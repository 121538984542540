import {
  faDesktop,
  faGlobeAsia,
  faMobileAlt,
  faPause,
  faPhoneAlt,
  faPlay
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import CallApi from 'components/common/custom-components/CallApi';
import React, { useContext, useState } from 'react';
import { Button, Card, Col, Nav, Row } from 'react-bootstrap';
import { useQuery } from 'react-query';
import { AdsAccountContext } from '../AdsAccountContext';
import { iPhoneFrame } from 'assets/img/accounts/images';
import Avatar from 'components/common/Avatar';
import IconButton from 'components/common/IconButton';
import { Link, NavLink } from 'react-router-dom';

const AdPreview = () => {
  const [ads, setAds] = useState([]);
  const [currentAd, setCurrentAd] = useState({ index: 0, ad: null });
  const adsAccountContext = useContext(AdsAccountContext);
  const { currentAccount } = adsAccountContext;
  const [device, setDevice] = useState('mobile');
  const [isPaused, setIsPaused] = useState(false);

  const { isLoading } = useQuery(
    ['account-preview-ad', currentAccount?.id],
    () => CallApi.get(`/account/${currentAccount?.id}/ad-preview`, false),
    {
      refetchOnMount: true,
      refetchInterval: 8000,
      enabled: !!(!isPaused && currentAccount?.id),
      onSuccess: json => {
        if (json.status == 200) {
          const newAds = [...ads, { ...json.data }];
          setAds(newAds);

          if (!isPaused) {
            setCurrentAd({ index: newAds.length - 1, ad: json.data });
          }
        }
      }
    }
  );

  const getSitelinks = () =>
    currentAd.ad?.sitelinks
      ?.slice(0, device == 'mobile' ? 3 : 4)
      ?.map((link, i, links) =>
        device == 'mobile' ? (
          <Button
            key={i}
            target="_blank"
            href={link?.link_url}
            disabled={!link?.link_url}
            size="sm"
            className={`border fw-medium mb-1 ${
              i + 1 == links.length ? '' : 'me-1'
            }`}
            variant="link"
          >
            <small>{link?.title}</small>
          </Button>
        ) : (
          <Nav.Item as="li" key={i}>
            <NavLink to={link?.link_url}>{link?.title}</NavLink>
            <small>
              <p>
                {link?.description1} {link?.description2}
              </p>
            </small>
          </Nav.Item>
        )
      );
  const renderPreview = () => (
    <div
      className={classNames('fw-medium text-start px-2', {
        'position-absolute mx-auto': device == 'mobile'
      })}
      style={{
        maxWidth: device == 'mobile' ? '250px' : '93%',
        marginTop: device == 'mobile' ? '-33.7rem' : '0px',
        left: 0,
        right: 0
      }}
    >
      <small>
        <b className="text-dark">Sponsored</b>
      </small>
      <div className="d-flex my-2 align-items-center">
        {currentAccount?.logo ? (
          <Avatar
            src={currentAccount?.logo}
            size={device == 'mobile' ? 's' : 'xl'}
          />
        ) : (
          <FontAwesomeIcon icon={faGlobeAsia} className="text-primary" />
        )}

        <div>
          {device == 'mobile' ? null : (
            <h6 className="ms-2 mb-0 text-dark">
              {currentAccount?.account_name}
            </h6>
          )}
          <small>
            <Link to={currentAccount?.website} className="ms-2 text-dark">
              {currentAccount?.website}
            </Link>
          </small>
        </div>
      </div>
      {isLoading ? (
        <>
          <FontAwesomeIcon icon={'spinner'} spin /> Loading your ad preview...
        </>
      ) : (
        <>
          <p className="text-primary mb-1 ">
            {currentAd.ad?.headlines
              ?.map(headline => headline.text)
              ?.join(' - ')}
          </p>
          <small>
            <p>
              {currentAd.ad?.descriptions
                ?.map(description => description.text)
                ?.join(' ')}
            </p>
          </small>
          {device == 'mobile' ? (
            getSitelinks()
          ) : (
            <Nav className="flex-column nav ms-3" as="ul">
              {getSitelinks()}
            </Nav>
          )}
          {currentAd.ad?.phone ? (
            <>
              <hr />
              {device == 'mobile' ? (
                <small>
                  <FontAwesomeIcon icon={faPhoneAlt} className="mx-2" />
                  {currentAccount?.language?.phone_call_text || 'Ring'}{' '}
                  {currentAd.ad?.phone}
                </small>
              ) : (
                <p>
                  <FontAwesomeIcon icon={faPhoneAlt} className="mx-2" />
                  {currentAccount?.language?.phone_call_text || 'Ring'}{' '}
                  {currentAd.ad?.phone}
                </p>
              )}
            </>
          ) : null}
        </>
      )}
    </div>
  );
  return (
    <Card>
      <Card.Body className="text-center m-3 user-select-none position-relative">
        <div className="border-bottom border-2 border-dark pb-3 mb-4">
          <Row>
            <Col>
              <h5 className="text-start">Ad Preview</h5>
            </Col>
            <Col xs="auto">
              <h5 className="my-0">
                <FontAwesomeIcon
                  className={classNames('me-3 cursor-pointer', {
                    'text-primary': device == 'mobile'
                  })}
                  onClick={() => setDevice('mobile')}
                  icon={faMobileAlt}
                />
                <FontAwesomeIcon
                  className={classNames('me-2 cursor-pointer', {
                    'text-primary': device == 'desktop'
                  })}
                  icon={faDesktop}
                  onClick={() => setDevice('desktop')}
                />
              </h5>
            </Col>
          </Row>
        </div>
        <div
          className={classNames({
            'py-4 bg-aida': device == 'mobile',
            'py-2': device != 'mobile'
          })}
        >
          {device == 'mobile' ? (
            <img src={iPhoneFrame} style={{ maxWidth: '300px' }} alt="" />
          ) : null}
          <div className={device == 'mobile' ? 'position-relative' : ''}>
            {renderPreview()}
          </div>
        </div>
        <div className="d-flex align-items-center text-primary fw-medium mt-3 justify-content-between">
          <Button
            variant="outline-primary"
            className="cursor-pointer rounded-pill"
            onClick={() => {
              const newIndex =
                currentAd.index - 1 >= 0
                  ? currentAd.index - 1
                  : currentAd.index;
              setCurrentAd({
                index: newIndex,
                ad: ads[newIndex]
              });
            }}
          >
            <FontAwesomeIcon size="sm" icon="arrow-left" /> Previous
          </Button>
          <IconButton
            variant="outline-primary"
            icon={isPaused ? faPlay : faPause}
            onClick={() => setIsPaused(!isPaused)}
            className="cursor-pointer rounded-pill ms-n3"
          />
          <Button
            variant="outline-primary"
            className="cursor-pointer rounded-pill"
            onClick={() => {
              const newIndex =
                currentAd.index + 1 <= ads.length - 1
                  ? currentAd.index + 1
                  : currentAd.index;
              setCurrentAd({
                index: newIndex,
                ad: ads[newIndex]
              });
            }}
          >
            Next <FontAwesomeIcon size="sm" icon="arrow-right" />
          </Button>
        </div>
      </Card.Body>
    </Card>
  );
};

export default AdPreview;
