import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Form } from 'react-bootstrap';
import CallApi from 'components/common/custom-components/CallApi';

const ForgetPasswordForm = ({ ...rest }) => {
  const history = useHistory();
  // State
  const [email, setEmail] = useState('');

  // Handler
  const handleSubmit = e => {
    e.preventDefault();

    CallApi.post('/password/remind', new FormData(e.target)).then(json => {
      if (json?.status == 200) {
        setEmail('');
        history.push('/app/confirm-mail');
      }
    });
  };

  return (
    <Form {...rest} onSubmit={handleSubmit}>
      <Form.Group className="mb-3">
        <Form.Label>Email address</Form.Label>
        <Form.Control
          placeholder={'Email address'}
          required
          value={email}
          name="email"
          onChange={({ target }) => setEmail(target.value)}
          type="email"
        />
      </Form.Group>

      <Form.Group className="mb-3">
        <Button className="w-100 rounded-pill" type="submit" disabled={!email}>
          Send reset link
        </Button>
      </Form.Group>

      {/* <Link className="fs--1 text-600" to="#!">
        I can't recover my account using this page
        <span className="d-inline-block ms-1"> &rarr;</span>
      </Link> */}
    </Form>
  );
};

ForgetPasswordForm.propTypes = {
  layout: PropTypes.string
};

ForgetPasswordForm.defaultProps = { layout: 'simple' };

export default ForgetPasswordForm;
