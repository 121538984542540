import { AccountCreatedImg } from 'assets/img/accounts/images';
import IconButton from 'components/common/IconButton';
import LocalStoragePageInfo from 'components/common/custom-components/LocalStoragePageInfo';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const AccountCreated = () => {
  return (
    <Col>
      <LocalStoragePageInfo slug="account_form_account_created">
        <Row>
          <Col className="d-flex justify-content-center align-items-center">
            <div className="text-center">
              <h1 className="mb-4 fw-medium font-ibm-plex-serif">
                Yej! Let's go!
              </h1>
              <p className="fs-1">
                Let the journey begin! We're creating
                <br />
                your account - keep an eye on your <br /> inbox! We'll get in
                touch.
              </p>
              <IconButton
                as={Link}
                to="/accounts"
                className="rounded-pill mt-3"
                icon={'link'}
              >
                Back to Overviews
              </IconButton>
            </div>
          </Col>
          <Col lg={6}>
            <img src={AccountCreatedImg} width={'100%'} />
          </Col>
        </Row>
      </LocalStoragePageInfo>
    </Col>
  );
};

export default AccountCreated;
