import useClientSelectBox from 'components/aida/custom-form-hooks/useClientSelectBox';
import LocalStorageTooltip from 'components/common/custom-components/LocalStorageTooltip';
import React, { useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import StepDetailsCardImg from '../../../../assets/img/Analysing-a-Mock-Test.jpg';

const StepDetailsCard = ({ step, text, title }) => {
  return (
    <>
      <Col className="ms-3 mt-3">
        <h5 className="mb-0 text-dark">STEP {step}</h5>
        <div className="line-row my-3">
          <div
            className="line"
            style={{ height: `${step > 0 ? '5px' : '2px'}` }}
          ></div>
          <div
            className="line"
            style={{ height: `${step > 1 ? '5px' : '2px'}` }}
          ></div>
          <div
            className="line"
            style={{ height: `${step > 2 ? '5px' : '2px'}` }}
          ></div>
          <div
            className="line"
            style={{ height: `${step > 3 ? '5px' : '2px'}` }}
          ></div>
          <div
            className="line"
            style={{ height: `${step > 4 ? '5px' : '2px'}` }}
          ></div>
          <div
            className="line"
            style={{ height: `${step > 5 ? '5px' : '2px'}` }}
          ></div>
        </div>
        <h3 className="my-3 font-ibm-plex-serif text-dark">{title}</h3>
        <p className="mt-2 text-dark">{text}</p>
      </Col>
      <Col lg={5} className="mt-3 me-3 mb-3 d-flex justify-content-end">
        <img
          src={StepDetailsCardImg}
          alt="Step Details"
          style={{ width: '300px', height: '200px' }}
        />
      </Col>
    </>
  );
};

export default StepDetailsCard;
