import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Create from 'components/aida/clients/Create';
import useClientSelectBox from 'components/aida/custom-form-hooks/useClientSelectBox';
import useCountrySelectBox from 'components/aida/custom-form-hooks/useCountrySelectBox';
import CallApi from 'components/common/custom-components/CallApi';
import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import Select from 'react-select';
import StepDetailsCard from './StepDetailsCard';
import ClientUpsertModal from 'components/aida/clients/ClientUpsertModal';
import LocalStorageTooltip from 'components/common/custom-components/LocalStorageTooltip';
import useLanguageSelectBox from 'components/aida/custom-form-hooks/useLanguageSelectBox';
import LocalStoragePageInfo from 'components/common/custom-components/LocalStoragePageInfo';

const BasicInformationForm = ({ onNext, accountDraftId }) => {
  const [state, setState] = useState({
    formData: {
      account_name: '',
      client_id: null,
      country: null,
      language: null,
      website: '',
      type: null,
      category: null,
      goal: null
    },
    errors: {},
    account: {},
    showModal: false,
    organizations: [],
    types: [],
    categories: [],
    basicInfo: [],
    defaultOrganization: []
  });

  const handleInputChange = e => {
    const { name, value } = e.target;
    setState(prevState => ({
      ...prevState,
      formData: {
        ...prevState.formData,
        [name]: value
      }
    }));
  };

  const handleRadioChange = (value, inputName) => {
    setState(prevState => ({
      ...prevState,
      formData: {
        ...prevState.formData,
        [inputName]: value
      }
    }));
  };

  const handleSelectChange = (selectedOption, inputName) => {
    setState(prevState => ({
      ...prevState,
      formData: {
        ...prevState.formData,
        [inputName.name]: selectedOption.value
      }
    }));
  };

  const saveInformation = () => {
    const newErrors = {};
    const { formData } = state;

    if (!formData.account_name || formData.account_name === '') {
      newErrors.account_name = 'Account Name is required';
    }
    if (!formData.client_id) {
      newErrors.client_id = 'Client is required';
    }
    if (!formData.country) {
      newErrors.country = 'Country is required';
    }
    if (!formData.language) {
      newErrors.language = 'Language is required';
    }
    if (!formData.website && formData.website === '') {
      newErrors.website = 'Website is required';
    }
    if (!formData.type) {
      newErrors.type = 'Type is required';
    }
    if (!formData.category) {
      newErrors.category = 'Category is required';
    }
    if (!formData.goal) {
      newErrors.goal = 'Goal is required';
    }

    setState(prevState => ({
      ...prevState,
      errors: newErrors
    }));

    // Check if there are no errors before proceeding to the next step
    if (Object.keys(newErrors).length === 0) {
      var data = new FormData();
      data.append('account_name', formData.account_name);
      data.append('client_id', formData.client_id);
      data.append('country', formData.country);
      data.append('language', formData.language);
      data.append('website', formData.website);
      data.append('type', formData.type);
      data.append('category', formData.category);
      data.append('goal', formData.goal);
      data.append('draft_id', accountDraftId);
      data.append('form_type', 'basic_information');

      CallApi.post(`/account-details-draft`, data).then(json => {
        if (json.status === 200) {
          onNext();
        }
      });
    } else {
      console.log(newErrors);
    }
    // onNext();
  };

  useEffect(() => {
    Promise.all([
      CallApi.get(
        `/account-details-draft/get-details-by-draft-id/` + accountDraftId,
        false
      ),
      CallApi.get(`/account-type`, false),
      CallApi.get(`/account-category`, false)
    ])
      .then(([details, typeData, categoryData]) => {
        if (details?.status === 200 && details.data) {
          const parsedObject = JSON.parse(details.data.basic_information);
          setState(prevState => ({
            ...prevState,
            basicInfo: parsedObject,
            formData: {
              ...prevState.formData,
              account_name: parsedObject.account_name,
              website: parsedObject.website,
              client_id: parsedObject.client_id,
              country: parsedObject.country,
              language: parsedObject.language,
              type: parsedObject.type,
              category: parsedObject.category,
              goal: parsedObject.goal
            }
          }));
        }

        if (typeData?.status === 200) {
          const formattedData = typeData.data.map(item => ({
            value: item.id,
            label: item.name
          }));
          setState(prevState => ({
            ...prevState,
            types: formattedData
          }));
        }

        if (categoryData?.status === 200) {
          const formattedData = categoryData.data.map(item => ({
            value: item.id,
            label: item.name
          }));
          setState(prevState => ({
            ...prevState,
            categories: formattedData
          }));
        }
      })
      .catch(error => {
        // Handle errors here
      });
  }, [accountDraftId]);

  const { ClientSelectBox, reloadClientSelectBox } = useClientSelectBox();

  const { CountrySelectbox } = useCountrySelectBox('iso_3166_2');

  const { LanguageSelectBox } = useLanguageSelectBox();

  const setShow = boolValue => {
    setState(prevState => ({
      ...prevState,
      showModal: boolValue
    }));
  };

  const { formData, errors, showModal, types, categories } = state;

  const [domainUsageMessage, setDomainUsageMessage] = useState('');

  const selectClientId = clientId =>
    setState({
      ...state,
      formData: { ...state.formData, client_id: clientId }
    });

  return (
    <Col>
      <LocalStoragePageInfo slug="account_form_basic_information">
        <Row>
          <StepDetailsCard
            step="1"
            text="In order to create and optimize an ad account, you need to enter some basic information about our company and website."
            title="Basic Information"
          />
        </Row>
      </LocalStoragePageInfo>
      <Card>
        <Card.Body className="m-3">
          <h5 className="border-bottom border-2 border-dark pb-3 mb-4">
            Your account
          </h5>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="account_name">
                Account name
                <LocalStorageTooltip
                  className="ms-1"
                  identifier={'form_account_wizard_form_field_account_name'}
                />
              </Form.Label>
              <Form.Control
                type="text"
                id="account_name"
                name="account_name"
                value={formData.account_name}
                onChange={handleInputChange}
              />
              {<small className="text-danger">{errors.account_name}</small>}
            </Form.Group>
            <Row className="d-flex align-items-center justify-content-between">
              <Col lg={6}>
                <Form.Group className="mb-3">
                  <Form.Label htmlFor="client">
                    Client
                    <LocalStorageTooltip
                      className="ms-1"
                      identifier={
                        'form_account_wizard_form_field_account_client'
                      }
                    />
                  </Form.Label>
                  <ClientSelectBox
                    isSearchable
                    value={formData.client_id}
                    onChange={client => {
                      selectClientId(client?.id);
                    }}
                  />
                  {<small className="text-danger">{errors.client_id}</small>}
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group className="mb-3 mt-4">
                  <Form.Label htmlFor="organization"></Form.Label>
                  <ClientUpsertModal
                    setNewClientId={selectClientId}
                    clientId={null}
                    refreshListing={reloadClientSelectBox}
                    variant="outline-primary"
                    className="rounded-pill mt-4"
                  >
                    Add New
                  </ClientUpsertModal>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
                <Form.Group className="mb-3">
                  <Form.Label htmlFor="country">
                    Country
                    <LocalStorageTooltip
                      className="ms-1"
                      identifier={
                        'form_account_wizard_form_field_account_country'
                      }
                    />
                  </Form.Label>
                  <CountrySelectbox
                    isSearchable
                    value={formData.country}
                    onChange={country => {
                      setState({
                        ...state,
                        formData: {
                          ...state.formData,
                          country: country?.iso_3166_2,
                          language:
                            country?.default_language_id || formData.language
                        }
                      });
                    }}
                  />
                </Form.Group>
                {<small className="text-danger">{errors.country}</small>}
              </Col>
              <Col lg={6}>
                <Form.Group className="mb-3">
                  <Form.Label htmlFor="language">
                    Language
                    <LocalStorageTooltip
                      className="ms-1"
                      identifier={'form_account_wizard_form_field_language'}
                    />
                  </Form.Label>
                  <LanguageSelectBox
                    isSearchable
                    value={formData.language}
                    onChange={language => {
                      setState({
                        ...state,
                        formData: {
                          ...state.formData,
                          language: language?.id
                        }
                      });
                    }}
                  />
                </Form.Group>
                {<small className="text-danger">{errors.language}</small>}
              </Col>
            </Row>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="website">
                Website
                <LocalStorageTooltip
                  className="ms-1"
                  identifier={'form_account_wizard_form_field_website'}
                />
              </Form.Label>
              <Form.Control
                id="website"
                type="text"
                name="website"
                value={formData.website}
                onChange={e => {
                  handleInputChange(e);
                  if (domainUsageMessage) {
                    setDomainUsageMessage('');
                  }
                }}
                onBlur={() => {
                  if (formData.website) {
                    CallApi.get(
                      `/account-details-draft/domain-usage?domain=${formData.website}`,
                      false
                    ).then(json => {
                      setDomainUsageMessage(json?.message);
                    });
                  }
                }}
              />
              <Form.Text
                className="text-warning"
                hidden={!formData.website || !domainUsageMessage}
              >
                {domainUsageMessage}
              </Form.Text>
              {<small className="text-danger">{errors.website}</small>}
            </Form.Group>
            <Row>
              <Col lg={6}>
                <Form.Group className="mb-3">
                  <Form.Label htmlFor="type">
                    Type
                    <LocalStorageTooltip
                      className="ms-1"
                      identifier={'form_account_wizard_form_field_account_type'}
                    />
                  </Form.Label>
                  <Select
                    name="type"
                    onChange={e => handleSelectChange(e, { name: 'type' })}
                    value={types.find(type => type.value == formData.type)}
                    options={types}
                    isSearchable={true}
                  />
                  {<small className="text-danger">{errors.type}</small>}
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group className="mb-3">
                  <Form.Label htmlFor="category">
                    Category
                    <LocalStorageTooltip
                      className="ms-1"
                      identifier={
                        'form_account_wizard_form_field_account_category'
                      }
                    />
                  </Form.Label>
                  <Select
                    name="category"
                    onChange={e => handleSelectChange(e, { name: 'category' })}
                    value={categories.find(
                      category => category.value == formData.category
                    )}
                    options={categories}
                    isSearchable={true}
                  />
                  {<small className="text-danger">{errors.category}</small>}
                </Form.Group>
              </Col>
            </Row>
            <Form.Group className="mb-3">
              <Form.Label>
                Goal with ads:
                <LocalStorageTooltip
                  className="ms-1"
                  identifier={'form_account_wizard_form_field_goal_with_ads'}
                />
              </Form.Label>
              <Row>
                <Col>
                  <Form.Group controlId="sales">
                    <Form.Check
                      type="radio"
                      name="goals"
                      label={'Sales'}
                      onChange={e => handleRadioChange('sales', 'goal')}
                      checked={formData.goal == 'sales'}
                      className="mb-n2"
                    />
                    <Form.Text>
                      Generate revenue through immediate
                      <br />
                      purchases with ad campaigns.
                    </Form.Text>
                  </Form.Group>
                  <Form.Group className="mt-3" controlId="brand-awareness">
                    <Form.Check
                      type="radio"
                      name="goals"
                      label="Brand awareness"
                      onChange={e =>
                        handleRadioChange('brand_awareness', 'goal')
                      }
                      checked={formData.goal == 'brand_awareness'}
                      className="mb-n2"
                    />
                    <Form.Text>
                      Boost brand recognition with
                      <br />
                      targeted advertising efforts.
                    </Form.Text>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId="new-customers">
                    <Form.Check
                      type="radio"
                      name="goals"
                      label="New Customers"
                      onChange={e => handleRadioChange('new_customers', 'goal')}
                      checked={formData.goal == 'new_customers'}
                      className="mb-n2"
                    />
                    <Form.Text>
                      Attract and engage new buyers
                      <br />
                      via effective advertising strategies
                    </Form.Text>
                  </Form.Group>
                  <Form.Group className="mt-3" controlId="traffic-to-website">
                    <Form.Check
                      type="radio"
                      name="goals"
                      label="Traffic to the website"
                      onChange={e =>
                        handleRadioChange('traffic_on_website', 'goal')
                      }
                      checked={formData.goal == 'traffic_on_website'}
                      className="mb-n2"
                    />
                    <Form.Text>
                      Increase website visits using
                      <br />
                      ad campaigns for online visibility.
                    </Form.Text>
                  </Form.Group>
                </Col>
                {<small className="text-danger">{errors.goal}</small>}
              </Row>
            </Form.Group>
          </Form>
        </Card.Body>
      </Card>
      <div className="button-group mt-4 text-center">
        <Button
          variant="outline-primary"
          className="rounded-pill"
          onClick={saveInformation}
        >
          Next Step: Your Website <FontAwesomeIcon icon={'arrow-right'} />
        </Button>
      </div>
    </Col>
  );
};

export default BasicInformationForm;
