import React from 'react';
import IconButton from 'components/common/IconButton';
import { Modal } from 'react-bootstrap';
import { useState } from 'react';
import ClientUpsertForm from './ClientUpsertForm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ClientUpsertModal = ({
  setNewClientId = id => {},
  clientId = null,
  refreshListing = () => {},
  iconOnly = false,
  ...rest
}) => {
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
  };

  return (
    <>
      {iconOnly ? (
        <FontAwesomeIcon
          onClick={() => setShow(true)}
          icon={clientId ? 'edit' : 'plus'}
          {...rest}
        />
      ) : (
        <IconButton
          {...rest}
          icon={clientId ? 'edit' : 'plus'}
          size="sm"
          className="rounded-pill"
          variant="outline-primary"
          onClick={() => setShow(true)}
        >
          {!clientId && 'Add Client'}
        </IconButton>
      )}
      <Modal show={show} centered onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{clientId ? 'Edit Client' : 'Add Client'}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-3">
          <ClientUpsertForm
            setNewClientId={setNewClientId}
            closeModal={() => {
              refreshListing();
              handleClose();
            }}
            clientId={clientId}
          />
        </Modal.Body>
        <Modal.Footer>
          <IconButton
            icon="times-circle"
            size="sm"
            onClick={handleClose}
            variant="falcon-light"
          >
            Cancel
          </IconButton>
          <IconButton
            className="rounded-pill"
            variant="outline-primary"
            form="client-form"
            type="submit"
            icon="save"
            size="sm"
          >
            {clientId ? 'Update' : 'Save'}
          </IconButton>
        </Modal.Footer>
      </Modal>
    </>
  );
};

ClientUpsertModal.propTypes = {};

export default ClientUpsertModal;
