import CallApi from 'components/common/custom-components/CallApi';
import FalconCardHeader from 'components/common/FalconCardHeader';
import { debounce } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { Card, Row, Col, Form } from 'react-bootstrap';
import { useMutation } from 'react-query';
import { AdsAccountContext } from '../../AdsAccountContext';

const MonthlyBudget = () => {
  const adsAccountContext = useContext(AdsAccountContext);
  const { currentAccount, refetchCurrentAccount } = adsAccountContext;
  const [currentBudget, setCurrentBudget] = useState(currentAccount?.budget);
  const [currentBudgetType, setCurrentBudgetType] = useState(
    currentAccount?.budget == 4000 ? 'monthly' : 'custom'
  );

  const updateMonthlyBudgetMutation = useMutation(
    ['account-monthly-budget', currentAccount?.id],
    budget => {
      let formData = new FormData();

      formData.append('budget', budget);

      return CallApi.post(
        `/account/${currentAccount?.id}/monthly-budget`,
        formData
      );
    },
    {
      onSuccess: json => {
        refetchCurrentAccount();
      }
    }
  );

  const onRadioChange = e => {
    if (e.target.value == 'monthly' && currentBudget != 4000) {
      if (
        confirm(
          `You're changing the monthly budget, this will affect how many people see and clicks on your ads. Sure you want to change budget from ${currentAccount?.budget} to 4000 SEK?`
        )
      ) {
        setCurrentBudgetType(e.target.value);
        setCurrentBudget(4000);
        updateMonthlyBudgetMutation.mutate(4000);
      }
    } else {
      setCurrentBudgetType(e.target.value);
    }
  };

  const onCustomBudgetChange = e => {
    console.log(e.target.value);
    if (
      currentBudget &&
      confirm(
        `You're changing the monthly budget, this will affect how many people see and clicks on your ads. Sure you want to change budget from ${currentAccount?.budget} to ${e.target.value} SEK?`
      )
    ) {
      updateMonthlyBudgetMutation.mutate(e.target.value);
    }
  };

  return (
    <Card>
      <Card.Body className="m-3">
        <h5 className="border-bottom border-2 border-dark pb-3 mb-4">
          Monthly Budget
        </h5>
        <Row>
          <Col>
            <Form.Check
              type="radio"
              checked={currentBudgetType == 'monthly'}
              name="budget"
              value={'monthly'}
              onChange={onRadioChange}
              label="Use recommended monthly budget of 4000 sek/month"
            />
            <Row>
              <Col xs={5}>
                <Form.Check
                  name="budget"
                  type="radio"
                  onChange={onRadioChange}
                  label="Current Budget"
                  value={'custom'}
                  checked={currentBudgetType == 'custom'}
                />
              </Col>
              <Col xs={7}>
                <Form.Control
                  type="text"
                  name="budget"
                  disabled={currentBudgetType == 'monthly'}
                  value={currentBudget}
                  onChange={e => setCurrentBudget(e.target.value)}
                  onBlur={onCustomBudgetChange}
                  placeholder="Enter your budget here"
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default MonthlyBudget;
