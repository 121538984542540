import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CallApi from 'components/common/custom-components/CallApi';
import IconButton from 'components/common/IconButton';
import React, { useState } from 'react';
import { Form, Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';

const CommonImageUpdaterModal = ({
  children = null,
  iconOnly = false,
  refetchCurrent = () => {},
  modalTitle = 'Image',
  updateUrl = '',
  icon = 'image',
  fieldName = 'file',
  ...rest
}) => {
  const [show, setShow] = useState(false);

  const { handleSubmit, register } = useForm({});

  const handleClose = () => {
    setShow(false);
  };

  const UpdateImageMutation = useMutation(
    form => CallApi.post(updateUrl, new FormData(form), true),
    {
      onSuccess: response => {
        handleClose();
        refetchCurrent();
      }
    }
  );

  const onSubmit = (data, form) => {
    UpdateImageMutation.mutate(form.target);
  };

  return (
    <>
      {iconOnly ? (
        <FontAwesomeIcon {...rest} icon={icon} />
      ) : (
        <IconButton icon={icon} {...rest} onClick={() => setShow(true)}>
          {children}
        </IconButton>
      )}

      <Modal show={show} centered onHide={() => handleClose()}>
        <Modal.Header closeButton>
          <Modal.Title>
            <FontAwesomeIcon icon={icon} /> Upload {modalTitle}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-3">
          <Form id="upload-image-form" onSubmit={handleSubmit(onSubmit)}>
            <input type="file" accept="image/*" {...register(fieldName)} />
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <IconButton
            icon="times-circle"
            size="sm"
            onClick={handleClose}
            variant="falcon-light"
          >
            Cancel
          </IconButton>
          <IconButton
            form="upload-image-form"
            type="submit"
            size="sm"
            spin={UpdateImageMutation.isLoading}
            icon={UpdateImageMutation.isLoading ? 'spinner' : 'pencil-alt'}
          >
            {UpdateImageMutation.isLoading ? 'Updating...' : 'Update'}
          </IconButton>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CommonImageUpdaterModal;
