import { ManOnChart } from 'assets/img/accounts/images';
import LocalStoragePageInfo from 'components/common/custom-components/LocalStoragePageInfo';
import MarketShare from 'components/dashboards/default/MarketShare';
import TopProducts from 'components/dashboards/default/TopProducts';
import WeeklySales from 'components/dashboards/default/WeeklySales';
import { AidaAppContext } from 'context/AidaAppContext';
import {
  marketShare,
  topProducts,
  weeklySalesData
} from 'data/dashboard/default';
import React, { useContext, useEffect, useState } from 'react';
import { Card, Row, Col } from 'react-bootstrap';

const OverviewPage = () => {
  const aidaAppContext = useContext(AidaAppContext);

  useEffect(() => {}, []);

  const getGreeting = () => {
    const currentTime = new Date().getHours();

    if (currentTime >= 5 && currentTime < 12) {
      return 'Good morning';
    } else if (currentTime >= 12 && currentTime < 17) {
      return 'Good afternoon';
    } else {
      return 'Good evening';
    }
  };

  return (
    <>
      <LocalStoragePageInfo slug="account_overview">
        <Row>
          <Col>
            <div className="px-4 py-2 h-100 d-flex flex-column justify-content-between">
              <div>
                <h2 className="mb-0 font-ibm-plex-serif">
                  {getGreeting()},
                  <br /> {aidaAppContext?.current_user?.name}!
                </h2>
                <p className="fs-1 mt-1 text-dark">
                  Here's what happening <br /> with your store today.
                </p>
              </div>
            </div>
          </Col>
          <Col lg={5}>
            <img src={ManOnChart} className="w-100" />
          </Col>
        </Row>
      </LocalStoragePageInfo>
      <Row className="gap-3">
        <Col lg={8}>
          <TopProducts data={topProducts} />
        </Col>
        <Col>
          <Row className="gap-3">
            <Col>
              <WeeklySales className="mb-4" data={weeklySalesData} />
            </Col>
            <Col>
              <MarketShare radius={[]} data={marketShare} />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default OverviewPage;
