import IconButton from 'components/common/IconButton';
import { trim } from 'lodash';
import React, { useState } from 'react';
import { Form, Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';

const ManualAddWebsiteLinkModal = ({
  selectedRowIds = {},
  setWebsiteLinks = arg => {},
  websiteLinks,
  setInitiallySelectedRowIds = arg => {},
  websiteDomain,
  ...rest
}) => {
  const defaultErrorObject = { message: '', type: '' };
  const [show, setShow] = useState(false);
  const [error, setError] = useState(defaultErrorObject);

  const { handleSubmit, register, reset } = useForm();

  const handleClose = () => {
    setShow(false);
    setError(defaultErrorObject);
    reset();
  };

  const onSubmit = formData => {
    const links = [
      ...new Set(
        formData.links
          .split('\n')
          .map(link => trim(link))
          .filter(link => link != '')
      )
    ];

    let newTopLinks = [];

    let newInitiallySelectedRowIds = { 0: true };

    links.forEach((link, i) => {
      // remove if duplicate link
      if (websiteLinks?.map(fetchedlink => fetchedlink?.link)?.includes(link)) {
        return;
      }

      // check domain
      if (link?.includes(websiteDomain)) {
        // checking if http or https is present or not
        if (!(link.includes('http://') || link.includes('https://'))) {
          link = `https://${link}`;
        }
        newTopLinks.push({ link: link, manually_added: true });
        newInitiallySelectedRowIds[i] = true;
      }
    });

    Object.keys(selectedRowIds).forEach(
      id => (newInitiallySelectedRowIds[+id + +newTopLinks.length] = true)
    );

    const newWebsiteLinks = [...newTopLinks, ...websiteLinks];

    newWebsiteLinks.forEach((link, i) => {
      if (links.includes(link.link)) {
        newInitiallySelectedRowIds[+i] = true;
      }
    });

    setInitiallySelectedRowIds(newInitiallySelectedRowIds);
    setWebsiteLinks(newWebsiteLinks);
    handleClose();
  };

  return (
    <>
      <IconButton
        icon={'plus'}
        {...rest}
        className="rounded-pill"
        variant="outline-primary"
        size="sm"
        onClick={() => setShow(true)}
      >
        Add Manually
      </IconButton>
      <Modal show={show} centered onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add custom links</Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-3">
          <Form
            noValidate
            id="manual-website-link-form"
            onSubmit={event => {
              event.stopPropagation();
              handleSubmit(onSubmit)(event);
            }}
          >
            <Form.Group className="mb-3">
              <Form.Label>Page links</Form.Label>
              <Form.Control
                as="textarea"
                rows={5}
                {...register('links', {
                  required: true
                })}
                isInvalid={!!error.message}
              />
              <Form.Text className="text-warning">
                Page link must have same domain as your website domain
                <br />
                eg: {websiteDomain}
              </Form.Text>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <IconButton
            icon="times-circle"
            size="sm"
            onClick={handleClose}
            variant="falcon-light"
          >
            Cancel
          </IconButton>
          <IconButton
            form="manual-website-link-form"
            type="submit"
            icon="save"
            size="sm"
            variant="outline-primary"
            className="rounded-pill"
          >
            Save
          </IconButton>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ManualAddWebsiteLinkModal;
