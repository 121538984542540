import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import IconButton from 'components/common/IconButton';
import CallApi from 'components/common/custom-components/CallApi';
import useUrl from 'hooks/useUrl';
import React, { useEffect, useState } from 'react';
import { Container, Card } from 'react-bootstrap';
import { BsMailbox } from 'react-icons/bs';
import { useMutation } from 'react-query';
import { Link } from 'react-router-dom';

const VerifyProject = () => {
  const url = useUrl();
  const token = url.get('token');
  const [data, setData] = useState({
    status: null,
    seo_project: {},
    message: ''
  });

  const VerifyEmailMutation = useMutation(['verify-email'], postData =>
    CallApi.post('/verify-seo-project', postData, false)
  );

  useEffect(() => {
    if (token) {
      let postData = new FormData();
      postData.append('token', token);

      VerifyEmailMutation.mutate(postData, {
        onSuccess: json => {
          setData(json);
        }
      });
    }
  }, [token]);

  return (
    <Container>
      <Card className="mt-6">
        <Card.Header className="m-3">
          <Card.Title>
            <BsMailbox className="fs-3 pb-1 me-1" /> E-mail Verification
          </Card.Title>
        </Card.Header>
        <Card.Body className="m-3 mt-0 pt-0">
          {VerifyEmailMutation.isLoading ? (
            <>
              <FontAwesomeIcon icon={'spinner'} spin /> Verifying your e-mail,
              please wait...
            </>
          ) : (
            <>
              {data?.status === 200 ? (
                <>
                  ✅ E-mail verified for <b>{data?.seo_project?.name}</b>{' '}
                  project.
                </>
              ) : (
                data?.message
              )}
              {VerifyEmailMutation.isLoading ? null : (
                <div>
                  <Link to={'/'}>
                    <IconButton
                      icon={'home'}
                      className="mt-5 rounded-pill "
                      variant="outline-primary"
                    >
                      Back to Trakk.ai
                    </IconButton>
                  </Link>
                </div>
              )}
            </>
          )}
        </Card.Body>
      </Card>
    </Container>
  );
};

export default VerifyProject;
