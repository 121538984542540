import { faToggleOn } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { WebMonitorPerson } from 'assets/img/accounts/images';
import classNames from 'classnames';
import IconButton from 'components/common/IconButton';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import CallApi from 'components/common/custom-components/CallApi';
import React, { useContext } from 'react';
import { Card, Col, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { useMutation, useQuery } from 'react-query';
import { AdsAccountContext } from './AdsAccountContext';
import AddWebMonitoringOverview from './edit-texts/AddWebMonitoringOverview';
import LocalStoragePageInfo from 'components/common/custom-components/LocalStoragePageInfo';
import Flex from 'components/common/Flex';
import { Link } from 'react-router-dom';

const WebMonitor = () => {
  const adsAccountContext = useContext(AdsAccountContext);
  const { currentAccount } = adsAccountContext;
  const WebMonitorQuery = useQuery(
    ['web-monitor', currentAccount?.id],
    () =>
      CallApi.get(`/account/${currentAccount?.id}/web-monitoring-overviews`),
    { placeholderData: { data: [] }, enabled: !!currentAccount?.id }
  );

  const ExportAsExcelMutation = useMutation(
    ['re-run-web-monitoring-ping'],
    () =>
      CallApi.get(`/account/${currentAccount?.id}/web-monitoring-excel-export`)
  );

  const columns = [
    {
      accessor: 'url',
      Header: 'Url',
      Cell: ({ cell }) => (
        <a href={cell.value} target="_blank" rel="noreferrer">
          {cell.value}
        </a>
      )
    },
    {
      accessor: 'active',
      Header: 'Active',
      Cell: ({ cell }) => {
        const ToggleStatusMutation = useMutation(
          ['toggle-status-web-monitor-url'],
          id => CallApi.put(`/web-monitoring-overviews/${id}/toggle-status`),
          {
            onSuccess: json => WebMonitorQuery.refetch()
          }
        );
        return (
          <FontAwesomeIcon
            spin={ToggleStatusMutation.isLoading}
            icon={ToggleStatusMutation.isLoading ? 'spinner' : faToggleOn}
            className={classNames(
              'fs-2 cursor-pointer',
              cell.value ? 'text-success' : 'fa-rotate-180 text-danger'
            )}
            title={cell.value ? 'Make In-active' : 'Make Active'}
            onClick={() => ToggleStatusMutation.mutate(cell.row.original.id)}
          />
        );
      }
    },
    { accessor: 'latest_run_date', Header: 'Date' },
    {
      accessor: 'status_code',
      Header: 'Status',
      Cell: ({ cell: { value }, row }) => {
        const statusCodes = value?.split(' / ');
        const StatusJsx = ({ statusCode }) => (
          <>
            <FontAwesomeIcon
              icon={'circle'}
              className={classNames('me-1', {
                'text-danger': +statusCode >= 400 ?? +statusCode <= 500,
                'text-warning': +statusCode >= 300 ?? +statusCode <= 400,
                'text-success': +statusCode >= 200 ?? +statusCode <= 300
              })}
            />
            {statusCode}
          </>
        );

        return value
          ? statusCodes.map((statusCode, i) => (
              <>
                {i == 0 ? null : ' / '}
                {statusCode == 301 ? (
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip>{row.original.final_url}</Tooltip>}
                  >
                    <span className="cursor-pointer">
                      <StatusJsx statusCode={statusCode} />
                    </span>
                  </OverlayTrigger>
                ) : (
                  <StatusJsx statusCode={statusCode} />
                )}
              </>
            ))
          : null;
      }
    },
    {
      id: 'removing_id',
      Header: 'Remove',
      cellProps: { className: 'text-center' },
      Cell: ({ cell }) => {
        const DeleteWebMonitoringUrlMutation = useMutation(
          ['delete-web-monitoring-overview'],
          id => CallApi.delete(`/web-monitoring-overviews/${id}`),
          {
            onSuccess: json => WebMonitorQuery.refetch()
          }
        );

        return (
          <FontAwesomeIcon
            onClick={() => {
              if (
                confirm('Do you really want to delete this web monitoring url?')
              ) {
                DeleteWebMonitoringUrlMutation.mutate(cell.row.original.id);
              }
            }}
            className="me-3 text-dark cursor-pointer"
            icon={
              DeleteWebMonitoringUrlMutation.isLoading ? 'spinner' : 'trash-alt'
            }
            spin={DeleteWebMonitoringUrlMutation.isLoading}
          />
        );
      }
    },
    {
      accessor: 'id',
      Header: 'Actions',
      Cell: ({ cell }) => {
        const ReRunMutation = useMutation(
          ['re-run-web-monitoring-ping'],
          web_monitoring_overview_id =>
            CallApi.post(
              `/web-monitoring-overviews/${web_monitoring_overview_id}/ping`
            ),
          {
            onSuccess: json => WebMonitorQuery.refetch()
          }
        );
        return (
          <IconButton
            onClick={() => ReRunMutation.mutate(cell.value)}
            size="sm"
            iconClassName="me-2"
            className="rounded-pill"
            variant="outline-primary"
            icon={ReRunMutation.isLoading ? 'spinner' : 'redo'}
            spin={ReRunMutation.isLoading}
          >
            re-run
          </IconButton>
        );
      }
    }
  ];
  return (
    <>
      <LocalStoragePageInfo slug="web_monitor">
        <Row>
          <Col>
            <Flex
              className="px-4 py-2"
              justifyContent="between"
              direction="column"
            >
              <h2 className="mb-0 font-ibm-plex-serif">Web Monitor</h2>
              <p className="fs-1 mt-1 text-dark">
                Here you can monitor the performance
                <br /> and status for your important pages.
              </p>
            </Flex>
          </Col>
          <Col lg={4} xxl={3}>
            <img src={WebMonitorPerson} className="w-100" />
          </Col>
        </Row>
      </LocalStoragePageInfo>
      <Card>
        <Card.Body className="m-3">
          <AdvanceTableWrapper
            columns={columns}
            data={WebMonitorQuery.data?.data || []}
            sortable
            pagination
            perPage={25}
          >
            <Row className="align-items-center mb-3">
              <Col xs="auto">
                <AdvanceTableSearchBox table />
              </Col>
              <Col />
              <Col xs="auto">
                <IconButton
                  size="sm"
                  variant="outline-primary"
                  className="rounded-pill me-2"
                  disabled={WebMonitorQuery.data?.data?.length == 0}
                  icon={
                    ExportAsExcelMutation.isLoading
                      ? 'spinner'
                      : 'external-link-alt'
                  }
                  spin={ExportAsExcelMutation.isLoading}
                  onClick={() => ExportAsExcelMutation.mutate()}
                >
                  Export
                </IconButton>
                <AddWebMonitoringOverview
                  refetchUrls={WebMonitorQuery.refetch}
                />
              </Col>
            </Row>
            <AdvanceTable
              table
              headerClassName="bg-aida-table-header text-900 text-nowrap align-middle"
              rowClassName="align-middle white-space-nowrap"
              tableProps={{
                className: 'fs--1 mb-0 overflow-hidden'
              }}
              loadingText={'Loading urls...'}
              apiCallInProcess={WebMonitorQuery.isLoading}
            />
            <AdvanceTableFooter
              rowsPerPageOptions={[25, 50, 75, 100]}
              table
              className="mt-3 mx-3"
              rowCount={WebMonitorQuery?.data?.data?.length}
              rowInfo
              navButtons
              rowsPerPageSelection
            />
          </AdvanceTableWrapper>
        </Card.Body>
      </Card>
    </>
  );
};

export default WebMonitor;
