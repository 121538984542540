import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CallApi from 'components/common/custom-components/CallApi';
import IconButton from 'components/common/IconButton';
import React, { useState } from 'react';
import { Form, Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';

const AvatarPickerModal = ({
  children = null,
  iconOnly = false,
  userId,
  refetchCurrent = () => {},
  ...rest
}) => {
  const [show, setShow] = useState(false);

  const { handleSubmit, register } = useForm({});

  const handleClose = () => {
    setShow(false);
  };

  const UpdateAvatar = useMutation(
    form =>
      CallApi.post(
        `/users/${userId}/avatar/without-crop-cloudinary`,
        new FormData(form),
        true
      ),
    {
      onSuccess: response => {
        handleClose();
        refetchCurrent();
      }
    }
  );

  const onSubmit = (data, form) => {
    UpdateAvatar.mutate(form.target);
  };

  return (
    <>
      {iconOnly ? (
        <FontAwesomeIcon
          {...rest}
          icon={'camera'}
          onClick={() => setShow(true)}
        />
      ) : (
        <IconButton icon={'camera'} {...rest} onClick={() => setShow(true)}>
          {children}
        </IconButton>
      )}

      <Modal show={show} centered onHide={() => handleClose()}>
        <Modal.Header closeButton>
          <Modal.Title>
            <FontAwesomeIcon icon={'camera'} /> Upload Avatar
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-3">
          <Form id="avatar-changer-form" onSubmit={handleSubmit(onSubmit)}>
            <input type="file" accept="image/*" {...register('file')} />
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <IconButton
            icon="times-circle"
            size="sm"
            onClick={handleClose}
            variant="falcon-light"
          >
            Cancel
          </IconButton>
          <IconButton
            form="avatar-changer-form"
            type="submit"
            size="sm"
            variant="outline-primary"
            className="rounded-pill"
            spin={UpdateAvatar.isLoading}
            icon={UpdateAvatar.isLoading ? 'spinner' : 'pencil-alt'}
          >
            {UpdateAvatar.isLoading ? 'Updating...' : 'Update'}
          </IconButton>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AvatarPickerModal;
