import { ExtractHostname } from './utils';

export const calculateFrequency = keywordsListWithOtherDomains => {
  let frequency = 0;
  let goneThroughDomains = [];

  keywordsListWithOtherDomains.forEach(keyword => {
    const extractedHostname = ExtractHostname(keyword.url);
    if (!goneThroughDomains.includes(extractedHostname)) {
      frequency++;
    }
    goneThroughDomains.push(extractedHostname);
  });

  return frequency;
};
