import * as Sentry from '@sentry/react';
import 'helpers/initFA';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import Main from './Main';

if (process.env?.REACT_APP_SENTRY_ON) {
  console.log('Sentry is ON!');
  Sentry.init({
    dsn: 'https://e76b3927b40b3631cd3b3a2fcafda9f2@o4505486824243200.ingest.sentry.io/4505684087734272',
    integrations: [new Sentry.BrowserTracing({})],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0
  });
} else {
  console.log('Sentry is OFF!');
}

ReactDOM.render(
  <React.StrictMode>
    <Main>
      <App />
    </Main>
  </React.StrictMode>,
  document.getElementById('main')
);
