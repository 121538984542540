import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const IconButton = ({
  icon,
  iconAlign = 'left',
  className = '',
  iconClassName = '',
  transform = '',
  children = '',
  spin = false,
  pulse = false,
  ...rest
}) => (
  <Button className={className} {...rest}>
    {iconAlign === 'right' && children}
    <FontAwesomeIcon
      icon={icon}
      className={classNames(iconClassName, {
        'me-1': children && iconAlign === 'left',
        'ms-1': children && iconAlign === 'right'
      })}
      transform={transform}
      spin={spin}
      pulse={pulse}
    />
    {iconAlign === 'left' && children}
  </Button>
);

IconButton.propTypes = {
  ...Button.propTypes,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  children: PropTypes.any,
  iconAlign: PropTypes.oneOf(['left', 'right']),
  iconClassName: PropTypes.string,
  transform: PropTypes.string,
  spin: PropTypes.bool,
  pulse: PropTypes.bool
};

IconButton.defaultProps = {
  iconClassName: '',
  transform: '',
  children: ''
};

export default IconButton;
