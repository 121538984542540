import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import CallApi from 'components/common/custom-components/CallApi';
import NavbarTopDropDownMenus from 'components/navbar/top/NavbarTopDropDownMenus';
import { navbarBreakPoint, topNavbarBreakpoint } from 'config';
import { AidaAppContext } from 'context/AidaAppContext';
import AppContext from 'context/Context';
import { inArray } from 'is_js';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { Col, Nav, Navbar, NavLink, Row } from 'react-bootstrap';
import { useQuery } from 'react-query';
import ReactSelect from 'react-select';
import NavbarClientSelector from './NavbarClientSelector';

const NavbarVertical = () => {
  const [accountId, setAccountId] = useState(null);
  const aidaAppContext = useContext(AidaAppContext);
  const [modules, setModules] = useState([]);
  const { setAidaAppContext } = aidaAppContext;
  const { data } = useQuery(
    ['client_menu', aidaAppContext.client?.id],
    () => CallApi.get(`/clients/${aidaAppContext.client?.id}/accounts`, false),
    {
      placeholderData: { data: [] },
      enabled: !!aidaAppContext.client?.id
    }
  );

  const myAccountsQuery = useQuery(
    'my-accounts',
    () => CallApi.get('/me/accounts', false),
    {
      placeholderData: { children: [] },
      enabled: !!inArray(aidaAppContext.user_role, ['Client', 'Client Admin'])
    }
  );

  const AccountQuery = useQuery(
    ['account', accountId],
    () => CallApi.get(`/accounts/${accountId}`, false),
    {
      onSuccess: json => {
        setModules(json?.data?.modules);
      },
      placeholderData: { data: [] },
      enabled: !!accountId
    }
  );

  const {
    config: {
      navbarPosition,
      navbarStyle,
      isNavbarVerticalCollapsed,
      showBurgerMenu
    }
  } = useContext(AppContext);

  const HTMLClassList = document.getElementsByTagName('html')[0].classList;

  useEffect(() => {
    if (isNavbarVerticalCollapsed) {
      HTMLClassList.add('navbar-vertical-collapsed');
    } else {
      HTMLClassList.remove('navbar-vertical-collapsed');
    }

    return () => {
      HTMLClassList.remove('navbar-vertical-collapsed-hover');
    };
  }, [isNavbarVerticalCollapsed, HTMLClassList]);

  //Control mouseEnter event
  let time = null;
  const handleMouseEnter = () => {
    if (isNavbarVerticalCollapsed) {
      time = setTimeout(() => {
        HTMLClassList.add('navbar-vertical-collapsed-hover');
      }, 100);
    }
  };
  const handleMouseLeave = () => {
    clearTimeout(time);
    HTMLClassList.remove('navbar-vertical-collapsed-hover');
  };

  return (
    <Navbar
      expand={navbarBreakPoint}
      className={classNames('mt-md-7 pt-md-2 navbar-vertical', {
        [`navbar-${navbarStyle}`]: navbarStyle !== 'transparent'
      })}
      variant="light"
    >
      {/* <Flex alignItems="center">
        <ToggleButton />
        <Logo at="navbar-vertical" width={40} />
      </Flex> */}
      {/* {inArray(aidaAppContext.user_role, ['Manager', 'Sales']) ? (
        <NavbarClientSelector className="d-md-none mb-3" />
      ) : null} */}
      <Navbar.Collapse
        in={showBurgerMenu}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        style={{
          backgroundImage:
            navbarStyle === 'vibrant'
              ? `linear-gradient(-45deg, rgba(0, 160, 255, 0.86), #0048a2)`
              : 'none'
        }}
      >
        <div className="navbar-vertical-content scrollbar">
          {inArray(aidaAppContext.user_role, ['Manager', 'Admin', 'Sales']) ? (
            <>
              <Row className="mt-3 mb-2 navbar-vertical-label-wrapper">
                <Col
                  xs="auto"
                  className="navbar-vertical-label navbar-vertical-label"
                >
                  Clients{' '}
                </Col>
                <Col className="ps-0 pe-0">
                  <hr className="mb-0 navbar-vertical-divider"></hr>
                </Col>
              </Row>
              <NavbarClientSelector
                callback={() => {
                  setModules([]);
                }}
                className="d-none d-md-block pe-1 mt-md-3 mt-xl-2"
              />
              <Row className="mt-3 mb-2 navbar-vertical-label-wrapper">
                <Col
                  xs="auto"
                  className="navbar-vertical-label navbar-vertical-label"
                >
                  Accounts{' '}
                </Col>
                <Col className="ps-0 pe-0">
                  <hr className="mb-0 navbar-vertical-divider"></hr>
                </Col>
              </Row>
              <div className={`overflow-initial`}>
                <ReactSelect
                  classNamePrefix="react-select"
                  options={data?.data || []}
                  value={aidaAppContext.account}
                  onChange={account => {
                    setAidaAppContext({ ...aidaAppContext, account: account });
                    setAccountId(account?.id);
                  }}
                  getOptionLabel={account => `${account?.account_name}`}
                  isSearchable
                />
              </div>
            </>
          ) : null}
          {inArray(aidaAppContext.user_role, ['Client Admin', 'Client']) ? (
            <>
              <Row className="mt-1 mb-2 navbar-vertical-label-wrapper">
                <Col
                  xs="auto"
                  className="navbar-vertical-label navbar-vertical-label"
                >
                  Accounts{' '}
                </Col>
                <Col className="ps-0 pe-0">
                  <hr className="mb-0 navbar-vertical-divider"></hr>
                </Col>
              </Row>

              <div className={`overflow-initial`}>
                <ReactSelect
                  classNamePrefix="react-select"
                  isDisabled={myAccountsQuery.isFetching}
                  options={myAccountsQuery.data?.children}
                  value={aidaAppContext.account}
                  onChange={account => {
                    setAidaAppContext({ ...aidaAppContext, account: account });
                    setAccountId(account?.id);
                  }}
                  getOptionLabel={account => account.name}
                  isSearchable
                />
              </div>
            </>
          ) : null}

          {modules?.length > 0 ? (
            <Nav className="flex-column nav mt-2" as="ul">
              <NavbarLabel label={'Modules'} />
              {AccountQuery?.isFetching ? (
                <div className="text-white">
                  <FontAwesomeIcon icon={'spinner'} spin /> Loading...
                </div>
              ) : (
                modules?.map(module => (
                  <Nav.Item as="li" key={module?.name}>
                    <NavLink>
                      <span className="nav-link-text ps-1">{module?.name}</span>
                    </NavLink>
                  </Nav.Item>
                ))
              )}
            </Nav>
          ) : null}
          <>
            {navbarPosition === 'combo' && (
              <div className={`d-${topNavbarBreakpoint}-none mb-3`}>
                <div className="navbar-vertical-divider">
                  <hr className="navbar-vertical-hr my-2" />
                </div>
                <Nav navbar>
                  <NavbarTopDropDownMenus />
                </Nav>
              </div>
            )}
          </>
        </div>
      </Navbar.Collapse>
    </Navbar>
  );
};

NavbarVertical.propTypes = {
  label: PropTypes.string
};

export const NavbarLabel = ({ label }) => (
  <Nav.Item as="li">
    <Row className="mt-3 mb-2 navbar-vertical-label-wrapper">
      <Col xs="auto" className="navbar-vertical-label navbar-vertical-label">
        {label}
      </Col>
      <Col className="ps-0">
        <hr className="mb-0 navbar-vertical-divider"></hr>
      </Col>
    </Row>
  </Nav.Item>
);
export default NavbarVertical;
