import { faKey } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Section from 'components/common/Section';
import CallApi from 'components/common/custom-components/CallApi';
import useAuthActions from 'hooks/useAuthActions';
import React, { useState } from 'react';
import { Row, Col, Card, Button, Form } from 'react-bootstrap';
import { useMutation } from 'react-query';

const ForceChangePassword = () => {
  const authActions = useAuthActions();
  // State
  const [formData, setFormData] = useState({
    current_password: '',
    password: '',
    password_confirmation: ''
  });

  const UpdatePasswordMutation = useMutation(
    'update-password',
    formData => CallApi.post(`/password/change`, formData),
    {
      onSuccess: json => {
        if (json?.status == 200) {
          authActions.runPostLoginActions();
        }
      }
    }
  );

  // Handler
  const handleSubmit = e => {
    e.preventDefault();
    let payload = new FormData();

    for (const field in formData) {
      if (Object.hasOwnProperty.call(formData, field)) {
        const value = formData[field];
        payload.append(field, value);
      }
    }

    UpdatePasswordMutation.mutate(payload);
  };

  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  return (
    <Section className="py-0">
      <Row className="g-0 min-vh-100 flex-center">
        <Col lg={8} xl={6} className="py-3 position-relative">
          <Card className="overflow-hidden z-index-1">
            <Card.Body>
              <h4 className="mb-3">
                <FontAwesomeIcon icon={faKey} /> Please change your password.
              </h4>

              <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-3">
                  <Form.Label>Current password</Form.Label>{' '}
                  <Form.Control
                    type="password"
                    value={formData.current_password}
                    name="current_password"
                    onChange={handleFieldChange}
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Row className="justify-content-between align-items-center">
                    <Col xs="auto">
                      <Form.Label>Password</Form.Label>{' '}
                    </Col>
                  </Row>
                  <Form.Control
                    type="password"
                    value={formData.password}
                    name="password"
                    onChange={handleFieldChange}
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Row className="justify-content-between align-items-center">
                    <Col xs="auto">
                      <Form.Label>Confirm New Password</Form.Label>{' '}
                    </Col>
                  </Row>
                  <Form.Control
                    type="password"
                    value={formData.password_confirmation}
                    name="password_confirmation"
                    onChange={handleFieldChange}
                  />
                </Form.Group>
                <Form.Group>
                  <Button
                    type="submit"
                    color="primary"
                    className="mt-3 rounded-pill w-100"
                    disabled={
                      !formData.current_password ||
                      !formData.password ||
                      !formData.password_confirmation ||
                      UpdatePasswordMutation.isLoading
                    }
                  >
                    Update Password
                  </Button>
                </Form.Group>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Section>
  );
};

export default ForceChangePassword;
