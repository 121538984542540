import React from 'react';
import useAccountSelectBox from 'components/aida/custom-form-hooks/useAccountSelectBox';
import useUrl from 'hooks/useUrl';
import { useHistory } from 'react-router-dom';

const AccountSelector = () => {
  const url = useUrl();
  const history = useHistory();
  const AccountSelectBox = useAccountSelectBox();
  return (
    <AccountSelectBox
      isSearchable
      value={url.get('id')}
      onChange={account => {
        history.replace(`/account?id=${account.id}`);
      }}
    />
  );
};

export default AccountSelector;
