import classNames from 'classnames';
import { navbarBreakPoint } from 'config';
import React, { useContext, useEffect } from 'react';
import { Nav, Navbar } from 'react-bootstrap';
import { SalesModuleRoutes } from 'routes/routes';
import { NavbarLabel } from './NavbarVertical';
import NavbarVerticalMenu from './NavbarVerticalMenu';
import AppContext from 'context/Context';

const NavbarVerticalWithoutBackground = () => {
  const {
    config: { isNavbarVerticalCollapsed, showBurgerMenu }
  } = useContext(AppContext);

  const HTMLClassList = document.getElementsByTagName('html')[0].classList;

  //Control mouseEnter event
  let time = null;
  const handleMouseEnter = () => {
    if (isNavbarVerticalCollapsed) {
      time = setTimeout(() => {
        HTMLClassList.add('navbar-vertical-collapsed-hover');
      }, 100);
    }
  };
  const handleMouseLeave = () => {
    clearTimeout(time);
    HTMLClassList.remove('navbar-vertical-collapsed-hover');
  };

  useEffect(() => {
    if (isNavbarVerticalCollapsed) {
      HTMLClassList.add('navbar-vertical-collapsed');
    } else {
      HTMLClassList.remove('navbar-vertical-collapsed');
    }

    return () => {
      HTMLClassList.remove('navbar-vertical-collapsed-hover');
    };
  }, [isNavbarVerticalCollapsed, HTMLClassList]);

  return (
    <Navbar
      expand={navbarBreakPoint}
      className={classNames('mt-4 mt-md-0 mt-xl-6 navbar-vertical navbar-dark')}
      variant="dark"
    >
      <Navbar.Collapse
        in={showBurgerMenu}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        className={classNames(
          showBurgerMenu ? 'bg-soft-primary' : 'bg-transparent'
        )}
      >
        <div className="navbar-vertical-content scrollbar">
          <Nav className="flex-column nav mt-xl-2 mb-3" as="ul">
            <NavbarLabel label={SalesModuleRoutes.label} />
            <NavbarVerticalMenu routes={SalesModuleRoutes.children} />
          </Nav>
        </div>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default NavbarVerticalWithoutBackground;
