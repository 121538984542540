import { AuthCardLeft } from 'assets/img/aida-auth/images';
import Flex from 'components/common/Flex';
import Logo from 'components/common/Logo';
import Section from 'components/common/Section';
import PropTypes from 'prop-types';
import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';

const AidaErrorLayout = ({ children, imgStyle = {}, backgroundImage }) => {
  return (
    <Section className="py-0">
      <Row className="g-0 min-vh-100 flex-center">
        <Col lg={8} xxl={5} className="py-3 position-relative">
          <Logo />
          <Card className="overflow-hidden z-index-1">
            <Card.Body className="p-0">
              <Row className="h-100 g-0">
                <Col
                  md={5}
                  style={{
                    ...{
                      backgroundImage: `url(${backgroundImage})`,
                      backgroundSize: 'cover',
                      backgroundRepeat: 'no-repeat',
                      backgroundPosition: 'center',
                      minHeight: '575px'
                    },
                    ...imgStyle
                  }}
                  className="text-dark py-6 text-center"
                ></Col>
                <Col
                  md={7}
                  as={Flex}
                  alignItems="center"
                  justifyContent="center"
                >
                  {children}
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Section>
  );
};

AidaErrorLayout.propTypes = {
  match: PropTypes.object
};

export default AidaErrorLayout;
