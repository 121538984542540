import React, { useContext } from 'react';
import { AdsAccountContext } from './AdsAccountContext';

const ViewForm = () => {
  const adsAccountContext = useContext(AdsAccountContext);
  const { currentStep } = adsAccountContext;

  return <>{currentStep?.component}</>;
};

export default ViewForm;
