import React, { useContext, useState } from 'react';
import { Nav, Tooltip, OverlayTrigger, Dropdown } from 'react-bootstrap';
import ProfileDropdown from 'components/navbar/top/ProfileDropdown';
import NotificationDropdown from 'components/navbar/top/NotificationDropdown';
import CartNotification from 'components/navbar/top/CartNotification';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AppContext from 'context/Context';
import RecentTasksDropdown from './RecentTasksDropdown';
import { AidaAppContext } from 'context/AidaAppContext';
import { faChartLine } from '@fortawesome/free-solid-svg-icons';
import { HiOutlinePresentationChartLine } from 'react-icons/hi';
import { Link } from 'react-router-dom';
import { AppSettings } from 'settings';
import Avatar from 'components/common/Avatar';
import { data } from 'jquery';
import { appRoutes, profileDropdownRoutes } from 'routes/routes';
import { inArray } from 'is_js';

const TopNavRightSideNavItem = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const aidaAppContext = useContext(AidaAppContext);
  const {
    config: { isDark },
    setConfig
  } = useContext(AppContext);
  return (
    <Nav
      navbar
      className="navbar-nav-icons ms-auto flex-row align-items-center"
      as="ul"
    >
      {aidaAppContext.user_modules?.includes('sales') ? (
        <Nav.Item>
          <Link
            to="/sales/research-competitor"
            className="px-2 nav-link theme-control-toggle"
          >
            <div className="theme-control-toggle-label">
              <HiOutlinePresentationChartLine className="fs-1" />
            </div>
          </Link>
        </Nav.Item>
      ) : null}

      {/* {AppSettings.apiUrl?.includes('.test') ? (
        <Nav.Item>
          <Nav.Link
            as={'div'}
            className="px-2 theme-control-toggle"
            onClick={() => setConfig('isDark', !isDark)}
          >
            <OverlayTrigger
              overlay={
                <Tooltip id="hi">
                  {isDark ? 'Switch to light theme' : 'Switch to dark theme'}
                </Tooltip>
              }
              placement="left"
            >
              <div className="theme-control-toggle-label">
                <FontAwesomeIcon
                  icon={isDark ? 'sun' : 'moon'}
                  className="fs-0"
                />
              </div>
            </OverlayTrigger>
          </Nav.Link>
        </Nav.Item>
      ) : null} */}
      {inArray(aidaAppContext.user_role, ['Admin', 'Manager', 'Sales']) ? (
        <Nav.Item>
          <Dropdown
            navbar={true}
            show={dropdownOpen}
            onClick={() => setDropdownOpen(!dropdownOpen)}
            as="li"
          >
            <Dropdown.Toggle
              bsPrefix="toggle"
              as={'a'}
              className="nav-link d-flex align-items-center cursor-pointer theme-control-toggle"
            >
              {aidaAppContext.agency?.favicon ? (
                <Avatar src={aidaAppContext.agency?.favicon} />
              ) : (
                <div className="theme-control-toggle-label">
                  <FontAwesomeIcon className="fs-1" icon={'cog'} />
                </div>
              )}
            </Dropdown.Toggle>

            <Dropdown.Menu className="dropdown-menu-card  dropdown-menu-end">
              <div className="bg-white rounded-2 py-2 dark__bg-1000">
                {appRoutes.children
                  //agency module checking
                  .filter(route =>
                    route?.identifier && route?.moduleType == 'agency'
                      ? aidaAppContext.agency_modules.includes(
                          route?.identifier
                        )
                      : true
                  )
                  //user role based access checking
                  .filter(route =>
                    route?.rolesCanAccess?.includes(aidaAppContext.user_role)
                  )
                  .map((route, index) => (
                    <Dropdown.Item key={index} as={Link} to={route.to}>
                      {route.name}
                    </Dropdown.Item>
                  ))}
              </div>
            </Dropdown.Menu>
          </Dropdown>
        </Nav.Item>
      ) : null}
      {/* {aidaAppContext.user_permissions?.access_top_nav_links && (
        )}
        <RecentTasksDropdown /> */}
      {/* <NotificationDropdown /> */}
      <ProfileDropdown />
    </Nav>
  );
};

export default TopNavRightSideNavItem;
