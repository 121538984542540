import { IoInformationCircleOutline } from 'react-icons/io5';
import { getItemFromStore, setItemToStore } from 'helpers/utils';
import React, { useContext, useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Flip, toast } from 'react-toastify';
import { AidaAppContext } from 'context/AidaAppContext';
import CallApi from './CallApi';
import { useQuery } from 'react-query';

const copyToClipBoard = text => {
  /* Copy the text inside the text field */
  navigator.clipboard.writeText(text);
  toast.info('Identifier copied to clipboard', {
    position: 'top-center',
    bodyClassName: 'fw-bold',
    autoClose: 1250,
    closeOnClick: true,
    draggable: true,
    hideProgressBar: true,
    transition: Flip
  });
};

const LocalStorageTooltip = ({
  className = '',
  identifier,
  customIcon = null
}) => {
  const aidaAppContext = useContext(AidaAppContext);
  const { user_role } = aidaAppContext;

  const [tooltips, setTooltips] = useState(getItemFromStore('tooltips', []));

  useQuery(['tooltips'], () => CallApi.get('/tooltip', false), {
    onSuccess: json => {
      setItemToStore('tooltips', json?.data || []);
      setTooltips(json?.data);
    },
    enabled: tooltips?.length == 0 && !!user_role
  });

  const tooltip = tooltips?.find(item => item?.key == identifier);

  if (tooltip?.hidden) return <></>;

  return (
    <>
      <OverlayTrigger
        placement="top"
        overlay={
          <Tooltip>
            {tooltip?.value ||
              `Add "${identifier}" in the tooltips table to replace this message. Click on the ${
                customIcon ? '' : 'i'
              } icon ${customIcon ? 'itself' : ''} to copy the "key"`}
          </Tooltip>
        }
      >
        <span
          className={`${className} cursor-pointer`}
          onClick={() => copyToClipBoard(identifier)}
        >
          {customIcon ? (
            customIcon
          ) : (
            <IoInformationCircleOutline className="fw-medium fs-1 pb-1" />
          )}
        </span>
      </OverlayTrigger>
    </>
  );
};

export default LocalStorageTooltip;
