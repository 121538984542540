import CallApi from 'components/common/custom-components/CallApi';
import LocalStorageTooltip from 'components/common/custom-components/LocalStorageTooltip';
import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';

const ClientUpsertForm = ({
  closeModal,
  clientId,
  setNewClientId = id => {}
}) => {
  const [errors, setErrors] = useState({ name: [], info: [] });
  const { handleSubmit, register, control, reset } = useForm();

  const manageFormResponse = json => {
    if (json.status == 422) {
      setErrors(json.errors);
      toast.error(json.message);
    }

    if (json.status == 200) {
      closeModal();
    }
  };

  const CreateClientMutation = useMutation(
    'client',
    formData => CallApi.post('/clients', formData),
    {
      onSuccess: json => {
        setNewClientId(json.data?.id);
        manageFormResponse(json);
      }
    }
  );

  const UpdateClientMutation = useMutation(
    ['client', clientId],
    formData => CallApi.put('/clients/' + clientId, formData, true, false),
    {
      onSuccess: json => manageFormResponse(json)
    }
  );

  const onSubmit = (data, form) => {
    const formData = new FormData(form.target);

    if (clientId) {
      UpdateClientMutation.mutate(formData);
    } else {
      CreateClientMutation.mutate(formData);
    }
  };

  const ExistingClientQuery = useQuery(
    ['client', clientId],
    () => CallApi.get(`/clients/${clientId}`),
    {
      onSuccess: json => reset(json.data),
      enabled: !!clientId
    }
  );

  return (
    <Form onSubmit={handleSubmit(onSubmit)} id="client-form">
      <Form.Group className="mb-3">
        <Form.Label>
          Name
          <LocalStorageTooltip
            className="ms-1"
            identifier={'form_client_field_name'}
          />
        </Form.Label>
        <Form.Control
          {...register('name', { required: true })}
          isInvalid={errors?.name?.length > 0}
        />
        <Form.Control.Feedback type="invalid">
          {errors?.name && errors?.name?.map(error => error)}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>
          Info
          <LocalStorageTooltip
            className="ms-1"
            identifier={'form_client_field_info'}
          />
        </Form.Label>
        <Form.Control
          {...register('info')}
          isInvalid={errors?.info?.length > 0}
        />
        <Form.Control.Feedback type="invalid">
          {errors?.info && errors?.info?.map(error => error)}
        </Form.Control.Feedback>
      </Form.Group>
      {/* <Form.Group className="mb-3" controlId="favicon">
        <Form.Label className="w-100">
          <div className="align-items-center row">
            <div className="col">
              <h6 className="mb-0">Favicon*</h6>
            </div>
            <div className="text-right col-auto">
              <a
                className="py-1 fs--1 font-sans-serif"
                target="_blank"
                rel="noreferrer"
                href="https://favicongrabber.com/"
              >
                Download Icon
              </a>
            </div>
          </div>
        </Form.Label>
        <Form.Control
          type="file"
          {...register('favicon')}
          placeholder="Select image"
        />
        <Form.Control.Feedback type="invalid">
          Please choose a favicon.
        </Form.Control.Feedback>
      </Form.Group> */}
    </Form>
  );
};

export default ClientUpsertForm;
