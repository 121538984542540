import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LogoutImg } from 'assets/img/aida-auth/images';
import CallApi from 'components/common/custom-components/CallApi';
import { AidaAppContext, defaultAidaAppContext } from 'context/AidaAppContext';
import { deleteItemFromStore, getItemFromStore } from 'helpers/utils';
import { widget } from 'react-freshchat';
import { useQueryClient } from 'react-query';

const LogoutContent = ({ layout, titleTag: TitleTag }) => {
  const aidaAppContext = useContext(AidaAppContext);
  const { setAidaAppContext } = aidaAppContext;
  const queryClient = useQueryClient();

  useEffect(() => {
    if (typeof widget == 'function') {
      widget().destroy();
    }
    setAidaAppContext({ ...defaultAidaAppContext });
    if (getItemFromStore('aidaToken')) {
      CallApi.post('/logout');
    }
    deleteItemFromStore('aidaToken');
    deleteItemFromStore('tooltips');
    deleteItemFromStore('frontend_pages');
    queryClient.removeQueries();
  }, []);

  return (
    <>
      <div className="position-relative">
        <img className="d-block mx-auto mb-4" src={LogoutImg} alt="shield" />
        <span
          className="position-absolute fw-bold font-ibm-plex-sans fs-3 text-white"
          style={{
            top: '25%',
            right: '29%'
          }}
        >
          See you <br />
          soon!
        </span>
      </div>

      <p className="font-ibm-plex-sans">
        Thanks you for your visit! <br className="d-none d-sm-block" />
        See you soon ;)
      </p>
      <Button as={Link} className="mt-3 rounded-pill" to={`/${layout}/login`}>
        <FontAwesomeIcon
          icon="chevron-left"
          transform="shrink-4 down-1"
          className="me-1"
        />
        Return to Login
      </Button>
    </>
  );
};

LogoutContent.propTypes = {
  layout: PropTypes.string,
  titleTag: PropTypes.string
};

LogoutContent.defaultProps = {
  layout: 'simple',
  titleTag: 'h4'
};

export default LogoutContent;
