import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CheckMailPlane } from 'assets/img/aida-auth/images';

const ConfirmMailContent = ({ email, layout, titleTag: TitleTag }) => (
  <>
    <img
      className="d-block rounded-circle mx-auto mb-4"
      src={CheckMailPlane}
      alt="sent"
      width={200}
    />
    <TitleTag className="font-ibm-plex-sans mb-3">Check your email!</TitleTag>
    <p>
      An email has been sent to provided email address. Please click on the
      included link to reset your password.
    </p>
    <Button
      as={Link}
      size="sm"
      className="mt-3 rounded-pill"
      to={`/${layout}/login`}
    >
      <FontAwesomeIcon
        icon="chevron-left"
        transform="shrink-4 down-1"
        className="me-1"
      />
      Return to login
    </Button>
  </>
);

ConfirmMailContent.propTypes = {
  email: PropTypes.string.isRequired,
  layout: PropTypes.string,
  titleTag: PropTypes.string
};

ConfirmMailContent.defaultProps = { layout: 'simple', titleTag: 'h4' };

export default ConfirmMailContent;
