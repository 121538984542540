import LocalStoragePageInfo from 'components/common/custom-components/LocalStoragePageInfo';
import NavbarVerticalWithoutBackground from 'components/navbar/vertical/NavbarVerticalWithoutBackground';
import VerticalNavbarLayout from 'layouts/VerticalNavbarLayout';
import React from 'react';

const Sales = () => {
  return (
    <VerticalNavbarLayout navbar={<NavbarVerticalWithoutBackground />}>
      <LocalStoragePageInfo slug="sales" />
    </VerticalNavbarLayout>
  );
};

export default Sales;
