import Avatar from 'components/common/Avatar';
import CommonImageUpdaterModal from 'components/common/custom-components/CommonImageUpdaterModal';
import FalconCardHeader from 'components/common/FalconCardHeader';
import React, { useContext } from 'react';
import { Card, Button } from 'react-bootstrap';
import { AdsAccountContext } from '../../AdsAccountContext';

const YourLogo = () => {
  const adsAccountContext = useContext(AdsAccountContext);
  const { currentAccount, refetchCurrentAccount } = adsAccountContext;

  return (
    <Card>
      <Card.Body className="m-3">
        <h5 className="border-bottom mt-0 border-dark border-2 mb-4 pb-3">
          Your Logo
        </h5>
        <div className="mb-4 py-4 text-center rounded border-2 border aida-border-dashed">
          <img src={currentAccount?.logo} width={'100px'} />
        </div>
        <CommonImageUpdaterModal
          modalTitle="Logo"
          fieldName="logo"
          updateUrl={`/account/${currentAccount?.id}/logo`}
          refetchCurrent={refetchCurrentAccount}
          className="w-100 rounded-pill"
          variant="outline-primary"
        >
          Change Logo
        </CommonImageUpdaterModal>
      </Card.Body>
    </Card>
  );
};

export default YourLogo;
