import Flex from 'components/common/Flex';
import IconButton from 'components/common/IconButton';
import LocalStoragePageInfo from 'components/common/custom-components/LocalStoragePageInfo';
import NavbarVerticalWithoutBackground from 'components/navbar/vertical/NavbarVerticalWithoutBackground';
import { AidaAppContext } from 'context/AidaAppContext';
import useUrl from 'hooks/useUrl';
import VerticalNavbarLayout from 'layouts/VerticalNavbarLayout';
import React, { useContext, useState } from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';
import useCountrySelectBox from '../custom-form-hooks/useCountrySelectBox';
import CompetitorsTable from './components/CompetitorsTable';

const CompetitorAnalysis = () => {
  const aidaAppContext = useContext(AidaAppContext);
  const { current_user } = aidaAppContext;
  const url = useUrl();
  const [refetch, setRefetch] = useState(true);
  const [source, setSource] = useState('');
  const [domain, setDomain] = useState(url.get('domain') || '');
  const [lastRanOn, setLastRanOn] = useState({ domain: '', source: '' });
  const handleSubmit = e => {
    e.preventDefault();
    setRefetch(!refetch);
    setSource(source || current_user?.country?.iso_3166_2);
    setLastRanOn({
      domain: domain,
      source: source || current_user?.country?.iso_3166_2
    });
  };

  const { CountrySelectbox } = useCountrySelectBox('iso_3166_2');

  return (
    <VerticalNavbarLayout navbar={<NavbarVerticalWithoutBackground />}>
      <LocalStoragePageInfo slug="research_competitor" />
      <Card className="mb-5">
        <Card.Body className="m-3">
          <Form onSubmit={handleSubmit}>
            <Flex alignItems={'center'}>
              <Form.Group className="col me-2">
                <Form.Control
                  size="sm"
                  placeholder="Enter Competitor domain"
                  onChange={e => setDomain(e.target.value)}
                  value={domain}
                  required
                />
              </Form.Group>
              <Form.Group className="col-3 me-2">
                <CountrySelectbox
                  value={source || current_user?.country?.iso_3166_2}
                  size="sm"
                  isSearchable
                  onChange={country => {
                    setSource(country.iso_3166_2);
                  }}
                />
              </Form.Group>
              <Form.Group className="col-auto">
                <IconButton
                  size="sm"
                  variant="outline-primary"
                  className="rounded-pill"
                  icon={'play'}
                  disabled={
                    lastRanOn.domain == domain && lastRanOn.source == source
                  }
                  type="submit"
                >
                  Run Now
                </IconButton>
              </Form.Group>
            </Flex>
          </Form>
        </Card.Body>
      </Card>
      <CompetitorsTable
        refetch={refetch}
        source={source || current_user?.country?.iso_3166_2}
        domain={domain}
      />
      {/* <Col xs={12} lg={6}>
          <DomainHistoryOverviewTable
            refetch={refetch}
            source={source}
            domain={domain}
          />
        </Col> */}
    </VerticalNavbarLayout>
  );
};

export default CompetitorAnalysis;
