import React from 'react';
import { Switch, Route, Redirect, useRouteMatch } from 'react-router-dom';
import Error404 from './Error404';
import Error500 from './Error500';

const AidaErrorRoutes = () => {
  const { url } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${url}/404`} component={Error404} />
      <Route path={`${url}/500`} component={Error500} />
      <Redirect to={`${url}/404`} />
    </Switch>
  );
};

export default AidaErrorRoutes;
