import CallApi from 'components/common/custom-components/CallApi';
import ReactSelectBoxImageLabel from 'components/common/custom-components/ReactSelectBoxImageLabel';
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import ReactSelect from 'react-select';

const useReportLanguageSelectBox = (identifier = 'id') => {
  const getLanguages = () => CallApi.get('/report-languages', false);
  const { data, refetch } = useQuery('report-languages', getLanguages, {
    placeholderData: { data: [] }
  });

  const ReportLanguageSelectBox = ({
    name = 'report_language_id',
    className = '',
    isClearable = false,
    isDisabled = false,
    isLoading = false,
    isMulti = false,
    isSearchable = false,
    placeholder = 'Please select a report language',
    onChange = reportlanguage => {},
    value,
    size = 'md',
    ...rest
  }) => {
    const [reportlanguage, setReportLanguage] = useState(null);

    useEffect(() => {
      if (data?.data?.length > 0) {
        setReportLanguage(
          data.data.find(reportlanguage => reportlanguage[identifier] == value)
        );
      }
    }, [value]);

    const smSizeStyles = {
      control: provided => ({
        ...provided,
        minHeight: 10
      }),
      dropdownIndicator: provided => ({ ...provided, padding: 1 }),
      clearIndicator: provided => ({ ...provided, padding: 2 }),
      input: provided => ({ ...provided, padding: 0, margin: 0 })
    };

    const getStyles = () => {
      if (size == 'sm') return smSizeStyles;
    };

    return (
      <ReactSelect
        styles={getStyles()}
        placeholder={placeholder}
        options={data?.data || []}
        name={name}
        onChange={reportlanguage => onChange(reportlanguage)}
        value={reportlanguage}
        getOptionValue={reportlanguage => reportlanguage[identifier]}
        getOptionLabel={reportlanguage => reportlanguage?.name}
        className={className}
        isClearable={isClearable}
        isDisabled={isDisabled}
        isLoading={isLoading}
        isMulti={isMulti}
        isSearchable={isSearchable}
        {...rest}
      />
    );
  };

  return { ReportLanguageSelectBox, refetch };
};

export default useReportLanguageSelectBox;
