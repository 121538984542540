import IconButton from 'components/common/IconButton';
import React, { useState } from 'react';
import { Form, Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';

const ReRunCompetitorsModal = ({ rerunCompetitors = domain => {} }) => {
  const [showModal, setShowModal] = useState(false);
  const toggleModal = () => setShowModal(!showModal);
  const { register, handleSubmit, reset } = useForm();
  const onSubmit = (data, form) => {
    rerunCompetitors(data.competitor_url);
    reset();
    toggleModal();
  };

  return (
    <>
      <IconButton
        icon={'redo'}
        variant="outline-primary"
        className="rounded-pill "
        size="sm"
        onClick={toggleModal}
      >
        Re-run competitors
      </IconButton>
      <Modal show={showModal} centered onHide={toggleModal}>
        <Modal.Header closeButton>
          <Modal.Title>Rerun competitors</Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-3">
          <small>
            If your website is very new or you have just started working with
            SEO, Trakk does not always find enough competitors. Therefore, you
            can redo the competition analysis here, but with one of your
            competitors as a starting point.
          </small>
          <Form id="rerun-competitors-form" onSubmit={handleSubmit(onSubmit)}>
            <Form.Group className="my-3">
              <Form.Label>Competitor URL</Form.Label>
              <Form.Control
                autoFocus
                {...register('competitor_url', {
                  required: true
                })}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <IconButton
            icon="times-circle"
            size="sm"
            onClick={toggleModal}
            variant="falcon-light"
          >
            Close
          </IconButton>
          <IconButton
            variant="outline-primary"
            className="rounded-pill"
            form="rerun-competitors-form"
            type="submit"
            icon="redo"
            size="sm"
          >
            Re-run
          </IconButton>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ReRunCompetitorsModal;
