import CallApi from 'components/common/custom-components/CallApi';
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import ReactSelect from 'react-select';

const useKeywordPurposesSelectBox = (identifier = 'id') => {
  const getKeywordPurposes = () => CallApi.get('/keyword-purposes', false);

  const { data, refetch } = useQuery('keyword-purposes', getKeywordPurposes, {
    placeholderData: { data: [] }
  });

  const KeywordPurposeSelectBox = ({
    name = 'keyword_purpose_id',
    className = '',
    isClearable = false,
    isDisabled = false,
    isLoading = false,
    isMulti = false,
    isSearchable = false,
    placeholder = 'Please select a keyword purpose',
    onChange = keywordPurpose => {},
    value,
    size = 'md',
    ...rest
  }) => {
    const [keywordPurpose, setKeywordPurpose] = useState(null);

    useEffect(() => {
      if (data?.data?.length > 0) {
        setKeywordPurpose(
          data.data.find(keywordPurpose => keywordPurpose[identifier] == value)
        );
      }
    }, [value]);

    const smSizeStyles = {
      control: provided => ({
        ...provided,
        minHeight: 10
      }),
      dropdownIndicator: provided => ({ ...provided, padding: 1 }),
      clearIndicator: provided => ({ ...provided, padding: 2 }),
      input: provided => ({ ...provided, padding: 0, margin: 0 })
    };

    const getStyles = () => {
      if (size == 'sm') return smSizeStyles;
    };

    return (
      <ReactSelect
        styles={{
          ...getStyles(),
          ...{
            container: base => ({
              ...base,
              minWidth: '150px'
            }),
            menuPortal: base => ({ ...base, zIndex: 9999 })
          }
        }}
        placeholder={placeholder}
        options={data?.data || []}
        name={name}
        onChange={keywordPurpose => onChange(keywordPurpose)}
        value={keywordPurpose}
        getOptionValue={keywordPurpose => keywordPurpose[identifier]}
        getOptionLabel={keywordPurpose => keywordPurpose?.name}
        className={className}
        isClearable={isClearable}
        isDisabled={isDisabled}
        isLoading={isLoading}
        isMulti={isMulti}
        classNamePrefix="react-select"
        menuPortalTarget={document.body}
        isSearchable={isSearchable}
        {...rest}
      />
    );
  };

  return { KeywordPurposeSelectBox, refetch };
};

export default useKeywordPurposesSelectBox;
