import CallApi from 'components/common/custom-components/CallApi';
import ReactSelectBoxImageLabel from 'components/common/custom-components/ReactSelectBoxImageLabel';
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import ReactSelect from 'react-select';

const useUserSelectBox = (customQuery = null) => {
  const getUsers = async () => {
    const users = await CallApi.get(
      customQuery ? customQuery : `/users?filter[role.name]=admin,user`,
      false
    );
    users.data.sort((a, b) =>
      a.first_name + a.last_name > b.first_name + b.last_name ? 1 : -1
    );
    return users;
  };
  const { data } = useQuery('users', getUsers, {
    placeholderData: { data: [] }
  });

  const UserSelectBox = ({
    name = 'user_id',
    className = '',
    isClearable = false,
    isDisabled = false,
    isLoading = false,
    isMulti = false,
    isSearchable = false,
    placeholder = 'Please select a user',
    onChange = user => {},
    value,
    size = 'md',
    ...rest
  }) => {
    const [user, setUser] = useState(null);

    useEffect(() => {
      if (data?.data?.length > 0) {
        setUser(data.data.find(user => user.id == value));
      }
    }, [value]);

    const smSizeStyles = {
      control: provided => ({
        ...provided,
        minHeight: 10
      }),
      dropdownIndicator: provided => ({ ...provided, padding: 1 }),
      clearIndicator: provided => ({ ...provided, padding: 2 }),
      input: provided => ({ ...provided, padding: 0, margin: 0 })
    };

    const getStyles = () => {
      if (size == 'sm') return smSizeStyles;
    };

    return (
      <ReactSelect
        styles={getStyles()}
        placeholder={placeholder}
        options={data?.data}
        name={name}
        formatOptionLabel={user => (
          <ReactSelectBoxImageLabel src={user.avatar_url} title={user.name} />
        )}
        onChange={user => onChange(user)}
        value={user}
        getOptionValue={user => user.id}
        getOptionLabel={user => user.name}
        className={className}
        isClearable={isClearable}
        isDisabled={isDisabled}
        isLoading={isLoading}
        isMulti={isMulti}
        isSearchable={isSearchable}
        {...rest}
      />
    );
  };

  return UserSelectBox;
};

export default useUserSelectBox;
