import CallApi from 'components/common/custom-components/CallApi';
import useUrl from 'hooks/useUrl';
import React, { createContext, useState } from 'react';
import { useQuery } from 'react-query';
import { EditAccountFormSteps } from './AdsAccountSidebar';

export const defaultAdsAccountContext = {
  currentStep: EditAccountFormSteps[0],
  currentAccount: {},
  refetchCurrentAccount: () => {},
  editTexts: {
    sitelinks: []
  },
  setAdsAccountContext: newContext => {}
};

export const AdsAccountContext = createContext(defaultAdsAccountContext);

const AdsAccountProvider = ({ children }) => {
  const [adsAccountContext, setAdsAccountContext] = useState({
    ...defaultAdsAccountContext
  });
  const url = useUrl();

  const { refetch } = useQuery(
    ['account', url.get('id')],
    () => CallApi.get(`/accounts/${url.get('id')}`, false),
    {
      onSuccess: json =>
        setAdsAccountContext({
          ...adsAccountContext,
          currentAccount: json?.data
        }),
      enabled: !!url.get('id')
    }
  );

  return (
    <AdsAccountContext.Provider
      value={{
        ...adsAccountContext,
        refetchCurrentAccount: refetch,
        setAdsAccountContext: value => setAdsAccountContext(value)
      }}
    >
      {children}
    </AdsAccountContext.Provider>
  );
};

export default AdsAccountProvider;
