import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import CallApi from 'components/common/custom-components/CallApi';
import LocalStoragePageInfo from 'components/common/custom-components/LocalStoragePageInfo';
import NameWithIcon from 'components/common/custom-components/NameWithIcon';
import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { useMutation, useQuery } from 'react-query';
import Listing from './components/Listing';
import classNames from 'classnames';
import IconButton from 'components/common/IconButton';
import { Link } from 'react-router-dom';
import LocalStorageTooltip from 'components/common/custom-components/LocalStorageTooltip';

const SeoProjectDrafts = () => {
  const SeoProjectDraftsQuery = useQuery(
    ['seo-project-drafts'],
    () => CallApi.get('/seo-project-drafts', false),
    { placeholderData: { data: [] } }
  );

  const columns = [
    {
      accessor: 'id',
      Header: (
        <>
          Actions
          <LocalStorageTooltip identifier="table_seo_project_drafts_column_actions" />
        </>
      ),
      Cell: ({ cell }) => {
        const DeleteSeoProjectDraftMutation = useMutation(
          ['delete-seo-project'],
          () => CallApi.delete('/seo-project-drafts/' + cell.value),
          {
            onSuccess: json =>
              json?.status == 200 && SeoProjectDraftsQuery.refetch()
          }
        );

        return (
          <>
            <Link to={`/seo-project/${cell.value}`}>
              <FontAwesomeIcon
                className="text-primary me-2 cursor-pointer"
                icon={'eye'}
              />
            </Link>
            <FontAwesomeIcon
              icon={
                DeleteSeoProjectDraftMutation.isLoading
                  ? 'spinner'
                  : 'trash-alt'
              }
              className="ms-1 text-dark cursor-pointer"
              spin={DeleteSeoProjectDraftMutation.isLoading}
              onClick={() =>
                confirm('Do you really want to delete this draft?') &&
                DeleteSeoProjectDraftMutation.mutate()
              }
            />
          </>
        );
      }
    },
    {
      accessor: 'main_information.name',
      Header: (
        <>
          Name
          <LocalStorageTooltip identifier="table_seo_project_drafts_column_name" />
        </>
      )
    },
    {
      accessor: 'main_information.domain',
      Header: (
        <>
          Website
          <LocalStorageTooltip identifier="table_seo_project_drafts_column_website" />
        </>
      )
    },
    {
      accessor: 'main_information.number_of_keywords',
      Header: (
        <>
          Keywords
          <LocalStorageTooltip identifier="table_seo_project_drafts_column_keywords" />
        </>
      ),
      headerProps: { className: 'text-end' },
      cellProps: { className: 'text-end' }
    },
    {
      accessor: 'managing_user.name',
      Header: (
        <>
          Contact Person
          <LocalStorageTooltip identifier="table_seo_project_drafts_column_contact_person" />
        </>
      ),
      Cell: ({ cell }) => {
        return (
          <NameWithIcon
            icon={cell.row.original.managing_user?.avatar_url}
            name={cell.value}
          />
        );
      }
    },
    {
      accessor: 'note',
      Header: (
        <>
          Note
          <LocalStorageTooltip identifier="table_seo_project_drafts_column_note" />
        </>
      )
    },
    {
      accessor: 'last_completed_step',
      Header: (
        <>
          Last Completed Step
          <LocalStorageTooltip identifier="table_seo_project_drafts_column_last_completed_step" />
        </>
      )
    },
    {
      accessor: 'updated_at',
      Header: (
        <>
          Last edited at
          <LocalStorageTooltip identifier="table_seo_project_drafts_column_last_edited_at" />
        </>
      ),
      headerProps: { className: 'text-end' },
      cellProps: { className: 'text-end' }
    }
  ];

  return (
    <>
      <LocalStoragePageInfo slug="seo-project-drafts" />
      <Card>
        <Card.Body className="m-3">
          <AdvanceTableWrapper
            columns={columns}
            data={SeoProjectDraftsQuery?.data?.data || []}
            sortable
            pagination
          >
            <Row className="align-items-end g-2 row mb-3">
              <Col xs="auto">
                <AdvanceTableSearchBox table />
              </Col>
              <Col />
              <Col xs="auto">
                <Link to={'/seo-projects'}>
                  <IconButton
                    icon={'reply'}
                    size="sm"
                    className="rounded-pill "
                    variant="outline-primary"
                  >
                    Back to SEO Projects
                  </IconButton>
                </Link>
              </Col>
            </Row>
            <AdvanceTable
              table
              headerClassName="bg-aida-table-header text-900 text-nowrap align-middle"
              rowClassName="align-middle white-space-nowrap"
              tableProps={{
                bordered: true,
                striped: true,
                className: 'fs--1 mb-0 overflow-hidden'
              }}
              apiCallInProcess={SeoProjectDraftsQuery.isFetching}
            />
            <div className="mt-3">
              <AdvanceTableFooter
                rowCount={SeoProjectDraftsQuery?.data?.data?.length}
                table
                rowInfo
                navButtons
                rowsPerPageSelection
              />
            </div>
          </AdvanceTableWrapper>
        </Card.Body>
      </Card>
    </>
  );
};

export default SeoProjectDrafts;
