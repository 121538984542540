import React, { useEffect } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { BrowserRouter as Router } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.min.css';
import Layout from './layouts/Layout';
import CallApi from 'components/common/custom-components/CallApi';
import { getItemFromStore, setItemToStore } from 'helpers/utils';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import './assets/css/style.css';

/**
 * Pre-Adding fa icons in the app
 * Check for more info: https://fontawesome.com/v5/docs/web/use-with/react#using-icons
 */
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faFile,
  faUserCheck,
  faUserTimes
} from '@fortawesome/free-solid-svg-icons';
import AppProvider from 'context/AidaAppContext';
import { AppSettings } from './settings';

library.add(faUserCheck, faUserTimes, faFile);

const App = () => {
  useEffect(() => {
    document.body.style.backgroundColor = '#E6E7E1';
  }, []);

  return (
    <Router basename={process.env?.PUBLIC_URL}>
      <QueryClientProvider
        client={
          new QueryClient({
            defaultOptions: {
              queries: {
                refetchOnWindowFocus: false
              }
            }
          })
        }
      >
        <AppProvider>
          <Layout />
          {AppSettings.apiUrl?.includes('.test') ? (
            <ReactQueryDevtools
              toggleButtonProps={{ className: 'ms-4 mb-4' }}
            />
          ) : null}
        </AppProvider>
      </QueryClientProvider>
    </Router>
  );
};

export default App;
