import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { WebMonitorPerson } from 'assets/img/accounts/images';
import IconButton from 'components/common/IconButton';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import CallApi from 'components/common/custom-components/CallApi';
import LocalStoragePageInfo from 'components/common/custom-components/LocalStoragePageInfo';
import React, { useContext, useState } from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';
import { useMutation, useQuery } from 'react-query';
import { AdsAccountContext } from '../AdsAccountContext';
import ExportXLSX from 'components/aida/budget-overviews/components/ExportXLSX';
import { getYYYYMMDDFormatDate } from 'helpers/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ImportAdgroupsModal from './ImportAdgroupsModal';

const Adgroups = () => {
  const adsAccountContext = useContext(AdsAccountContext);
  const { currentAccount } = adsAccountContext;
  const [showInput, setShowInput] = useState(false);

  const KeywordsQuery = useQuery(
    ['account-keywords', currentAccount?.id],
    () => CallApi.get(`/account/${currentAccount?.id}/keywords`),
    { placeholderData: { data: [] }, enabled: !!currentAccount?.id }
  );

  const ExportAsExcelMutation = useMutation(
    ['re-run-web-monitoring-ping'],
    () => CallApi.get(`/account/${currentAccount?.id}/keywords-export`)
  );

  const columns = [
    { accessor: 'keyword', Header: 'Keyword' },
    { accessor: 'adgroup.name', Header: 'Adgroup' }
  ];

  return (
    <>
      <LocalStoragePageInfo slug="account_adgroups">
        <Row>
          <Col className="px-5 py-4">
            <h2 className="mb-0 font-ibm-plex-serif">Adgroups</h2>
            <p className="fs-1 mt-3">Keyword - Adgroup pairs</p>
          </Col>
          <Col lg={3} xxl={4} />
          <Col lg={4} xxl={3}>
            <img src={WebMonitorPerson} className="w-100" />
          </Col>
        </Row>
      </LocalStoragePageInfo>
      <Card>
        <Card.Body>
          <AdvanceTableWrapper
            columns={columns}
            data={KeywordsQuery?.data?.data || []}
            sortable
            pagination
            perPage={25}
          >
            <Row className="align-items-center mb-3">
              <Col xs="auto">
                <AdvanceTableSearchBox table />
              </Col>
              <Col />
              {showInput ? (
                <Col xs="auto">
                  <Form.Control size="sm" type="file" />
                  <FontAwesomeIcon
                    icon={'times'}
                    onClick={() => setShowInput(false)}
                  />
                </Col>
              ) : null}
              <Col xs="auto">
                <ImportAdgroupsModal
                  refetch={KeywordsQuery.refetch}
                  className="rounded-pill me-2"
                />
                <ExportXLSX
                  table
                  variant="outline-primary"
                  className="rounded-pill"
                  sheetHeaderRow={['Keyword', 'Adgroup']}
                  filename={`${getYYYYMMDDFormatDate(
                    new Date()
                  )} - Keyword Adgroup Pairs`}
                />
              </Col>
            </Row>
            <AdvanceTable
              table
              headerClassName="bg-aida-table-header text-900 text-nowrap align-middle"
              rowClassName="align-middle white-space-nowrap"
              tableProps={{
                className: 'fs--1 mb-0 overflow-hidden'
              }}
              loadingText={'Loading keywords...'}
              apiCallInProcess={KeywordsQuery.isLoading}
            />
            <AdvanceTableFooter
              rowsPerPageOptions={[25, 50, 75, 100]}
              table
              className="mt-3 mx-3"
              rowCount={KeywordsQuery?.data?.data?.length}
              rowInfo
              navButtons
              rowsPerPageSelection
            />
          </AdvanceTableWrapper>
        </Card.Body>
      </Card>
    </>
  );
};

export default Adgroups;
