import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import CallApi from 'components/common/custom-components/CallApi';
import React, { useState } from 'react';
import {
  Button,
  ButtonGroup,
  Card,
  Col,
  OverlayTrigger,
  Row,
  Tooltip
} from 'react-bootstrap';
import { useQuery } from 'react-query';
import ManualAddCompetitorModal from './ManualAddCompetitorModal';
import StepDetailsCard from './StepDetailsCard';
import LocalStoragePageInfo from 'components/common/custom-components/LocalStoragePageInfo';

const CompetitorsForm = ({
  onNext = () => {},
  setSelectedCompetitors = selectedRows => {},
  onPrevious,
  accountDraftId
}) => {
  const columns = [
    {
      accessor: 'domain',
      Header: 'Domain',
      Cell: ({ cell }) => (
        <a
          href={`https://${cell.value}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon icon={'external-link-alt'} /> {cell.value}
        </a>
      )
    },
    {
      accessor: 'common_keywords',
      Header: 'Common Keyword',
      headerProps: { className: 'text-end' },
      cellProps: { className: 'text-end' }
    },
    {
      accessor: 'total_keywords',
      Header: 'Total Keyword',
      headerProps: { className: 'text-end' },
      cellProps: { className: 'text-end' }
    },
    {
      accessor: 'relevance',
      Header: 'Relevance',
      headerProps: { className: 'text-end' },
      cellProps: { className: 'text-end' }
    },
    {
      accessor: 'points',
      Header: 'Points',
      headerProps: { className: 'text-end' },
      cellProps: { className: 'text-end' }
    }
  ];
  const [initiallySelectedRowIds, setInitiallySelectedRowIds] = useState({});
  const [competitors, setCompetitors] = useState([]);
  const [source, setSource] = useState('');
  const [domain, setDomain] = useState('');

  const AccountDraftQuery = useQuery(
    ['account-draft-query', accountDraftId],
    () =>
      CallApi.get(
        `/account-details-draft/get-details-by-draft-id/` + accountDraftId
      ),
    {
      onSuccess: json => {
        const basicInfo = JSON.parse(json?.data?.basic_information);

        setDomain(basicInfo?.website);
        setSource(basicInfo?.country?.toLowerCase());
      }
    }
  );

  const CompetitorsQuery = useQuery(
    ['competitors', source, domain],
    () =>
      CallApi.get(
        `/competitors/se-ranking-reports/competitors?source=${source}&domain=${domain}`,
        true
      ),
    {
      onSuccess: json => {
        if (json?.status == 200) {
          const draftedCompetitors =
            AccountDraftQuery.data.data.competitor || '';

          const savedCompetitors =
            draftedCompetitors
              .replaceAll('"', '')
              .replaceAll('\\/', '/')
              .split(',')
              .filter(item => item != '') || [];

          const savedJsonCompetitors =
            savedCompetitors.map(
              competitorDomain =>
                json.data.find(
                  competitor => competitor.domain == competitorDomain
                ) || {
                  domain: competitorDomain,
                  common_keywords: '-',
                  total_keywords: '-',
                  relevance: '-',
                  points: '-'
                }
            ) || [];

          const allCompetitors = [
            ...savedJsonCompetitors,
            ...json.data.filter(
              item => !savedCompetitors.includes(item?.domain)
            )
          ];

          let initialSelectedRowIndexes = {};

          const indexesOfPreviouslySelectedRows = allCompetitors
            ?.map((competitor, i) =>
              savedCompetitors.includes(competitor?.domain) ? i : null
            )
            ?.filter(index => index != null);

          indexesOfPreviouslySelectedRows.forEach(index => {
            initialSelectedRowIndexes[index] = true;
          });

          setInitiallySelectedRowIds(initialSelectedRowIndexes);

          setCompetitors(allCompetitors);
        }
      },
      enabled: !!(source && domain && AccountDraftQuery.isSuccess)
    }
  );

  const saveCompetitor = selectedRows => {
    const competitorDomains = selectedRows
      .map(row => row.original?.domain)
      .join(',');

    let data = new FormData();

    data.append('competitor', competitorDomains);

    CallApi.put(
      `/account-drafts/${AccountDraftQuery.data?.data?.id}/update-competitors`,
      data
    ).then(json => {
      if (json.status == 200) {
        onNext();
      }
    });
  };

  const apiCallInProcess =
    AccountDraftQuery.isLoading ||
    AccountDraftQuery.isFetching ||
    CompetitorsQuery.isLoading ||
    CompetitorsQuery.isFetching;

  const NextPrevButtons = ({ selectedFlatRows }) => {
    const isNextStepButtonDisabled = selectedFlatRows?.length < 3;
    return (
      <ButtonGroup className="mt-4">
        <Button
          variant="outline-primary"
          className="rounded-pill me-3"
          onClick={onPrevious}
        >
          <FontAwesomeIcon icon={'arrow-left'} /> Previous
        </Button>
        <OverlayTrigger
          placement={'top'}
          overlay={
            isNextStepButtonDisabled ? (
              <Tooltip>at least 3 competitors must be selected</Tooltip>
            ) : (
              <></>
            )
          }
        >
          <span className="d-inline-block">
            <Button
              variant="outline-primary"
              className="rounded-pill"
              disabled={isNextStepButtonDisabled}
              onClick={() => saveCompetitor(selectedFlatRows)}
            >
              Next Step: Keywords <FontAwesomeIcon icon={'arrow-right'} />
            </Button>
          </span>
        </OverlayTrigger>
      </ButtonGroup>
    );
  };

  return (
    <Col>
      <LocalStoragePageInfo slug="account_form_competitors">
        <Row>
          {/*{progressBar && <ProgressBar now={25} style={{ height: 2 }} />}*/}
          <StepDetailsCard
            step="3"
            text="Choose 3-8 competitors who are odding the same things as you."
            title="Competitors"
          />
        </Row>
      </LocalStoragePageInfo>
      <Card>
        <Card.Body className="m-3">
          <AdvanceTableWrapper
            columns={columns}
            data={competitors || []}
            sortable
            pagination
            perPage={25}
            selection
            initialSelectedRowIds={initiallySelectedRowIds}
          >
            <Row className="align-items-center mb-3">
              <Col xs="auto">
                <AdvanceTableSearchBox table />
              </Col>
              <Col />
              <Col xs="auto">
                <ManualAddCompetitorModal
                  disabled={apiCallInProcess}
                  setCompetitors={setCompetitors}
                  competitors={competitors}
                  setInitiallySelectedRowIds={setInitiallySelectedRowIds}
                  variant="outline-primary"
                  className="rounded-pill"
                  table
                />
              </Col>
            </Row>
            <AdvanceTable
              table
              headerClassName="bg-aida-table-header text-900 text-nowrap align-middle"
              rowClassName="align-middle white-space-nowrap"
              tableProps={{
                className: 'fs--1 mb-0 overflow-hidden'
              }}
              loadingText={'Please wait, we are fetching your competitors...'}
              apiCallInProcess={apiCallInProcess}
            />
            <AdvanceTableFooter
              rowsPerPageOptions={[25, 50, 75, 100]}
              table
              className="mt-3 mx-3"
              rowCount={competitors?.length}
              rowInfo
              navButtons
              rowsPerPageSelection
            />
            <div className="text-center">
              <NextPrevButtons table />
            </div>
          </AdvanceTableWrapper>
        </Card.Body>
      </Card>
    </Col>
  );
};

export default CompetitorsForm;
