import Flex from 'components/common/Flex';
import Section from 'components/common/Section';
import PropTypes from 'prop-types';
import logo from 'assets/img/trakk_logo_w300px_ny_03.png';
import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { SandBackground } from 'assets/img/aida-auth/images';

const AuthAidaLayout = ({
  leftSideContent,
  backgroundImage = SandBackground,
  children,
  imgStyle = {},
  footer = true
}) => {
  return (
    <Section className="py-0">
      <Row className="g-0 min-vh-100 flex-center">
        <Col lg={8} xl={7} className="py-3 position-relative">
          <Card className="overflow-hidden z-index-1">
            <Card.Body className="p-0">
              <Row className="h-100 g-0">
                <Col
                  md={5}
                  style={{
                    ...{
                      backgroundImage: `url(${backgroundImage})`,
                      backgroundSize: 'cover',
                      backgroundRepeat: 'no-repeat',
                      backgroundPositionY: '100%',
                      minHeight: '475px'
                    },
                    ...imgStyle
                  }}
                  className="text-dark py-6 text-center"
                >
                  <Link
                    className="link-dark mb-3 font-ibm-plex-sans-serif fw-bolder fs-4 d-inline-block"
                    to="/"
                  >
                    <img width={'150px'} src={logo} />
                  </Link>
                  <p className="fs-2 font-ibm-plex-serif text-dark">
                    Improve your SEO
                    <br /> with the power of
                    <br /> AI.
                  </p>
                </Col>
                <Col
                  md={7}
                  as={Flex}
                  alignItems="center"
                  justifyContent="center"
                >
                  <div className="p-5 flex-grow-1">{children}</div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Section>
  );
};
AuthAidaLayout.propTypes = {
  leftSideContent: PropTypes.node,
  children: PropTypes.node.isRequired,
  footer: PropTypes.bool
};

export default AuthAidaLayout;
