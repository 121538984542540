import React, { useEffect } from 'react';
import IconButton from 'components/common/IconButton';
import { Modal } from 'react-bootstrap';
import { useState } from 'react';
import useProfileForm from './form-hooks/useProfileForm';
import { useMutation } from 'react-query';
import CallApi from 'components/common/custom-components/CallApi';
import SweetAlert from 'react-bootstrap-sweetalert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';

const EditAccountModal = ({ account = {}, refetch = () => {}, ...rest }) => {
  const [show, setShow] = useState(false);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);

  const handleClose = () => {
    setShow(false);
  };

  const UpdateAccountMutation = useMutation(
    ['update-mutation', account?.id],
    formData => CallApi.put(`/accounts/${account?.id}`, formData)
  );

  const DeleteAccountMutation = useMutation(
    ['delete-account', account?.id],
    () => CallApi.delete(`/accounts/${account?.id}`)
  );

  const { ProfileForm, setErrors, reset } = useProfileForm(
    (data, form) => {
      UpdateAccountMutation.mutate(new FormData(form.target), {
        onSuccess: json => {
          if (json?.status == 200) refetch();
          if (json?.status == 422) setErrors(json?.errors);
        }
      });
    },
    'edit-account-modal-form',
    UpdateAccountMutation.isLoading,
    ['country_id', 'language_id', 'website']
  );

  useEffect(() => {
    reset(account);
    return () => {
      reset({});
    };
  }, []);

  return (
    <>
      <SweetAlert
        input
        btnSize="md"
        showCancel
        show={showDeleteAlert}
        confirmBtnText="Delete"
        title={
          <h3 className="fw-bold">
            <FontAwesomeIcon icon="trash-alt" /> Delete Account ?
          </h3>
        }
        placeholder="type delete here..."
        onConfirm={response => {
          if (response == 'delete') {
            DeleteAccountMutation.mutate(null, {
              onSuccess: json => {
                refetch();
                setShowDeleteAlert(false);
              }
            });
          } else {
            toast.error('Account deletion not accepted!', {
              position: 'top-center'
            });
            setShowDeleteAlert(false);
          }
        }}
        disabled={DeleteAccountMutation.isLoading}
        openAnim={{ name: 'fade in' }}
        closeAnim={{ name: 'fade out' }}
        onCancel={() => setShowDeleteAlert(false)}
      >
        <p className="mb-3">
          You are deleting <b>{account?.account_name}</b> account.
          <br />
          To confirm deletion, please type 'delete' below:
        </p>
      </SweetAlert>
      <FontAwesomeIcon
        icon={'edit'}
        className="cursor-pointer text-primary"
        {...rest}
        onClick={() => setShow(true)}
      />
      <Modal show={show} centered size="lg" onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Editing account for {account.account_name}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-4">{ProfileForm}</Modal.Body>
        <Modal.Footer>
          <IconButton
            className="me-auto rounded-pill"
            icon="trash-alt"
            size="sm"
            onClick={() => {
              setShow(false);
              setShowDeleteAlert(true);
            }}
            variant="outline-danger"
          >
            Remove
          </IconButton>
          <IconButton
            icon="times-circle"
            size="sm"
            onClick={handleClose}
            variant="falcon-light"
          >
            Cancel
          </IconButton>
          <IconButton
            form="edit-account-modal-form"
            variant="outline-primary"
            className="rounded-pill"
            type="submit"
            icon={UpdateAccountMutation.isLoading ? 'spinner' : 'save'}
            spin={UpdateAccountMutation.isLoading}
            size="sm"
          >
            {UpdateAccountMutation.isLoading ? 'Updating...' : 'Update'}
          </IconButton>
        </Modal.Footer>
      </Modal>
    </>
  );
};

EditAccountModal.propTypes = {};

export default EditAccountModal;
