import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import UpsertModal from 'components/aida/users/components/UpsertModal';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import Avatar, { AvatarGroup } from 'components/common/Avatar';
import CallApi from 'components/common/custom-components/CallApi';
import NameWithIcon from 'components/common/custom-components/NameWithIcon';
import IconButton from 'components/common/IconButton';
import { AidaAppContext } from 'context/AidaAppContext';
import { inArray } from 'is_js';
import React, { useContext } from 'react';
import { Card, Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useQuery } from 'react-query';
import { AdsAccountContext } from '../AdsAccountContext';

const AccountUsers = () => {
  const adsAccountContext = useContext(AdsAccountContext);
  const aidaAppContext = useContext(AidaAppContext);
  const { user_role } = aidaAppContext;
  const { currentAccount } = adsAccountContext;

  const { data, refetch, isLoading } = useQuery(
    ['users', currentAccount?.id],
    ({ signal }) => CallApi.get(`/account/${currentAccount?.id}/users`),
    {
      placeholderData: { data: [] },
      enabled: !!currentAccount?.id
    }
  );

  const columns = [
    {
      accessor: 'id',
      Header: 'Actions',
      headerProps: { className: 'text-center' },
      cellProps: { className: 'text-center' },
      Cell: ({ cell }) => (
        <>
          <IconButton
            icon="trash-alt"
            className="ms-1"
            variant="danger"
            size="sm"
            onClick={() =>
              confirm('Do you really want to delete this user?') &&
              CallApi.delete('/users/' + cell.value).then(json => {
                if (json.status == 200) refetch();
              })
            }
          />
        </>
      )
    },
    {
      accessor: 'name',
      Header: 'Name',
      Cell: ({ cell }) => (
        <NameWithIcon name={cell.value} icon={cell.row.original.avatar_url} />
      )
    },
    { accessor: 'email', Header: 'E-mail' },
    {
      accessor: 'role.name',
      Header: 'Role'
    }
  ];

  return (
    <Card>
      <Card.Body className="m-3">
        <AdvanceTableWrapper
          columns={columns}
          data={data?.data || []}
          sortable
          pagination
        >
          <Row className="align-items-end g-2 row mb-3">
            <Col xs="auto">
              <AdvanceTableSearchBox table />
            </Col>
            <Col />
            {user_role == 'Client' ? null : (
              <Col xs="auto">
                <UpsertModal userFor={'accounts'} refreshListing={refetch} />
              </Col>
            )}
          </Row>
          <AdvanceTable
            table
            headerClassName="bg-aida-table-header text-900 text-nowrap align-middle"
            rowClassName="align-middle white-space-nowrap"
            tableProps={{
              bordered: true,
              striped: true,
              className: 'fs--1 mb-0 overflow-hidden'
            }}
            apiCallInProcess={isLoading}
          />
          <div className="mt-3">
            <AdvanceTableFooter
              rowCount={data?.data?.length}
              table
              rowInfo
              navButtons
              rowsPerPageSelection
            />
          </div>
        </AdvanceTableWrapper>
      </Card.Body>
    </Card>
  );
};

export default AccountUsers;
