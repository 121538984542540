import useAccountCategorySelectBox from 'components/aida/custom-form-hooks/useAccountCategorySelectBox';
import useAccountTypeSelectBox from 'components/aida/custom-form-hooks/useAccountTypeSelectBox';
import useClientSelectBox from 'components/aida/custom-form-hooks/useClientSelectBox';
import useCountrySelectBox from 'components/aida/custom-form-hooks/useCountrySelectBox';
import useLanguageSelectBox from 'components/aida/custom-form-hooks/useLanguageSelectBox';
import CallApi from 'components/common/custom-components/CallApi';
import LocalStorageTooltip from 'components/common/custom-components/LocalStorageTooltip';
import { inArray } from 'is_js';
import React, { useState } from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';

const useProfileForm = (
  onSubmit = (data, form) => {},
  formId = 'account-form',
  disabled = false,
  excludedFields = ['status']
) => {
  const [errors, setErrors] = useState({});
  const { register, control, reset, handleSubmit, watch } = useForm();
  const { ClientSelectBox } = useClientSelectBox();
  const { CountrySelectbox } = useCountrySelectBox();
  const { LanguageSelectBox } = useLanguageSelectBox();
  const { AccountTypeSelectBox } = useAccountTypeSelectBox();
  const { AccountCategorySelectBox } = useAccountCategorySelectBox();

  const ProfileForm = (
    <Form onSubmit={handleSubmit(onSubmit)} id={formId}>
      {inArray('status', excludedFields) ? null : (
        <Form.Group className="mb-3" controlId="formBasicName">
          <Form.Label>
            Status
            <LocalStorageTooltip identifier="form_account_field_status" />
          </Form.Label>
          <Form.Select
            {...register('status', { required: true, disabled: disabled })}
            isInvalid={errors?.status}
          >
            <option value="">Please select a status</option>
            <option value="active">Active</option>
            <option value="paused">Paused</option>
            {/* <option value="ended">Ended</option> */}
          </Form.Select>
          <Form.Control.Feedback type="invalid">
            {errors.status && errors.status.map(error => error)}
          </Form.Control.Feedback>
        </Form.Group>
      )}
      <Form.Group className="mb-3">
        <Form.Label htmlFor="account_name">
          Account name
          <LocalStorageTooltip
            className="ms-1"
            identifier={'form_account_wizard_form_field_account_name'}
          />
        </Form.Label>
        <Form.Control
          id="account_name"
          {...register('account_name', { disabled: disabled })}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label htmlFor="account_name">
          Client
          <LocalStorageTooltip
            className="ms-1"
            identifier={'form_account_wizard_form_field_account_client'}
          />
        </Form.Label>
        <Controller
          control={control}
          name="client_id"
          render={({ field }) => (
            <ClientSelectBox
              isDisabled={disabled}
              isSearchable
              value={field.value}
              onChange={client => field.onChange(client.id)}
            />
          )}
        />
      </Form.Group>
      <Row>
        {inArray('country_id', excludedFields) ? null : (
          <Col lg={6}>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="account_name">Country</Form.Label>
              <LocalStorageTooltip
                className="ms-1"
                identifier={'form_account_wizard_form_field_account_country'}
              />
              <Controller
                control={control}
                name="country_id"
                render={({ field }) => (
                  <CountrySelectbox
                    isDisabled={disabled}
                    isSearchable
                    value={field.value}
                    onChange={country => field.onChange(country.id)}
                  />
                )}
              />
            </Form.Group>
          </Col>
        )}
        {inArray('language_id', excludedFields) ? null : (
          <Col lg={6}>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="language">
                Language
                <LocalStorageTooltip
                  className="ms-1"
                  identifier={'form_account_wizard_form_field_language'}
                />
              </Form.Label>
              <Controller
                control={control}
                name="language_id"
                render={({ field }) => (
                  <LanguageSelectBox
                    isDisabled={disabled}
                    isSearchable
                    value={field.value}
                    onChange={language => field.onChange(language.id)}
                  />
                )}
              />
            </Form.Group>
          </Col>
        )}
      </Row>
      {inArray('website', excludedFields) ? null : (
        <Form.Group className="mb-3">
          <Form.Label htmlFor="website">
            Website
            <LocalStorageTooltip
              className="ms-1"
              identifier={'form_account_wizard_form_field_website'}
            />
          </Form.Label>
          <Form.Control
            disabled={disabled}
            id="website"
            {...register('website')}
          />
        </Form.Group>
      )}
      <Row>
        <Col lg={6}>
          <Form.Group className="mb-3">
            <Form.Label htmlFor="language">
              Type
              <LocalStorageTooltip
                className="ms-1"
                identifier={'form_account_wizard_form_field_account_type'}
              />
            </Form.Label>
            <Controller
              control={control}
              name="account_type_id"
              render={({ field }) => (
                <AccountTypeSelectBox
                  isDisabled={disabled}
                  value={field.value}
                  onChange={accountType => field.onChange(accountType.id)}
                  id="website"
                />
              )}
            />
          </Form.Group>
        </Col>
        <Col lg={6}>
          <Form.Group className="mb-3">
            <Form.Label htmlFor="language">
              Category
              <LocalStorageTooltip
                className="ms-1"
                identifier={'form_account_wizard_form_field_account_category'}
              />
            </Form.Label>
            <Controller
              control={control}
              name="account_category_id"
              render={({ field }) => (
                <AccountCategorySelectBox
                  isDisabled={disabled}
                  id="website"
                  value={field.value}
                  onChange={accountCategory =>
                    field.onChange(accountCategory.id)
                  }
                />
              )}
            />
          </Form.Group>
        </Col>
      </Row>
      <Form.Group className="mb-3" controlId="goal_with_ads">
        <Form.Label>
          Goal with ads:
          <LocalStorageTooltip
            className="ms-1"
            identifier={'form_account_wizard_form_field_goal_with_ads'}
          />
        </Form.Label>
        <Row>
          <Col>
            <Form.Group controlId="sales">
              <Form.Check
                {...register('goal_with_ads', { disabled: disabled })}
                type="radio"
                value="sales"
                label="Sales"
                className="mb-n2"
              />
              <Form.Text>
                Generate revenue through immediate
                <br />
                purchases with ad campaigns.
              </Form.Text>
            </Form.Group>
            <Form.Group className="mt-3" controlId="brand-awareness">
              <Form.Check
                {...register('goal_with_ads', { disabled: disabled })}
                type="radio"
                value="brand_awareness"
                label="Brand awareness"
                className="mb-n2"
              />
              <Form.Text>
                Boost brand recognition with
                <br />
                targeted advertising efforts.
              </Form.Text>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="new-customers">
              <Form.Check
                {...register('goal_with_ads', { disabled: disabled })}
                type="radio"
                value="new_customers"
                label="New Customers"
                className="mb-n2"
              />
              <Form.Text>
                Attract and engage new buyers
                <br />
                via effective advertising strategies
              </Form.Text>
            </Form.Group>
            <Form.Group className="mt-3" controlId="traffic-to-website">
              <Form.Check
                {...register('goal_with_ads', { disabled: disabled })}
                type="radio"
                value="traffic_on_website"
                label="Traffic to the website"
                className="mb-n2"
              />
              <Form.Text>
                Increase website visits using
                <br />
                ad campaigns for online visibility.
              </Form.Text>
            </Form.Group>
          </Col>
        </Row>
      </Form.Group>
    </Form>
  );

  return { ProfileForm, reset, setErrors };
};

export default useProfileForm;
