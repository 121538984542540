import { Logtail } from '@logtail/browser';
import { AppSettings } from 'settings';

const LoggerObject = {
  info: (message, context) => {
    const styles = `background: #333;color: #0bf; padding: 4px 8px; border-radius: 4px;`;

    console.log(`%c ${message}`, styles);
    console.log(context);
  },
  warn: (message, context) => {
    const styles = `background: #333; color: #ff0; padding: 4px 8px; border-radius: 4px;`;

    console.log(`%c ${message}`, styles);
    console.log(context);
  },
  debug: (message, context) => {
    const styles = `background: #333; color: #fff; padding: 4px 8px; border-radius: 4px;`;

    console.log(`%c ${message}`, styles);
    console.log(context);
  },
  error: (message, context) => {
    const styles = `background: #f55; color: #fff; padding: 4px 8px; border-radius: 4px;`;

    console.log(`%c ${message}`, styles);
    console.log(context);
  },
  flush: (message, ...args) => {}
};

const getLoggingService = () => {
  if (AppSettings.log_dna_source_token) {
    return new Logtail(AppSettings.log_dna_source_token);
  } else {
    LoggerObject.debug('Logtail is not configured');

    return LoggerObject;
  }
};

const logtail =
  process.env?.NODE_ENV == 'production' ? getLoggingService() : LoggerObject;

export default logtail;
