import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import IconButton from 'components/common/IconButton';
import CallApi from 'components/common/custom-components/CallApi';
import { validateJson } from 'helpers/utils';
import React, { useState } from 'react';
import {
  Button,
  Col,
  Form,
  Modal,
  OverlayTrigger,
  Row,
  Spinner,
  Tooltip
} from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';

const TwoFactorAuth = ({ user, refetch }) => {
  const [show, setShow] = useState(false);

  const GetQrCodeQuery = useQuery(
    'enable-2fa',
    () => CallApi.get(user?.is_totp_2fa_enabled ? `/reset-2fa` : `/2fa`),
    {
      enabled: !!show
    }
  );

  const { handleSubmit, register, reset } = useForm({});

  const handleClose = () => {
    setShow(false);
  };

  const VerifyMutation = useMutation(
    'verify-2fa',
    formData => CallApi.post(`/2fa`, formData),
    {
      onSuccess: json => {
        if (json?.status == 200) {
          refetch();
          reset();
          setShow(false);
        }
      }
    }
  );

  const onSubmit = (data, form) => {
    const formData = new FormData(form.target);

    if (user?.is_totp_2fa_enabled) {
      formData.append('secret_key', GetQrCodeQuery?.data?.totp_code);
    }

    VerifyMutation.mutate(formData);
  };

  const EnableButton = () => (
    <IconButton
      onClick={() => setShow(!show)}
      variant={user?.is_totp_2fa_enabled ? 'success' : 'primary'}
      className="w-100"
      icon={user?.is_totp_2fa_enabled ? 'check' : ''}
      iconClassName="me-2"
      disabled={user?.is_totp_2fa_enabled}
    >
      {user?.is_totp_2fa_enabled
        ? '2FA Enabled'
        : 'Enable Two-Factor Authentication'}
    </IconButton>
  );

  const ResetButton = () => (
    <Button
      onClick={() => {
        setShow(true);
      }}
      variant="primary"
    >
      Reset 2FA
    </Button>
  );

  const Remove2FA = () => {
    const Remove2FAMutation = useMutation(
      'remove-2fa',
      () => CallApi.delete(`/2fa`),
      {
        onSuccess: json => {
          if (json?.status == 200) {
            refetch();
          }
        }
      }
    );

    return (
      <OverlayTrigger
        placement="bottom"
        overlay={
          <Tooltip>
            {Remove2FAMutation.isLoading ? 'Removing...' : 'Remove'} 2FA
          </Tooltip>
        }
      >
        <span>
          <IconButton
            spin={Remove2FAMutation.isLoading}
            onClick={() => {
              if (
                confirm(
                  'Do you really want to remove Two-Factor Authentication from your account?'
                )
              ) {
                Remove2FAMutation.mutate();
              }
            }}
            variant={'danger'}
            className="w-100"
            icon={Remove2FAMutation.isLoading ? 'spinner' : 'trash-alt'}
          />
        </span>
      </OverlayTrigger>
    );
  };

  return (
    <>
      {user?.is_totp_2fa_enabled ? (
        <Row>
          <Col>
            <EnableButton />
          </Col>
          <Col xs={'auto'} className="ps-0">
            <ResetButton />
          </Col>
          <Col xs={'auto'} className="ps-0">
            <Remove2FA />
          </Col>
        </Row>
      ) : (
        <EnableButton />
      )}

      <Modal show={show} centered onHide={() => handleClose()}>
        <Modal.Header closeButton>
          <Modal.Title>
            <FontAwesomeIcon icon={'key'} /> Enabling 2FA
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-3">
          Scan the code below in your authenticator app to register 2FA. e.g.
          Microsoft Authenticator/Google Authenticator/Authy
          <div className="text-center">
            {GetQrCodeQuery.isFetching ? (
              <Spinner className="my-5" />
            ) : (
              <div
                dangerouslySetInnerHTML={{
                  __html: GetQrCodeQuery?.data?.qr_code
                }}
              />
            )}
          </div>
          If you are not able to scan the code, open your authenticator app &
          manually enter{' '}
          <b>
            {GetQrCodeQuery.isFetching
              ? '...'
              : GetQrCodeQuery?.data?.totp_code}
          </b>
          <Form
            className="mt-3 mb-2"
            id="verify-2fa-form"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Form.Label>Authenticator Code:</Form.Label>
            <Form.Control
              type="text"
              {...register('totp_code', {
                required: true
              })}
              placeholder="Enter the code you get after scanning the QR code"
            />
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <IconButton
            icon={VerifyMutation.isLoading ? 'spinner' : ''}
            spin={VerifyMutation.isLoading}
            type="submit"
            form="verify-2fa-form"
          >
            Verify code
          </IconButton>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default TwoFactorAuth;
