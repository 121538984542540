import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import Flex from 'components/common/Flex';
import IconButton from 'components/common/IconButton';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import CallApi from 'components/common/custom-components/CallApi';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import React, { useContext, useEffect, useState } from 'react';
import { Card, Col, Form, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { useMutation, useQuery } from 'react-query';
import { AdsAccountContext } from '../AdsAccountContext';
import AdPreview from './AdPreview';
import AddTextAd from './AddTextAd';

const TextAds = () => {
  const [selectedAdgroup, setSelectedAdgroup] = useState(null);
  const adsAccountContext = useContext(AdsAccountContext);
  const { currentAccount } = adsAccountContext;

  const HeadlinesAdsQuery = useQuery(
    ['textads-headlines', selectedAdgroup],
    () =>
      CallApi.get(`/adgroups/${selectedAdgroup}/text-ads?type=headline`, false),
    {
      enabled: !!selectedAdgroup
    }
  );
  const DescriptionAdsQuery = useQuery(
    ['textads-descriptions', selectedAdgroup],
    () =>
      CallApi.get(
        `/adgroups/${selectedAdgroup}/text-ads?type=description`,
        false
      ),
    {
      enabled: !!selectedAdgroup
    }
  );

  const AdgroupsQuery = useQuery(
    ['account-adgroups', currentAccount?.id],
    () => CallApi.get(`/account/${currentAccount?.id}/adgroups`, false),
    {
      enabled: !!currentAccount?.id,
      onSuccess: json => {
        if (json.status == 200) {
          setSelectedAdgroup(null);
          DescriptionAdsQuery.remove();
          HeadlinesAdsQuery.remove();
        }
      }
    }
  );

  const DeleteTextAdMutation = useMutation('delete-textad', textAdId =>
    CallApi.delete(`/text-ad/${textAdId}`)
  );

  const columns = [
    {
      accessor: 'selected',
      Header: '',
      Cell: ({ cell }) => {
        const ToggleSelectionMutation = useMutation(
          ['select-sitelink'],
          () =>
            CallApi.put(`/text-ad/${cell.row.original?.id}/toggle-selected`),
          {
            onSuccess: json => {
              if (json?.status == 200) {
                HeadlinesAdsQuery.refetch();
              }
            }
          }
        );

        dayjs.extend(localizedFormat);

        const selectedAt = dayjs(cell.row.original?.selected_at);

        return ToggleSelectionMutation.isLoading ? (
          <FontAwesomeIcon icon="spinner" spin />
        ) : (
          <Form.Check
            type="checkbox"
            className={classNames(
              'form-check cursor-pointer fs-0 mb-0 d-flex align-items-center'
            )}
          >
            {cell.value ? (
              <OverlayTrigger
                key={cell.value}
                placement="top"
                overlay={
                  <Tooltip>Selected at: {selectedAt.format('llll')}</Tooltip>
                }
              >
                <Form.Check.Input
                  onChange={() => ToggleSelectionMutation.mutate()}
                  type="checkbox"
                  checked={cell.value}
                />
              </OverlayTrigger>
            ) : (
              <Form.Check.Input
                onChange={() => ToggleSelectionMutation.mutate()}
                type="checkbox"
                checked={cell.value}
              />
            )}
          </Form.Check>
        );
      }
    },
    {
      accessor: 'text',
      Header: 'Headline'
    },
    {
      accessor: 'id',
      Header: 'Actions',
      Cell: ({ cell }) => (
        <IconButton
          icon={'trash-alt'}
          onClick={() =>
            DeleteTextAdMutation.mutate(cell.value, {
              onSuccess: json => HeadlinesAdsQuery.refetch()
            })
          }
          variant="danger"
          size="sm"
        />
      )
    }
  ];
  const descriptionAdsColumns = [
    {
      accessor: 'selected',
      Header: '',
      Cell: ({ cell }) => {
        const ToggleSelectionMutation = useMutation(
          ['select-sitelink'],
          () =>
            CallApi.put(`/text-ad/${cell.row.original?.id}/toggle-selected`),
          {
            onSuccess: json => {
              if (json?.status == 200) {
                DescriptionAdsQuery.refetch();
              }
            }
          }
        );

        dayjs.extend(localizedFormat);

        const selectedAt = dayjs(cell.row.original?.selected_at);

        return ToggleSelectionMutation.isLoading ? (
          <FontAwesomeIcon icon="spinner" spin />
        ) : (
          <Form.Check
            type="checkbox"
            className={classNames(
              'form-check cursor-pointer fs-0 mb-0 d-flex align-items-center'
            )}
          >
            {cell.value ? (
              <OverlayTrigger
                key={cell.value}
                placement="top"
                overlay={
                  <Tooltip>Selected at: {selectedAt.format('llll')}</Tooltip>
                }
              >
                <Form.Check.Input
                  onChange={() => ToggleSelectionMutation.mutate()}
                  type="checkbox"
                  checked={cell.value}
                />
              </OverlayTrigger>
            ) : (
              <Form.Check.Input
                onChange={() => ToggleSelectionMutation.mutate()}
                type="checkbox"
                checked={cell.value}
              />
            )}
          </Form.Check>
        );
      }
    },
    {
      accessor: 'text',
      Header: 'Description'
    },
    {
      accessor: 'id',
      Header: 'Actions',
      Cell: ({ cell }) => (
        <IconButton
          onClick={() =>
            DeleteTextAdMutation.mutate(cell.value, {
              onSuccess: json => DescriptionAdsQuery.refetch()
            })
          }
          icon={'trash-alt'}
          variant="danger"
          size="sm"
        />
      )
    }
  ];
  return (
    <Row className="gap-3">
      <Col>
        <Card>
          <Card.Body className="m-3">
            <div className="border-bottom border-2 border-dark pb-3 mb-4">
              <Row>
                <Col>
                  <h5>Text Ads</h5>
                </Col>
                <Col xs="auto">
                  <Form.Select
                    size="sm"
                    value={selectedAdgroup}
                    onChange={e => setSelectedAdgroup(e.target.value)}
                  >
                    <option value="">Select a category</option>
                    {AdgroupsQuery.data?.data?.map(adgroup => (
                      <option value={adgroup.id}>{adgroup?.name}</option>
                    ))}
                  </Form.Select>
                </Col>
              </Row>
            </div>
            <Flex
              className="mb-3"
              justifyContent={'between'}
              alignItems={'center'}
            >
              <h5>Headlines</h5>
              <AddTextAd
                fieldname="headline"
                adgroupId={selectedAdgroup}
                refetchAds={HeadlinesAdsQuery.refetch}
                table
              />
            </Flex>
            <AdvanceTableWrapper
              columns={columns}
              data={HeadlinesAdsQuery.data?.data || []}
              sortable
              pagination
            >
              <AdvanceTable
                table
                headerClassName="bg-aida-table-header text-900 text-nowrap align-middle"
                rowClassName="align-middle white-space-nowrap"
                tableProps={{
                  bordered: true,
                  striped: true,
                  className: 'fs--1 mb-0 overflow-hidden'
                }}
                apiCallInProcess={HeadlinesAdsQuery.isLoading}
              />
            </AdvanceTableWrapper>
            <Flex
              className="mb-3 mt-4"
              justifyContent={'between'}
              alignItems={'center'}
            >
              <h5>Descriptions</h5>
              <AddTextAd
                table
                adgroupId={selectedAdgroup}
                fieldname={'description'}
                refetchAds={DescriptionAdsQuery.refetch}
              />
            </Flex>
            <AdvanceTableWrapper
              columns={descriptionAdsColumns}
              data={DescriptionAdsQuery.data?.data || []}
              sortable
              pagination
              apiCallInProcess={DescriptionAdsQuery.isLoading}
            >
              <AdvanceTable
                table
                headerClassName="bg-aida-table-header text-900 text-nowrap align-middle"
                rowClassName="align-middle white-space-nowrap"
                tableProps={{
                  bordered: true,
                  striped: true,
                  className: 'fs--1 mb-0 overflow-hidden'
                }}
              />
            </AdvanceTableWrapper>
          </Card.Body>
        </Card>
      </Col>
      <Col md={5}>
        <AdPreview />
      </Col>
    </Row>
  );
};

export default TextAds;
