import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import IconButton from 'components/common/IconButton';
import CallApi from 'components/common/custom-components/CallApi';
import React, { useState } from 'react';
import { Form, Modal } from 'react-bootstrap';
import SweetAlert from 'react-bootstrap-sweetalert';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';

const EditKeywordUrl = ({ keyword, refetch }) => {
  const [show, setShow] = useState(false);
  const [showUpdateAlert, setShowUpdateAlert] = useState(false);

  const handleClose = () => {
    setShow(false);
  };

  const [url, setUrl] = useState('');
  const UpdateKeywordUrlMutation = useMutation(
    ['update-keyword-url', keyword?.id],
    formData => CallApi.put(`/seo-project-keyword/${keyword?.id}`, formData)
  );

  return (
    <>
      <SweetAlert
        btnSize="md"
        showCancel
        show={showUpdateAlert}
        confirmBtnText={
          UpdateKeywordUrlMutation.isLoading ? 'Updating...' : 'Change'
        }
        title={<h4>Do you really want to change url?</h4>}
        onConfirm={() => {
          let formData = new FormData();
          formData.append('url', url);

          UpdateKeywordUrlMutation.mutate(formData, {
            onSuccess: json => {
              refetch();
              setShowUpdateAlert(false);
            }
          });
        }}
        disabled={UpdateKeywordUrlMutation.isLoading}
        openAnim={{ name: 'fade in' }}
        closeAnim={{ name: 'fade out' }}
      >
        <div className="py-3">
          <p className="text-wrap mb-2 text-start">
            <b>keyword: </b>
            {keyword.keyword} <br />
          </p>
          <p className="text-wrap text-start">
            <b>New URL: </b>
            {url}
          </p>
        </div>
      </SweetAlert>
      <FontAwesomeIcon
        icon={'pencil-alt'}
        className="cursor-pointer text-primary"
        onClick={() => setShow(true)}
      />
      <Modal show={show} centered size="md" onHide={handleClose}>
        <Modal.Header className="bg-300 border-bottom border-light" closeButton>
          <Modal.Title>
            <small>Edit url for keyword:</small>
            <br />
            <b>{keyword?.keyword}</b>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="bg-300 px-3 pt-4 pb-5">
          <Form.Label>New URL </Form.Label>
          <Form.Control
            onChange={e => setUrl(e.target.value)}
            type="text"
            name="url"
          />
        </Modal.Body>
        <Modal.Footer className="bg-300  border-top border-light">
          <IconButton
            icon="times-circle"
            size="sm"
            onClick={handleClose}
            variant="falcon-light"
          >
            Cancel
          </IconButton>
          <IconButton
            onClick={() => {
              setShow(false);
              setShowUpdateAlert(true);
            }}
            variant="outline-primary"
            className="rounded-pill"
            disabled={!url}
            icon="save"
            size="sm"
          >
            Update
          </IconButton>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EditKeywordUrl;
