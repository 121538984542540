import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CallApi from 'components/common/custom-components/CallApi';
import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Form, Row, Table } from 'react-bootstrap';
import Select from 'react-select';
import { toast } from 'react-toastify';
import StepDetailsCard from './StepDetailsCard';
import TargetGroups from '../View/account-settings/components/TargetGroups';
import FalconCardHeader from 'components/common/FalconCardHeader';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import { isArray } from 'lodash';
import LocalStoragePageInfo from 'components/common/custom-components/LocalStoragePageInfo';

const RegionAndBudgetForm = ({ onNext, onPrevious, accountDraftId }) => {
  const [regions, setRegions] = useState([]);
  const [selectedRegions, setSelectedRegions] = useState([]);
  const [budget, setBudget] = useState('');
  const levels = [
    { title: 'Priority', color: 'success' },
    { title: 'Neutral', color: 'secondary' },
    { title: 'Neglect', color: 'magenta' }
  ];

  const defaultData = [
    { age_group: '18-24', male: 'Neutral', female: 'Neutral' },
    { age_group: '25-34', male: 'Neutral', female: 'Neutral' },
    { age_group: '35-44', male: 'Neutral', female: 'Neutral' },
    { age_group: '45-54', male: 'Neutral', female: 'Neutral' },
    { age_group: '55-64', male: 'Neutral', female: 'Neutral' },
    { age_group: '65+', male: 'Neutral', female: 'Neutral' }
  ];

  const [targetData, setTargetData] = useState(defaultData);

  const selectLevel = (tableRow, gender, level) => {
    const jsonData = targetData?.map(row =>
      row.age_group == tableRow.age_group ? { ...row, [gender]: level } : row
    );
    setTargetData(jsonData);
  };

  const columns = [
    {
      accessor: 'age_group',
      Header: 'Age',
      cellProps: { className: 'fw-medium' }
    },
    {
      accessor: 'male',
      Header: 'Male',
      cellProps: { className: 'border-end-2 border-start-2 border-dark' },
      Cell: ({ cell }) => {
        const male = cell.row.original.male;
        return (
          <>
            {levels.map((level, i) => (
              <Button
                key={i}
                className={`rounded-pill ${i == 0 ? '' : 'ms-2'}`}
                variant={
                  male == level.title ? level.color : `outline-${level.color}`
                }
                size="sm"
                onClick={() =>
                  selectLevel(cell.row.original, 'male', level.title)
                }
              >
                {level.title}
              </Button>
            ))}
          </>
        );
      }
    },
    {
      accessor: 'female',
      Header: 'Female',
      Cell: ({ cell }) => {
        const female = cell.row.original.female;
        return (
          <>
            {levels.map(level => (
              <Button
                className="rounded-pill me-2"
                variant={
                  female == level.title ? level.color : `outline-${level.color}`
                }
                size="sm"
                onClick={() =>
                  selectLevel(cell.row.original, 'female', level.title)
                }
              >
                {level.title}
              </Button>
            ))}
          </>
        );
      }
    }
  ];

  useEffect(() => {
    CallApi.get(
      `/account-details-draft/get-details-by-draft-id/` + accountDraftId,
      false
    ).then(json => {
      if (json?.status == 200 && json.data) {
        if (json.data && json.data.region) {
          const jsonObject = JSON.parse(json.data.region);

          if (jsonObject.target) {
            const parsedTarget = JSON.parse(jsonObject.target);

            setTargetData(isArray(parsedTarget) ? parsedTarget : defaultData);
          }

          if (jsonObject.regions) {
            const regionObject = JSON.parse(jsonObject.regions);
            setSelectedRegions(regionObject);
          }

          if (jsonObject.budget) {
            setBudget(jsonObject.budget);
          }
        }
      }
    });

    CallApi.get(`/regions`, false).then(json => {
      if (json?.status == 200 && json.data) {
        const formattedData = json.data.map(item => ({
          value: item.id,
          label: item.location_name
        }));

        setRegions(formattedData);
      }
    });
  }, []);

  const handleSelectChange = e => {
    const isRegionAlreadyAdded = selectedRegions.some(
      region => region.value === e.value
    );

    if (!isRegionAlreadyAdded) {
      setSelectedRegions(prevSelectedRegions => [...prevSelectedRegions, e]);
    }
  };

  const removeRegion = item => {
    const updatedItems = selectedRegions.filter(
      selectedRegion => selectedRegion.value !== item.value
    );
    setSelectedRegions(updatedItems);
  };

  const handleKeyPress = event => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    const regex = /^[0-9]+$/; // Regular expression to allow only integers

    if (!regex.test(keyValue)) {
      event.preventDefault();
    }
  };

  const handleRadioChange = value => {
    setBudget(value);
  };

  const handleInputChange = event => {
    const { value } = event.target;
    // Use a regular expression to remove any non-numeric characters from the input
    const cleanedValue = value.replace(/\D/g, '');
    setBudget(cleanedValue);
  };

  const saveData = () => {
    if (selectedRegions.length > 0 && budget !== '') {
      saveRegion();
    } else {
      if (selectedRegions.length < 1) {
        toast.error('Select atleast one region.');
      }
      if (budget == '') {
        toast.error('Select monthly budget.');
      }
    }
  };

  const saveRegion = () => {
    // remove the header object from target
    // const targetWithoutHeader = Object.assign({}, target);
    // delete targetWithoutHeader.header;

    // const regionValues = selectedRegions.map(item => item.value);

    var data = new FormData();
    data.append('target', JSON.stringify(targetData));
    data.append('regions', JSON.stringify(selectedRegions));
    data.append('budget', budget);
    data.append('draft_id', accountDraftId);
    data.append('form_type', 'region');

    CallApi.post(`/account-details-draft`, data).then(json => {
      if (json.status == 200) {
        onNext();
      }
    });
  };

  return (
    <Col>
      <>
        <LocalStoragePageInfo slug="account_form_region_and_budget">
          <Row>
            {/*{progressBar && <ProgressBar now={25} style={{ height: 2 }} />}*/}
            <StepDetailsCard
              step="5"
              text="Now you are almost ready! Enter in which geographical locations you want the ads to appear. Let AIDA know what monthly budget you wish to start with, and then you're good to go!."
              title="Region And Budget"
            />
          </Row>
        </LocalStoragePageInfo>
        <Row className="gap-3">
          <Col lg={5}>
            <Card className="mb-3">
              <Card.Body className="m-3">
                <h5 className="border-bottom border-2 border-dark pb-3 mb-4">
                  Your region
                </h5>
                <Form.Group className="mb-3">
                  <Form.Label htmlFor="account_name">Add new region</Form.Label>
                  <Select
                    name="regions"
                    onChange={handleSelectChange}
                    options={regions}
                    isSearchable={true}
                  />
                </Form.Group>
                <div className="mt-4">
                  <AdvanceTableWrapper
                    pagination
                    columns={[
                      { accessor: 'label', Header: 'Selected regions' },
                      {
                        accessor: 'id',
                        Header: 'Remove',
                        headerProps: { className: 'text-end' },
                        cellProps: { className: 'text-end' },
                        Cell: ({ cell }) => (
                          <FontAwesomeIcon
                            onClick={() => removeRegion(cell.row.original)}
                            icon="trash-alt"
                            className="text-dark cursor-pointer"
                          />
                        )
                      }
                    ]}
                    data={selectedRegions || []}
                  >
                    <AdvanceTable
                      table
                      headerClassName="bg-aida-table-header exclude-last-row text-900 text-nowrap align-middle"
                      rowClassName="align-middle white-space-nowrap"
                      tableProps={{
                        className: 'fs--1 mb-0 text-left overflow-hidden'
                      }}
                    />
                  </AdvanceTableWrapper>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card className="mb-5">
              <Card.Body className="m-3">
                <h5 className="mb-3">Target group</h5>
                <AdvanceTableWrapper columns={columns} data={targetData || []}>
                  <AdvanceTable
                    table
                    headerClassName="bg-aida-table-header exclude-last-row text-900 text-nowrap align-middle"
                    rowClassName="align-middle white-space-nowrap"
                    tableProps={{
                      bordered: true,
                      striped: true,
                      className: 'fs--1 mb-0 text-center overflow-hidden'
                    }}
                  />
                </AdvanceTableWrapper>
              </Card.Body>
            </Card>
            <Card>
              <Card.Body className="m-3">
                <h5 className="border-bottom border-2 border-dark pb-3 mb-4">
                  Monthly budget
                </h5>
                <Row>
                  <Col>
                    <Form.Check
                      type="radio"
                      name="goals"
                      label="Use recommended monthly budget of 4000 sek/month"
                      onChange={e => handleRadioChange('4000')}
                      checked={budget == 4000}
                    />
                    <Row>
                      <Col xs={5}>
                        <Form.Check
                          type="radio"
                          name="goals"
                          label="Custom Budget"
                          checked={budget != 4000}
                          onChange={() => handleRadioChange('')} // Pass the budget value here
                        />
                      </Col>
                      <Col xs={7}>
                        <Form.Control
                          type="text"
                          id="account_name"
                          name="account_name"
                          placeholder="Enter your budget here"
                          value={budget && budget != 4000 ? budget : ''}
                          onChange={handleInputChange} // Handle input changes to update budget state
                          onKeyPress={handleKeyPress}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <div className="button-group mt-4 text-center">
          <Button
            className="rounded-pill"
            variant="outline-primary"
            onClick={onPrevious}
          >
            <FontAwesomeIcon icon={'arrow-left'} /> Previous
          </Button>
          <>&nbsp;&nbsp;&nbsp;</>
          <Button
            className="rounded-pill"
            variant="outline-primary"
            onClick={saveData}
          >
            Next Step: Finish <FontAwesomeIcon icon={'arrow-right'} />
          </Button>
        </div>
      </>
    </Col>
  );
};

export default RegionAndBudgetForm;
