import CallApi from 'components/common/custom-components/CallApi';
import ReactSelectBoxImageLabel from 'components/common/custom-components/ReactSelectBoxImageLabel';
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import ReactSelect from 'react-select';

const useRoleSelectBox = () => {
  const getRoles = () => CallApi.get('/roles?paginate=0', false);
  const { data } = useQuery('roles', getRoles, {
    placeholderData: { data: [] }
  });

  const RoleSelectBox = ({
    name = 'role_id',
    className = '',
    isClearable = false,
    isDisabled = false,
    isLoading = false,
    isMulti = false,
    isSearchable = false,
    placeholder = 'Please select a role',
    onChange = role => {},
    value,
    size = 'md',
    ...rest
  }) => {
    const [role, setRole] = useState(null);

    useEffect(() => {
      if (data?.data?.length > 0) {
        setRole(data.data.find(role => role.id == value));
      }
    }, [value]);

    const smSizeStyles = {
      control: provided => ({
        ...provided,
        minHeight: 10
      }),
      dropdownIndicator: provided => ({ ...provided, padding: 1 }),
      clearIndicator: provided => ({ ...provided, padding: 2 }),
      input: provided => ({ ...provided, padding: 0, margin: 0 })
    };

    const getStyles = () => {
      if (size == 'sm') return smSizeStyles;
    };

    return (
      <ReactSelect
        styles={getStyles()}
        placeholder={placeholder}
        options={data?.data}
        name={name}
        onChange={role => onChange(role)}
        value={role}
        getOptionValue={role => role.id}
        getOptionLabel={role => role.display_name}
        className={className}
        isClearable={isClearable}
        isDisabled={isDisabled}
        isLoading={isLoading}
        isMulti={isMulti}
        isSearchable={isSearchable}
        {...rest}
      />
    );
  };

  return RoleSelectBox;
};

export default useRoleSelectBox;
