import IconButton from 'components/common/IconButton';
import CallApi from 'components/common/custom-components/CallApi';
import React, { useContext, useState } from 'react';
import { Form, Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { AdsAccountContext } from '../AdsAccountContext';

const AddWebMonitoringOverview = ({ refetchUrls = () => {} }) => {
  const adsAccountContext = useContext(AdsAccountContext);
  const [show, setShow] = useState(false);
  const [errors, setErrors] = useState({});
  const handleClose = () => {
    setShow(false);
    setErrors({});
    reset();
  };

  const { handleSubmit, register, reset, setValue } = useForm();

  const CreateWebMonitoringOverviewMutation = useMutation(
    'create-web-monitoring-overview',
    formData => CallApi.post('/web-monitoring-overviews', formData),
    {
      onSuccess: json => {
        console.log(json);
        if (json.status == 422) {
          setErrors(json.errors);
        } else {
          refetchUrls();
          handleClose();
        }
      }
    }
  );

  const onSubmit = (data, form) => {
    let formData = new FormData(form.target);

    formData.append('account_id', adsAccountContext.currentAccount?.id);

    if (!(data.url?.includes('http://') || data.url?.includes('https://'))) {
      setValue('url', `https://${data?.url}`);
      formData.set('url', `https://${data?.url}`);
    }

    CreateWebMonitoringOverviewMutation.mutate(formData);
  };

  return (
    <>
      <IconButton
        icon={'plus'}
        variant="outline-primary"
        className="rounded-pill"
        size="sm"
        onClick={() => setShow(true)}
      >
        Add New
      </IconButton>
      <Modal show={show} centered onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Web Monitoring Url</Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-3">
          <Form noValidate id="user-form" onSubmit={handleSubmit(onSubmit)}>
            <Form.Group className="mb-3">
              <Form.Label>Url</Form.Label>
              <Form.Control
                autoFocus
                {...register('url', {
                  required: true
                })}
                isInvalid={errors?.url?.length > 0}
              />
              <Form.Control.Feedback type="invalid">
                {errors?.url?.join(', ') || ''}
              </Form.Control.Feedback>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <IconButton
            icon="times-circle"
            size="sm"
            onClick={handleClose}
            variant="falcon-light"
          >
            Cancel
          </IconButton>
          <IconButton form="user-form" type="submit" icon="save" size="sm">
            Save
          </IconButton>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddWebMonitoringOverview;
