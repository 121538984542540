import Flex from 'components/common/Flex';
import React from 'react';
import AdsAccountProvider from './AdsAccountContext';
import AdsAccountSidebar from './AdsAccountSidebar';
import ViewForm from './ViewForm';
import AccountStatusNotice from './AccountStatusNotice';

const AdsAccount = () => {
  return (
    <AdsAccountProvider>
      <AccountStatusNotice />
      <Flex className={'me-3 mt-4'} justifyContent={'between'}>
        <div className="me-5" style={{ minWidth: '20%' }}>
          <AdsAccountSidebar />
        </div>
        <div className="flex-fill container">
          <ViewForm />
        </div>
      </Flex>
    </AdsAccountProvider>
  );
};

export default AdsAccount;
