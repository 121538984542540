import Flex from 'components/common/Flex';
import React from 'react';
import { Card } from 'react-bootstrap';
import SeoWizardProvider from './Context';
import ProgressAndPageTitle from './ProgressAndPageTitle';
import SeoWizardForm from './SeoWizardForm';
import Sidebar from './Sidebar';

const SeoProjectWizard = () => {
  return (
    <SeoWizardProvider>
      <Flex className={'mx-3 mt-3'} justifyContent={'between'}>
        <Sidebar />
        <div className="flex-fill">
          <ProgressAndPageTitle />
          <SeoWizardForm />
        </div>
      </Flex>
    </SeoWizardProvider>
  );
};

export default SeoProjectWizard;
