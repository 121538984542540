import CallApi from 'components/common/custom-components/CallApi';
import IconButton from 'components/common/IconButton';
import { upperCase } from 'lodash';
import { capitalize } from 'lodash';
import React, { useContext, useState } from 'react';
import { Form, Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { AdsAccountContext } from '../AdsAccountContext';

const AddTextAd = ({
  fieldname = '',
  adgroupId = null,
  selectedRowIds = {},
  refetchAds = () => {},
  setInitiallySelectedRowIds = arg => {}
}) => {
  const adsAccountContext = useContext(AdsAccountContext);
  const { currentAccount } = adsAccountContext;
  const [show, setShow] = useState(false);
  const [error, setError] = useState('');
  const handleClose = () => {
    setShow(false);
    setError('');
    reset();
  };

  const CreateTextAdMutation = useMutation(
    ['create-text-ad', fieldname],
    formdata => CallApi.post(`/text-ad?type=${upperCase(fieldname)}`, formdata),
    {
      onSuccess: json => {
        if (json.status == 200) {
          refetchAds();
          handleClose();
        }
      }
    }
  );

  const { handleSubmit, register, reset, watch } = useForm();

  const onSubmit = (data, form) => {
    let newInitiallySelectedRowIds = { 0: true };

    // if pre-select required
    // Object.keys(selectedRowIds).forEach(
    //   id => (newInitiallySelectedRowIds[+id + 1] = true)
    // );
    // setInitiallySelectedRowIds(newInitiallySelectedRowIds);

    CreateTextAdMutation.mutate(new FormData(form.target));
  };

  const moreThanAllowed =
    watch('text')?.length > (fieldname == 'headline' ? 30 : 90);

  return (
    <>
      <IconButton
        icon={'plus'}
        className="rounded-pill"
        variant="outline-primary"
        size="sm"
        onClick={() => setShow(true)}
      >
        Add
      </IconButton>
      <Modal show={show} centered onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add {capitalize(fieldname)}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-3">
          <Form
            noValidate
            id={`add-${fieldname}-form`}
            onSubmit={handleSubmit(onSubmit)}
          >
            <Form.Group className="mb-3" controlId="title">
              <Form.Label>{capitalize(fieldname)}</Form.Label>
              <input
                type="text"
                hidden
                name="adgroup_id"
                value={adgroupId}
                readOnly
              />
              <input
                type="text"
                hidden
                name="account_id"
                value={currentAccount?.id}
                readOnly
              />
              <Form.Control
                autoFocus
                {...register(`text`, {
                  required: true,
                  maxLength: fieldname == 'headline' ? 30 : 90
                })}
                placeholder={`Enter ${fieldname}`}
                isInvalid={!!(moreThanAllowed || error)}
              />
              <Form.Control.Feedback type="invalid">
                {error || ''}
              </Form.Control.Feedback>
              <div className="text-end mt-1">
                <span className={moreThanAllowed ? 'text-danger' : ''}>
                  {watch('text')?.length || 0}/
                  {fieldname == 'headline' ? 30 : 90}
                </span>
              </div>
            </Form.Group>{' '}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <IconButton
            icon="times-circle"
            size="sm"
            onClick={handleClose}
            variant="falcon-light"
          >
            Cancel
          </IconButton>
          <IconButton
            form={`add-${fieldname}-form`}
            type="submit"
            icon="save"
            size="sm"
          >
            Save
          </IconButton>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddTextAd;
