import React, { useContext, useEffect, useState } from 'react';
import { Navbar, Nav, Row, Col } from 'react-bootstrap';
import classNames from 'classnames';
import AppContext from 'context/Context';
import Logo from 'components/common/Logo';
import SearchBox from './SearchBox';
import NavbarTopDropDownMenus from './NavbarTopDropDownMenus';
import { navbarBreakPoint, topNavbarBreakpoint } from 'config';
import autoCompleteInitialItem from 'data/autocomplete/autocomplete';
import TopNavRightSideNavItem from './TopNavRightSideNavItem';
import { useLocation } from 'react-router-dom';
import Flex from 'components/common/Flex';
import { AidaAppContext } from 'context/AidaAppContext';
import AccountSelector from '../components/AccountSelector';

const NavbarTop = () => {
  const {
    config: { showBurgerMenu, navbarPosition, navbarCollapsed },
    setConfig
  } = useContext(AppContext);
  const aidaAppContext = useContext(AidaAppContext);
  const { pathname } = useLocation();
  const isChat = pathname.includes('chat');

  const [showDropShadow, setShowDropShadow] = useState(false);

  const handleBurgerMenu = () => {
    navbarPosition === 'top' && setConfig('navbarCollapsed', !navbarCollapsed);
    (navbarPosition === 'vertical' || navbarPosition === 'combo') &&
      setConfig('showBurgerMenu', !showBurgerMenu);
  };

  const setDropShadow = () => {
    const el = document.documentElement;
    if (el.scrollTop > 0) {
      setShowDropShadow(true);
    } else {
      setShowDropShadow(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', setDropShadow);
    return () => window.removeEventListener('scroll', setDropShadow);
  }, []);

  return (
    <Navbar
      className={classNames(
        'bg-white ps-md-5 pe-md-3 navbar-top m-0 sticky-kit',
        {
          // 'navbar-glass-shadow': showDropShadow,
          'navbar-glass-shadow': showDropShadow && !isChat
        }
      )}
      expand={
        navbarPosition === 'top' || navbarPosition === 'combo'
          ? topNavbarBreakpoint
          : true
      }
    >
      <Row className="w-100 align-items-center">
        <Col xs={4} md="auto">
          <Flex>
            <Navbar.Toggle
              className={classNames('toggle-icon-wrapper me-md-3 me-2', {
                // 'd-lg-none': navbarPosition === 'top',
                [`d-${navbarBreakPoint}-none`]:
                  navbarPosition === 'vertical' || navbarPosition === 'combo'
              })}
              as="div"
            >
              <button
                className="navbar-toggler-humburger-icon btn btn-link d-flex flex-center"
                onClick={handleBurgerMenu}
                id="burgerMenu"
              >
                <span className="navbar-toggle-icon">
                  <span className="toggle-line" />
                </span>
              </button>
            </Navbar.Toggle>

            <Logo at="navbar-top" width={40} id="topLogo" />
          </Flex>
        </Col>
        <Col>
          {navbarPosition === 'top' || navbarPosition === 'combo' ? (
            <Navbar.Collapse
              in={navbarCollapsed}
              className="scrollbar pb-3 pb-lg-0 w-100"
            >
              <Nav navbar className="w-100">
                <NavbarTopDropDownMenus className="w-100" />
              </Nav>
            </Navbar.Collapse>
          ) : (
            <Nav
              navbar
              className={`align-items-center d-none d-${topNavbarBreakpoint}-block`}
              as="ul"
            >
              <Nav.Item as="li">
                <SearchBox autoCompleteItem={autoCompleteInitialItem} />
              </Nav.Item>
            </Nav>
          )}
        </Col>

        <Col xs={6} md={5} lg={4} xl={3} xxl={2}>
          {aidaAppContext.agency_modules?.includes('google-ads') ? (
            <AccountSelector />
          ) : null}
        </Col>

        <Col xs="auto" md={1} lg={2}></Col>

        <Col xs={12} md="auto">
          <TopNavRightSideNavItem />
        </Col>
      </Row>
    </Navbar>
  );
};

export default NavbarTop;
