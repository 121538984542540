import React from 'react';
import ConfirmMailContent from 'components/authentication/ConfirmMailContent';
import AuthAidaLayout from 'layouts/AuthAidaLayout';

const ConfirmMail = () => (
  <AuthAidaLayout>
    <div className="text-center">
      <ConfirmMailContent layout="app" email="xyz@abc.com" titleTag="h3" />
    </div>
  </AuthAidaLayout>
);

export default ConfirmMail;
