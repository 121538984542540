import Flex from 'components/common/Flex';
import CallApi from 'components/common/custom-components/CallApi';
import ReactSelectBoxImageLabel from 'components/common/custom-components/ReactSelectBoxImageLabel';
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import ReactSelect from 'react-select';

const useCountrySelectBox = (identifier = 'id', custom_query = null) => {
  const { data, refetch } = useQuery(
    'country',
    async () => {
      const countries = await CallApi.get(custom_query || '/countries', false);
      // Sort alfabetically by country name
      countries.data.sort((a, b) => (a.name > b.name ? 1 : -1));
      return countries;
    },
    {
      placeholderData: { data: [] }
    }
  );

  const CountrySelectbox = ({
    name = 'country_id',
    className = '',
    isClearable = false,
    isDisabled = false,
    isLoading = false,
    isMulti = false,
    isSearchable = false,
    placeholder = 'Please select a country',
    onChange = country => {},
    value,
    size = 'md',
    ...rest
  }) => {
    const [country, setCountry] = useState(null);

    useEffect(() => {
      if (data?.data?.length > 0) {
        setCountry(data.data.find(country => country[identifier] == value));
      }
    }, [value]);

    const smSizeStyles = {
      control: provided => ({
        ...provided,
        minHeight: 10
      }),
      dropdownIndicator: provided => ({ ...provided, padding: 1 }),
      clearIndicator: provided => ({ ...provided, padding: 2 }),
      input: provided => ({ ...provided, padding: 0, margin: 0 })
    };

    const getStyles = () => {
      if (size == 'sm') return smSizeStyles;
    };

    return (
      <ReactSelect
        styles={getStyles()}
        placeholder={placeholder}
        options={data?.data}
        name={name}
        formatOptionLabel={country => (
          <Flex>
            <ReactSelectBoxImageLabel src={country.flag} title={country.name} />
          </Flex>
        )}
        onChange={country => onChange(country)}
        value={country}
        getOptionValue={country => country[identifier]}
        getOptionLabel={country => country.name}
        className={className}
        isClearable={isClearable}
        isDisabled={isDisabled}
        isLoading={isLoading}
        isMulti={isMulti}
        isSearchable={isSearchable}
        {...rest}
      />
    );
  };

  return { CountrySelectbox, refetch };
};

export default useCountrySelectBox;
