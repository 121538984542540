import CallApi from 'components/common/custom-components/CallApi';
import LocalStorageTooltip from 'components/common/custom-components/LocalStorageTooltip';
import React, { useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';

const Create = ({ loadListing, setShow, regionId, setRegionId }) => {
  const [region, setRegion] = useState({});
  const [validated, setValidated] = useState(false);

  useEffect(() => {
    if (regionId) {
      let getSingleRegionUrl = `/regions/${regionId}`;
      CallApi.get(getSingleRegionUrl).then(json => {
        if (json.status == 200) setRegion(json.data);
      });
    }
  }, [regionId]);

  const onInputChange = e => {
    setRegion({ ...region, [e.target.name]: e.target.value });
  };

  const handleSubmit = event => {
    const form = event.currentTarget;
    event.preventDefault();

    if (form.checkValidity() === false) {
      event.stopPropagation();
    }

    setValidated(true);
    let formData = new FormData(form);

    if (regionId === '') {
      let postUrl = `/regions`;
      CallApi.post(postUrl, formData).then(json => {
        if (json.status == 200) clearAndCloseModal();
      });
    } else {
      let putUrl = `/regions/${regionId}`;
      CallApi.put(putUrl, formData).then(json => {
        if (json.status == 200) clearAndCloseModal();
      });
    }
  };

  const clearAndCloseModal = () => {
    loadListing();
    setRegionId(null);
    setShow(false);
  };

  return (
    <>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Form.Group className="mb-3" controlId="name">
          <Form.Label>
            Name <LocalStorageTooltip identifier={`form_region_field_name`} />
          </Form.Label>
          <Form.Control
            type="text"
            name="name"
            value={region.name || ''}
            placeholder="Enter name"
            onChange={e => onInputChange(e)}
            required
          />
          <Form.Control.Feedback type="invalid">
            Please choose a name.
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3" controlId="description">
          <Form.Label>
            Description{' '}
            <LocalStorageTooltip identifier={`form_region_field_description`} />
          </Form.Label>
          <Form.Control
            type="text"
            value={region.description || ''}
            name="description"
            onChange={e => onInputChange(e)}
            placeholder="Enter description"
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="location_code">
          <Form.Label>
            Location Code{' '}
            <LocalStorageTooltip
              identifier={`form_region_field_location_code`}
            />
          </Form.Label>
          <Form.Control
            type="text"
            value={region.location_code || ''}
            name="location_code"
            onChange={e => onInputChange(e)}
            placeholder="Enter Location Code"
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="location_name">
          <Form.Label>
            Location Name{' '}
            <LocalStorageTooltip
              identifier={`form_region_field_location_name`}
            />
          </Form.Label>
          <Form.Control
            type="text"
            value={region.location_name || ''}
            name="location_name"
            onChange={e => onInputChange(e)}
            placeholder="Enter Location Name"
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="country_iso_code">
          <Form.Label>
            Country ISO code{' '}
            <LocalStorageTooltip
              identifier={`form_region_field_country_iso_code`}
            />
          </Form.Label>
          <Form.Control
            type="text"
            value={region.country_iso_code || ''}
            name="country_iso_code"
            onChange={e => onInputChange(e)}
            placeholder="Enter Country ISO code"
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="location_type">
          <Form.Label>
            Location Type{' '}
            <LocalStorageTooltip
              identifier={`form_region_field_location_type`}
            />
          </Form.Label>
          <Form.Control
            type="text"
            value={region.location_type || ''}
            name="location_type"
            onChange={e => onInputChange(e)}
            placeholder="Enter Location Type"
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="google_domain">
          <Form.Label>
            Google Domain{' '}
            <LocalStorageTooltip
              identifier={`form_region_field_google_domain`}
            />
          </Form.Label>
          <Form.Control
            type="text"
            value={region.google_domain || ''}
            name="google_domain"
            onChange={e => onInputChange(e)}
            placeholder="Enter Google Domain"
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="google_country">
          <Form.Label>
            Google Country{' '}
            <LocalStorageTooltip
              identifier={`form_region_field_google_country`}
            />
          </Form.Label>
          <Form.Control
            type="text"
            value={region.google_country || ''}
            name="google_country"
            onChange={e => onInputChange(e)}
            placeholder="Enter Google Country"
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="google_lang">
          <Form.Label>
            Google Lang{' '}
            <LocalStorageTooltip identifier={`form_region_field_google_lang`} />
          </Form.Label>
          <Form.Control
            type="text"
            value={region.google_lang || ''}
            name="google_lang"
            onChange={e => onInputChange(e)}
            placeholder="Enter Google Lang"
          />
        </Form.Group>

        <Form.Group className="d-flex justify-content-end">
          <Button variant="muted" onClick={() => setShow(false)}>
            Close
          </Button>
          <Button variant="primary" type="submit">
            Submit
          </Button>
        </Form.Group>
      </Form>
    </>
  );
};

export default Create;
