import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import CallApi from 'components/common/custom-components/CallApi';
import IconButton from 'components/common/IconButton';
import React, { useContext, useState } from 'react';
import { Card, Row, Col, Button } from 'react-bootstrap';
import { useMutation, useQuery } from 'react-query';
import ManualAddWebsiteLinkModal from '../../wizard-sub-forms/ManualAddWebsiteLinkModal';
import { AdsAccountContext } from '../AdsAccountContext';
import { validateJson } from 'helpers/utils';

const WebsiteLinks = () => {
  const adsAccountContext = useContext(AdsAccountContext);
  const { currentAccount, refetchCurrentAccount } = adsAccountContext;
  const [initiallySelectedRowIds, setInitiallySelectedRowIds] = useState({});
  const [websiteLinks, setWebsiteLinks] = useState([]);
  const WebsiteLinksQuery = useQuery(
    ['website-links', currentAccount?.id],
    ({ signal }) =>
      CallApi.get(
        `/scrape-links?domain=` + currentAccount?.website,
        false,
        {},
        signal
      ),
    {
      onSuccess: json => {
        const pages = currentAccount?.important_pages;
        // this step is present due to not cause error as we are changing the format of storing links in db
        const savedLinks =
          validateJson(pages) ||
          pages
            ?.replaceAll('"', '')
            ?.replaceAll('\\/', '/')
            ?.split(',')
            ?.filter(item => item != '') ||
          [];

        const savedJsonLinks = validateJson(pages)
          ? savedLinks
          : savedLinks?.map(link => ({ link: link })) || [];

        const newLinks =
          json?.data?.filter(
            item => !savedLinks?.map(link => link.link)?.includes(item?.link)
          ) || [];

        const collapsedLinks = [...savedJsonLinks, ...newLinks];

        let initialSelectedRowIndexes = {};

        const indexesOfPreviouslySelectedRows = collapsedLinks
          ?.map((urlObj, i) =>
            savedLinks?.map(link => link.link).includes(urlObj?.link) ? i : null
          )
          ?.filter(index => index != null);

        indexesOfPreviouslySelectedRows.forEach(index => {
          initialSelectedRowIndexes[index] = true;
        });

        setInitiallySelectedRowIds(initialSelectedRowIndexes);

        setWebsiteLinks(collapsedLinks);
      },
      enabled: !!currentAccount?.domain
    }
  );

  const columns = [
    {
      accessor: 'link',
      Header: 'Page link',
      Cell: ({ cell }) => {
        return (
          <a
            href={
              cell.value?.includes('http')
                ? cell.value
                : `https://${cell.value}`
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={'external-link-alt'} /> {cell.value}{' '}
            {cell.row.original?.manually_added ? (
              <Button
                variant="outline-warning"
                className="rounded-pill"
                size="sm"
              >
                Manually Added
              </Button>
            ) : null}
          </a>
        );
      }
    },
    {
      id: 'title',
      Header: 'Title',
      Cell: ({ cell }) => {
        const fetchTitleQuery = useQuery(
          ['title-query', cell.row.original?.link],
          ({ signal }) =>
            CallApi.get(
              `/scrape-title?url=${cell.row.original?.link}`,
              false,
              {},
              signal
            ),
          {
            refetchOnWindowFocus: false,
            refetchOnMount: false
          }
        );

        return fetchTitleQuery.isLoading ? (
          <FontAwesomeIcon icon={'spinner'} spin />
        ) : (
          fetchTitleQuery?.data?.data || '-'
        );
      }
    }
  ];

  const apiCallInProcess =
    WebsiteLinksQuery.isLoading || WebsiteLinksQuery.isFetching;

  const UpdateLinksMutation = useMutation(
    ['account-website-links', currentAccount?.id],
    formData =>
      CallApi.post(`/account/${currentAccount?.id}/important-pages`, formData),
    {
      onSuccess: json => {
        refetchCurrentAccount();
      }
    }
  );

  const UpdateLinksButton = ({ selectedFlatRows }) => {
    return (
      <IconButton
        disabled={apiCallInProcess || UpdateLinksMutation.isLoading}
        icon={UpdateLinksMutation.isLoading ? 'spinner' : 'check'}
        className="rounded-pill"
        spin={UpdateLinksMutation.isLoading}
        size="sm"
        onClick={() => {
          let formData = new FormData();

          formData.append(
            'important_pages',
            JSON.stringify(selectedFlatRows?.map(row => row.original))
          );

          UpdateLinksMutation.mutate(formData);
        }}
        variant="outline-info"
      >
        Update Links
      </IconButton>
    );
  };

  return (
    <Card>
      <Card.Body className="m-3">
        <AdvanceTableWrapper
          columns={columns}
          data={websiteLinks || []}
          perPage={25}
          sortable
          initialSelectedRowIds={initiallySelectedRowIds}
          pagination
          selection
        >
          <Row className="align-items-center mb-3">
            <Col xs="auto">
              <AdvanceTableSearchBox table />
            </Col>
            <Col />
            <Col xs="auto" className="pe-0">
              <ManualAddWebsiteLinkModal
                disabled={apiCallInProcess}
                setWebsiteLinks={setWebsiteLinks}
                websiteDomain={currentAccount?.domain}
                setInitiallySelectedRowIds={setInitiallySelectedRowIds}
                websiteLinks={websiteLinks}
                table
              />
            </Col>
            <Col xs="auto">
              <UpdateLinksButton table />
            </Col>
          </Row>
          <AdvanceTable
            table
            loadingText="Please wait, we are fetching your website's page links..."
            headerClassName="bg-aida-table-header text-900 text-nowrap align-middle"
            rowClassName="align-middle white-space-nowrap"
            tableProps={{
              className: 'fs--1 mb-0 overflow-hidden'
            }}
            rowProps={row => {
              return {
                className: classNames({
                  'bg-soft-success': row.isSelected
                })
              };
            }}
            apiCallInProcess={apiCallInProcess}
          />
          <AdvanceTableFooter
            table
            className="mt-3 mx-3"
            rowCount={websiteLinks.length}
            rowInfo
            navButtons
            rowsPerPageSelection
            rowsPerPageOptions={[25, 50, 75, 100]}
          />
        </AdvanceTableWrapper>
      </Card.Body>
    </Card>
  );
};

export default WebsiteLinks;
