import Flex from 'components/common/Flex';
import { AidaAppContext } from 'context/AidaAppContext';
import AppContext from 'context/Context';
import { inArray } from 'is_js';
import React, { useContext } from 'react';
import { Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { appRoutes } from 'routes/routes';
import NavbarDropdown from './NavbarDropdown';

const NavbarTopDropDownMenus = () => {
  const {
    config: { navbarCollapsed, showBurgerMenu },
    setConfig
  } = useContext(AppContext);
  const aidaAppContext = useContext(AidaAppContext);

  const handleDropdownItemClick = () => {
    if (navbarCollapsed) {
      setConfig('navbarCollapsed', !navbarCollapsed);
    }
    if (showBurgerMenu) {
      setConfig('showBurgerMenu', !showBurgerMenu);
    }
  };

  return (
    <Flex justifyContent={'between'} className="w-100">
      {/* <div>
        {inArray(aidaAppContext.user_role, ['Admin', 'Client Admin']) ? (
          <NavbarDropdown
            className="text-end"
            label={appRoutes.label}
            icon={appRoutes.icon}
            type={appRoutes.dropdownType}
          >
            {appRoutes.children
              .filter(route =>
                inArray(aidaAppContext.user_role, route.rolesCanAccess)
              )
              .map(route => (
                <Dropdown.Item
                  key={route.name}
                  as={Link}
                  className={route.active ? 'link-600' : 'text-500'}
                  to={route.to}
                  onClick={handleDropdownItemClick}
                >
                  {route.name}
                </Dropdown.Item>
              ))}
          </NavbarDropdown>
        ) : null}
      </div> */}
      <div className="mt-1 d-none d-md-block">
        {/* <Nav variant="pills" className="nav-pills-falcon m-0">
          <Nav.Item>
            <Nav.Link
              as={Button}
              onClick={() => setActiveButton('user_level')}
              active={activeButton == 'user_level'}
              size="sm"
              eventKey="user_level"
            >
              User Level
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              as={Button}
              onClick={() => setActiveButton('agency_level')}
              active={activeButton == 'agency_level'}
              size="sm"
              eventKey="agency_level"
            >
              Agency Level
            </Nav.Link>
          </Nav.Item>
        </Nav> */}
      </div>
      {/* <NavbarDropdown label={toolRoutes.label} type={toolRoutes.dropdownType}>
        {toolRoutes.children.map(route => (
          <Dropdown.Item
            key={route.name}
            as={Link}
            className={route.active ? 'link-600' : 'text-500'}
            to={route.to}
            onClick={handleDropdownItemClick}
          >
            {route.name}
          </Dropdown.Item>
        ))}
      </NavbarDropdown> */}
    </Flex>
  );
};

export default NavbarTopDropDownMenus;
