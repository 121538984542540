import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import CallApi from 'components/common/custom-components/CallApi';
import Flex from 'components/common/Flex';
import IconButton from 'components/common/IconButton';
import React, { useContext } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { AdsAccountContext } from '../AdsAccountContext';
import AddTextAd from './AddTextAd';
import AdPreview from './AdPreview';

const PhoneNumber = () => {
  const adsAccountContext = useContext(AdsAccountContext);
  const { currentAccount, refetchCurrentAccount } = adsAccountContext;

  const { handleSubmit, register, reset } = useForm();

  const updatePhoneMutation = useMutation(
    ['account-monthly-budget', currentAccount?.id],
    form =>
      CallApi.put(`/account/${currentAccount?.id}/phone`, new FormData(form))
  );

  const onSubmit = (data, form) => {
    updatePhoneMutation.mutate(form.target, {
      onSuccess: json => refetchCurrentAccount()
    });
  };
  return (
    <Row className="gap-3">
      <Col>
        <Card>
          <Card.Body className="m-3">
            <h5 className="border-bottom border-2 border-dark pb-3 mb-4">
              Add phone for {currentAccount?.account_name}
            </h5>
            <Form noValidate id="user-form" onSubmit={handleSubmit(onSubmit)}>
              <Form.Label>Phone:</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter phone number"
                name="phone"
                defaultValue={currentAccount?.phone}
              />
              <div className="text-end mt-4">
                <IconButton
                  type="submit"
                  className="rounded-pill"
                  variant="outline-primary"
                  icon={
                    updatePhoneMutation?.isLoading ? 'spinner' : 'pencil-alt'
                  }
                  spin={updatePhoneMutation.isLoading}
                  size="sm"
                >
                  Update Phone
                </IconButton>
              </div>
            </Form>
          </Card.Body>
        </Card>
      </Col>
      <Col md={5}>
        <AdPreview />
      </Col>
    </Row>
  );
};

export default PhoneNumber;
