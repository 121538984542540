import { faUserSecret } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTableWrapper, {
  SelectBoxFilterMenu
} from 'components/common/advance-table/AdvanceTableWrapper';
import Avatar, { AvatarGroup } from 'components/common/Avatar';
import CallApi from 'components/common/custom-components/CallApi';
import NameWithIcon from 'components/common/custom-components/NameWithIcon';
import Flex from 'components/common/Flex';
import { AidaAppContext } from 'context/AidaAppContext';
import { setItemToStore } from 'helpers/utils';
import { inArray } from 'is_js';
import React, { useContext } from 'react';
import { Button, Col, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { toast } from 'react-toastify';

const ImpersonateUsersTable = ({
  users = [],
  sudo_admin_token = '',
  sudo_username = ''
}) => {
  const history = useHistory();
  const aidaAppContext = useContext(AidaAppContext);
  const { refetchContext } = aidaAppContext;

  const impersonateUser = user_id => {
    const formData = new FormData();

    formData.append('device_name', 'Web App');

    CallApi.token(sudo_admin_token)
      .post('/login/impersonate/' + user_id, formData)
      .then(json => {
        if (json?.status == 200) {
          // clear temp/sudo token from callapi
          CallApi.token(null);
          toast.success('Logged In successfully.');
          setItemToStore('aidaToken', json.token);
          refetchContext();
          history.push('/');
        }
      });
  };

  const columns = [
    {
      accessor: 'email',
      Header: 'E-mail'
    },
    {
      accessor: 'name',
      Header: 'Name',
      Cell: ({ cell }) => (
        <NameWithIcon name={cell.value} icon={cell.row.original.avatar_url} />
      )
    },
    {
      accessor: 'status',
      Header: 'Status'
    },
    {
      accessor: 'agency.name',
      Header: 'Agency',
      Filter: SelectBoxFilterMenu,
      Cell: ({ cell }) => (
        <NameWithIcon
          name={cell.row.original?.agency?.name}
          icon={cell.row.original?.agency?.favicon}
        />
      )
    },
    {
      accessor: 'role.display_name',
      Header: 'Role'
    },
    {
      accessor: 'clients',
      Header: 'Clients',
      Cell: ({ cell }) => {
        const role = cell.row.original.role.name;
        const client = cell.row.original.client;

        return inArray(role, ['Client Admin', 'Client']) ? (
          <NameWithIcon name={client?.name} icon={client?.favicon} />
        ) : (
          <AvatarGroup>
            {cell.value.map(client => (
              <OverlayTrigger
                key={client.name}
                placement="top"
                overlay={<Tooltip>{client.name}</Tooltip>}
              >
                <div>
                  <Avatar size="l" src={client?.favicon} className="ms-n1" />
                </div>
              </OverlayTrigger>
            ))}
          </AvatarGroup>
        );
      }
    },
    {
      accessor: 'accounts',
      Header: 'Accounts',
      Cell: ({ cell }) => {
        const role = cell.row.original.role.name;

        return inArray(role, ['Client']) ? (
          <AvatarGroup>
            {cell.value?.map(account => (
              <OverlayTrigger
                key={account.name}
                placement="top"
                overlay={<Tooltip>{account.account_name}</Tooltip>}
              >
                <div>
                  <Avatar
                    name={account.account_name}
                    rounded="circle"
                    className="ms-n1"
                  />
                </div>
              </OverlayTrigger>
            ))}
          </AvatarGroup>
        ) : null;
      }
    },
    // { accessor: 'email', Header: 'E-mail' },
    {
      accessor: 'id',
      Header: 'Impersonate',
      cellProps: { className: 'text-center' },
      Cell: ({ cell }) => (
        <FontAwesomeIcon
          className="cursor-pointer"
          onClick={() => impersonateUser(cell.value)}
          icon={faUserSecret}
        />
      )
    }
  ];

  const AgencyFilterButtons = ({ setFilter, headers }) => {
    const filterValue = headers?.find(header =>
      header?.id == 'agency.name' ? header : null
    )?.filterValue;
    return (
      <>
        {users
          .map(user => user.agency)
          .reduce((agencies, agency) => {
            if (!agencies.map(item => item?.id).includes(agency?.id)) {
              agencies.push(agency);
            }

            return agencies;
          }, [])
          .filter(agency => !!agency?.id)
          .map(agency => (
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip>
                  {filterValue == agency?.name ? (
                    'Click to reset filter'
                  ) : (
                    <>
                      Click to filter users by <b>{agency?.name}</b> agency.
                    </>
                  )}
                </Tooltip>
              }
            >
              <Button
                size="sm"
                className="mx-1 py-1 px-2 rounded-pill"
                variant={filterValue == agency?.name ? 'dark' : 'outline-dark'}
                onClick={() => {
                  filterValue == agency?.name
                    ? setFilter('agency.name', null)
                    : setFilter('agency.name', agency?.name);
                }}
              >
                <Flex alignItems="center">
                  <Avatar size="m" src={agency?.favicon} />
                  <span className="ms-1">{agency?.name}</span>
                </Flex>
              </Button>
            </OverlayTrigger>
          ))}
      </>
    );
  };

  return (
    <>
      <AdvanceTableWrapper
        columns={columns}
        data={users || []}
        sortable
        pagination
      >
        <Row className="g-2 row mb-3">
          <Col xs="auto">
            <h4>
              <FontAwesomeIcon icon={faUserSecret} /> Welcome, {sudo_username}
            </h4>
            <h6>Choose user you want to impersonate</h6>
            <AdvanceTableSearchBox table />
          </Col>
          <Col>
            <Flex className="justify-content-end">
              <AgencyFilterButtons table />
            </Flex>
          </Col>
        </Row>
        <AdvanceTable
          table
          headerClassName="bg-aida-table-header text-900 text-nowrap align-middle"
          rowClassName="align-middle white-space-nowrap"
          tableProps={{
            bordered: true,
            striped: true,
            className: 'fs--1 mb-0 overflow-hidden'
          }}
        />
      </AdvanceTableWrapper>
    </>
  );
};

export default ImpersonateUsersTable;
