import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import CallApi from 'components/common/custom-components/CallApi';
import { ExtractHostname } from 'helpers/utils';
import React, { useEffect, useMemo, useState } from 'react';
import {
  Button,
  ButtonGroup,
  Card,
  Col,
  OverlayTrigger,
  Row,
  Tooltip
} from 'react-bootstrap';
import { useMutation, useQuery } from 'react-query';
import ManualAddKeywordModal from './ManualAddKeywordModal';
import StepDetailsCard from './StepDetailsCard';
import LocalStoragePageInfo from 'components/common/custom-components/LocalStoragePageInfo';

const KeywordsForm = ({ onNext, onPrevious, accountDraftId }) => {
  const [initiallySelectedRowIds, setInitiallySelectedRowIds] = useState({});
  const [domain, setDomain] = useState('');
  const [source, setSource] = useState('');
  const [competitorDomains, setCompetitorDomains] = useState([]);
  const columns = [
    {
      id: 'keyword',
      accessor: v => v[0].keyword,
      Header: 'Keyword'
    },
    {
      id: 'frequency',
      accessor: v => v.length,
      Header: 'Frequency',
      headerProps: { className: 'text-end' },
      cellProps: { className: 'text-end' }
    },
    {
      id: 'cpc',
      accessor: v => v[0].cpc,
      Header: 'CPC',
      headerProps: { className: 'text-end' },
      cellProps: { className: 'text-end' }
    },
    {
      id: 'volume',
      accessor: v => v[0].volume,
      Header: 'Volume',
      headerProps: { className: 'text-end' },
      cellProps: { className: 'text-end' }
    },
    {
      id: 'competition',
      accessor: v => v[0].competition,
      Header: 'Competition',
      headerProps: { className: 'text-end' },
      cellProps: { className: 'text-end' }
    },
    {
      accessor: 'kei',
      Header: 'KEI',
      cellProps: { className: 'text-end' }
    },
    {
      id: 'my_position',
      Header: 'My Position',
      headerProps: { className: 'text-end' },
      cellProps: { className: 'text-end' },
      accessor: v =>
        v.find(item => ExtractHostname(item.url) == domain)?.position
    }
  ];
  const [keywords, setKeywords] = useState([]);

  const AccountDraftQuery = useQuery(
    ['account-draft-query', accountDraftId],
    () =>
      CallApi.get(
        `/account-details-draft/get-details-by-draft-id/` + accountDraftId,
        false
      ),
    {
      onSuccess: json => {
        const parsedInfoObject = JSON.parse(json.data.basic_information);
        const competitorDomains = json.data.competitor
          ?.replaceAll('"', '')
          ?.replaceAll('\\/', '/')
          ?.split(',');

        const website = parsedInfoObject.website;
        setDomain(website);

        const source = parsedInfoObject.country?.toLowerCase();
        setSource(source);

        setCompetitorDomains(competitorDomains);
      }
    }
  );

  const KeywordsMutation = useMutation(
    ['keywords'],
    formData => CallApi.post(`/research-keywords`, formData),
    {
      onSuccess: json => {
        const draftedKeywords = AccountDraftQuery?.data?.data?.keyword || '';

        const savedKeywords = draftedKeywords
          ?.replaceAll('"', '')
          ?.replaceAll('\\/', '/')
          ?.split(',')
          ?.filter(item => item != '');

        const keywordsArray =
          json?.data?.filter(items =>
            items?.find(
              item =>
                ExtractHostname(item.url) == domain ||
                competitorDomains.includes(ExtractHostname(item.url))
            )
          ) || [];

        const allKeywords = [
          ...savedKeywords
            .filter(
              keyword =>
                !keywordsArray?.map(item => item[0].keyword)?.includes(keyword)
            )
            .map(keyword => [
              {
                keyword: keyword,
                common_keywords: '-',
                total_keywords: '-',
                relevance: '-',
                points: '-'
              }
            ]),
          ...keywordsArray
        ];

        let initialSelectedRowIndexes = {};

        const indexesOfPreviouslySelectedRows = allKeywords
          ?.map((items, i) =>
            [...savedKeywords].includes(items[0]?.keyword) ? i : null
          )
          ?.filter(index => index != null);

        indexesOfPreviouslySelectedRows.forEach(index => {
          initialSelectedRowIndexes[index] = true;
        });

        setInitiallySelectedRowIds(initialSelectedRowIndexes);

        setKeywords(allKeywords);
      }
    }
  );
  useEffect(() => {
    if (domain && competitorDomains && source) {
      var formData = new FormData();

      formData.append('my_domain', domain);
      formData.append('location', source);
      formData.append('competitor_domains', competitorDomains.join('\n'));

      KeywordsMutation.mutate(formData);
    }
  }, [domain, competitorDomains, source]);

  const saveKeywords = selectedRows => {
    let selectedKeywords = selectedRows
      .map(row => row.original[0]?.keyword)
      .join(',');

    var data = new FormData();

    data.append('keyword', selectedKeywords);
    data.append('draft_id', accountDraftId);
    data.append('form_type', 'keyword');

    CallApi.post(`/account-details-draft`, data).then(json => {
      if (json.status == 200) {
        onNext();
      }
    });
  };

  const NextPrevButtons = ({ selectedFlatRows }) => {
    const isNextStepButtonDisabled = selectedFlatRows?.length < 10;
    return (
      <ButtonGroup className="mt-4">
        <Button
          variant="outline-primary"
          className="rounded-pill me-3"
          onClick={onPrevious}
        >
          <FontAwesomeIcon icon={'arrow-left'} /> Previous
        </Button>
        <OverlayTrigger
          placement={'top'}
          overlay={
            isNextStepButtonDisabled ? (
              <Tooltip>at least 10 keywords must be selected</Tooltip>
            ) : (
              <></>
            )
          }
        >
          <span className="d-inline-block">
            <Button
              variant="outline-primary"
              className="rounded-pill"
              disabled={isNextStepButtonDisabled}
              onClick={() => saveKeywords(selectedFlatRows)}
            >
              Next Step: Region and Budget{' '}
              <FontAwesomeIcon icon={'arrow-right'} />
            </Button>
          </span>
        </OverlayTrigger>
      </ButtonGroup>
    );
  };

  return (
    <Col>
      <LocalStoragePageInfo slug="account_form_keywords">
        <Row>
          {/*{progressBar && <ProgressBar now={25} style={{ height: 2 }} />}*/}
          <StepDetailsCard
            step="4"
            text="Select the keywords that you think your customers are searching for to find your website. Use 'Add manually' if you are missing any keywords."
            title="Keywords"
          />
        </Row>
      </LocalStoragePageInfo>
      <Card>
        <Card.Body className="m-3">
          <AdvanceTableWrapper
            columns={columns}
            data={keywords}
            selection
            perPage={25}
            sortable
            pagination
            initialSelectedRowIds={initiallySelectedRowIds}
            sortByOptions={useMemo(
              () => [
                {
                  id: 'frequency',
                  desc: true
                }
              ],
              []
            )}
          >
            <Row className="align-items-center mb-3">
              <Col xs="auto">
                <AdvanceTableSearchBox table />
              </Col>
              <Col />
              <Col xs="auto">
                <ManualAddKeywordModal
                  table
                  setKeywords={setKeywords}
                  keywords={keywords}
                  setInitiallySelectedRowIds={setInitiallySelectedRowIds}
                  variant="outline-primary"
                  className="rounded-pill"
                />
              </Col>
            </Row>
            <AdvanceTable
              // @ts-ignore
              apiCallInProcess={
                AccountDraftQuery?.isLoading || KeywordsMutation?.isLoading
              }
              table
              headerClassName="bg-aida-table-header text-900 text-nowrap align-middle"
              rowClassName="align-middle white-space-nowrap"
              tableProps={{
                bordered: true,
                striped: true,
                className: 'fs--1 mb-0 overflow-hidden'
              }}
            />
            <AdvanceTableFooter
              // @ts-ignore
              showSelectedRowCount
              table
              className="mt-3 mx-3"
              rowCount={keywords?.length}
              rowInfo
              navButtons
              rowsPerPageOptions={[25, 50, 75, 100, 150, 200]}
              rowsPerPageSelection
            />
            <div className="text-center">
              <NextPrevButtons table />
            </div>
          </AdvanceTableWrapper>
        </Card.Body>
      </Card>
    </Col>
  );
};

export default KeywordsForm;
