import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CallApi from 'components/common/custom-components/CallApi';
import { deleteItemFromStore } from 'helpers/utils';
import React, { createContext, useState } from 'react';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';
import FreshChatPlugin from 'components/common/custom-components/FreshChatPlugin';

export const defaultAidaAppContext = {
  client: {},
  user_role: '',
  is_background_color_too_dark: false,
  agency: {},
  agency_modules: [],
  user_modules: [],
  account: null,
  current_user: {},
  refetchContext: () => {
    console.log('This will refetch context');
  },
  setAidaAppContext: newContext => {}
};

export const AidaAppContext = createContext(defaultAidaAppContext);

const AppProvider = ({ children }) => {
  const [appState, setAppState] = useState({
    ...defaultAidaAppContext
  });

  const history = useHistory();
  const location = useLocation();

  const { isLoading, isRefetching, refetch } = useQuery(
    'me/context-details',
    () => CallApi.get('/me/context-details'),
    {
      onSuccess: response => {
        if (
          response?.status == 401 &&
          !location.pathname?.includes('reset-password') &&
          !location.pathname?.includes('forgot-password') &&
          !location.pathname?.includes('verify-seo-project')
        ) {
          deleteItemFromStore('aidaToken');
          history.push('/app/login');
        } else if (response?.status == 200) {
          const agencyModules =
            response?.agency?.modules?.map(module => module.identifier) || [];

          const userModules =
            response?.user?.modules?.map(module => module.identifier) || [];

          const newAppState = {
            ...appState,
            client: response?.client,
            agency: response?.agency,
            user_role: response?.user_role,
            current_user: response?.user,
            agency_modules: agencyModules,
            user_modules: userModules
          };

          setAppState(newAppState);
        }
      }
    }
  );

  return (
    <AidaAppContext.Provider
      value={{
        ...appState,
        refetchContext: refetch,
        setAidaAppContext: value => setAppState(value)
      }}
    >
      {isLoading || (location.pathname == '/' && isRefetching) ? (
        <div className="d-flex vh-100 align-items-center justify-content-center">
          <div>
            <FontAwesomeIcon icon={'spinner'} className="me-2" spin />
            Loading app...
          </div>
        </div>
      ) : (
        <>
          {children}
          <FreshChatPlugin />
        </>
      )}
    </AidaAppContext.Provider>
  );
};

export default AppProvider;
