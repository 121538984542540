import { AccountSettings as AccountSettingsCog } from 'assets/img/accounts/images';
import Flex from 'components/common/Flex';
import React, { useContext, useState } from 'react';
import { Button, Card, Col, Nav, Row } from 'react-bootstrap';
import AudienceSettings from './account-settings/AudienceSettings';
import ProfileSettings from './account-settings/ProfileSettings';
import AccountUsers from './account-settings/AccountUsers';
import WebsiteLinks from './account-settings/WebsiteLinks';
import { AdsAccountContext } from './AdsAccountContext';
import LocalStoragePageInfo from 'components/common/custom-components/LocalStoragePageInfo';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const AccountSettings = () => {
  const subSettings = [
    {
      name: 'Profile Settings',
      description: `Update basic account details`,
      component: <ProfileSettings />
    },
    {
      name: 'Target group, region and budget',
      description: 'Audience settings',
      component: <AudienceSettings />
    },
    {
      name: 'Most important pages',
      description: 'Website links',
      component: <WebsiteLinks />
    },
    { name: 'Users', description: 'Account users', component: <AccountUsers /> }
  ];

  const [activeAccountSettings, setActiveAccountSettings] = useState(
    subSettings[0]
  );

  const subSettingsLink = (link, i) => (
    <Nav.Item
      key={i}
      className={`fs--1 border-top border-2 px-3 w-100 border-dark py-2`}
    >
      <Flex justifyContent={'between'} alignItems={'center'}>
        <span>
          <span className="fw-medium">{link.name}:</span> {link.description}
        </span>
        <Button
          onClick={() => setActiveAccountSettings(link)}
          className={`rounded-pill border-0 ${
            activeAccountSettings?.name == link?.name
              ? 'bg-soft-primary text-primary'
              : ''
          }`}
          size="sm"
        >
          View and edit <FontAwesomeIcon icon={'angle-right'} />
        </Button>
      </Flex>
    </Nav.Item>
  );

  return (
    <>
      <LocalStoragePageInfo slug="account_settings">
        <Row className="text-dark">
          <Col>
            <Flex
              className="h-100 px-4 py-2"
              justifyContent="between"
              direction="column"
            >
              <div>
                <h2 className="mb-0 font-ibm-plex-serif">Account Settings</h2>
                <p className="fs-1 mt-1">Manage accounts settings</p>
              </div>
              <Nav>{subSettings.map(subSettingsLink)}</Nav>
            </Flex>
          </Col>
          <Col lg={5} xxl={4}>
            <img src={AccountSettingsCog} className="w-100" />
          </Col>
        </Row>
      </LocalStoragePageInfo>
      {activeAccountSettings?.component}
    </>
  );
};

export default AccountSettings;
