import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import LoginForm from 'components/authentication/LoginForm';

import AuthAidaLayout from 'layouts/AuthAidaLayout';
import { KeyBackground } from 'assets/img/aida-auth/images';
import ImpersonateUsersTable from '../ImpersonateUsersTable';
import { Card, Col, Row } from 'react-bootstrap';
import Section from 'components/common/Section';
import ForceChangePassword from './ForceChangePassword';

const Login = () => {
  const [showImpersonationCard, setShowImpersonationCard] = useState(false);
  const [users, setUsers] = useState([]);
  const [sudoUser, setSudoUser] = useState({ name: '', token: '' });
  const [showPasswordResetForm, setShowPasswordResetForm] = useState(false);

  const getForm = () =>
    showPasswordResetForm ? (
      <ForceChangePassword />
    ) : (
      <AuthAidaLayout
        backgroundImage={KeyBackground}
        leftSideContent={
          <p className="text-white">
            Don't have an account?
            <br />
            <Link
              className="text-white text-decoration-underline"
              to="/authentication/app/register"
            >
              Get started!
            </Link>
          </p>
        }
      >
        <h3 className="mb-5 font-ibm-plex-sans">Welcome to log in</h3>
        <LoginForm
          layout="app"
          hasLabel
          setShowImpersonationCard={setShowImpersonationCard}
          setUsers={setUsers}
          setSudoUser={setSudoUser}
          showPasswordResetForm={() => setShowPasswordResetForm(true)}
        />
      </AuthAidaLayout>
    );

  return showImpersonationCard ? (
    <Section className={'py-0'}>
      <Row className="g-0 min-vh-100 flex-center">
        <Col>
          <Card>
            <Card.Body>
              <ImpersonateUsersTable
                users={users || []}
                sudo_username={sudoUser.name}
                sudo_admin_token={sudoUser.token}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Section>
  ) : (
    getForm()
  );
};

export default Login;
