import IconButton from 'components/common/IconButton';
import { trim } from 'lodash';
import React, { useState } from 'react';
import { Form, Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';

const ManualAddCompetitorModal = ({
  selectedRowIds,
  setCompetitors,
  competitors,
  maxSelection,
  setInitiallySelectedRowIds,
  ...rest
}) => {
  const [show, setShow] = useState(false);
  const [error, setError] = useState('');
  const handleClose = () => {
    setShow(false);
    setError('');
    reset();
  };

  const { handleSubmit, register, reset } = useForm();

  const onSubmit = formData => {
    const domains = [
      ...new Set(
        formData.domains
          ?.split('\n')
          .map(domain => trim(domain))
          .filter(domain => domain != '')
      )
    ];

    let newTopCompetitors = [];

    let newInitiallySelectedRowIds = { 0: false };

    domains.forEach((domain, i) => {
      // remove if duplicate domain
      if (
        competitors
          ?.map(fetchedCompetitor => fetchedCompetitor?.domain)
          ?.includes(domain)
      ) {
        return;
      }
      newTopCompetitors.push({
        domain: domain,
        common_keywords: '-',
        total_keywords: '-',
        relevance: '-',
        points: '-'
      });
      if (!maxSelection) {
        newInitiallySelectedRowIds[i] = true;
      }
    });

    Object.keys(selectedRowIds).forEach(
      id => (newInitiallySelectedRowIds[+id + +newTopCompetitors.length] = true)
    );

    const newCompetitors = [...newTopCompetitors, ...competitors];

    newCompetitors.forEach((competitor, i) => {
      const foundExisting = domains.includes(competitor.domain);

      const newSelectedRowCount = Object.keys(
        newInitiallySelectedRowIds
      ).reduce((total, val) => {
        return newInitiallySelectedRowIds[val] ? total + 1 : total;
      }, 0);

      if (maxSelection) {
        if (newSelectedRowCount < maxSelection && foundExisting) {
          newInitiallySelectedRowIds[+i] = true;
        }
      } else if (foundExisting) {
        newInitiallySelectedRowIds[+i] = true;
      }
    });

    setInitiallySelectedRowIds(newInitiallySelectedRowIds);
    setCompetitors(newCompetitors);
    handleClose();
  };

  return (
    <>
      <IconButton
        icon={'plus'}
        variant="primary"
        size="sm"
        onClick={() => setShow(true)}
        {...rest}
      >
        Add Manually
      </IconButton>
      <Modal show={show} centered onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add custom competitor</Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-3">
          <Form
            noValidate
            id="manual-add-competitor-form"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Form.Group className="mb-3">
              <Form.Label>Competitor Domain</Form.Label>
              <Form.Control
                as="textarea"
                rows={5}
                autoFocus
                {...register('domains', {
                  required: true
                })}
                isInvalid={!!error}
              />
              <Form.Control.Feedback type="invalid">
                {error || ''}
              </Form.Control.Feedback>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <IconButton
            icon="times-circle"
            size="sm"
            onClick={handleClose}
            variant="falcon-light"
          >
            Cancel
          </IconButton>

          <IconButton
            className="rounded-pill"
            variant="outline-primary"
            form="manual-add-competitor-form"
            type="submit"
            icon="save"
            size="sm"
          >
            Save
          </IconButton>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ManualAddCompetitorModal;
