import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NavbarVertical from 'components/navbar/vertical/NavbarVertical';
import { progressBar } from 'data/dashboard/projectManagement';
import { indexOf } from 'lodash';
import React, { useState } from 'react';
import {
  Card,
  Col,
  Nav,
  NavItem,
  NavLink,
  ProgressBar,
  Row
} from 'react-bootstrap';
import AccountForm from './wizard-sub-forms/AccountForm';
import BasicInformationForm from './wizard-sub-forms/BasicInformationForm';
import YourWebsiteForm from './wizard-sub-forms/YourWebsiteForm';
import CompetitorsForm from './wizard-sub-forms/CompetitorsForm';
import KeywordsForm from './wizard-sub-forms/KeywordsForm';
import RegionAndBudgetForm from './wizard-sub-forms/RegionAndBudgetForm';
import FinishForm from './wizard-sub-forms/FinishForm';
import AccountCreated from './wizard-sub-forms/AccountCreated';
import Flex from 'components/common/Flex';
import classNames from 'classnames';

const CreateAccountWizardForm = () => {
  const widgetMenus = [
    { key: 1, value: 'Basic information' },
    { key: 2, value: 'Your website' },
    { key: 3, value: 'Competitors' },
    { key: 4, value: 'Keywords' },
    { key: 5, value: 'Region and budget' },
    { key: 6, value: 'Finalize' },
    { key: 7, value: 'Created' }
  ];

  const [draftId, setDraftId] = useState(
    Math.floor(Math.random() * (999999999 - 100000000 + 1)) + 100000000
  );

  const [step, setStep] = useState(1);

  const handleNextStep = () => {
    setStep(step + 1);
  };

  const handlePrevStep = () => {
    setStep(step - 1);
  };

  const renderForm = () => {
    switch (step) {
      case 1:
        return (
          <BasicInformationForm
            onNext={handleNextStep}
            accountDraftId={draftId}
          />
        );
      case 2:
        return (
          <YourWebsiteForm
            onNext={handleNextStep}
            onPrevious={handlePrevStep}
            accountDraftId={draftId}
          />
        );
      case 3:
        return (
          <CompetitorsForm
            onNext={handleNextStep}
            onPrevious={handlePrevStep}
            accountDraftId={draftId}
          />
        );
      case 4:
        return (
          <KeywordsForm
            onNext={handleNextStep}
            onPrevious={handlePrevStep}
            accountDraftId={draftId}
          />
        );
      case 5:
        return (
          <RegionAndBudgetForm
            onNext={handleNextStep}
            onPrevious={handlePrevStep}
            accountDraftId={draftId}
          />
        );
      case 6:
        return (
          <FinishForm
            onNext={handleNextStep}
            onPrevious={handlePrevStep}
            accountDraftId={draftId}
          />
        );
      case 7:
        return <AccountCreated />;
      // Add more cases for additional steps
      default:
        return null;
    }
  };

  return (
    <Flex className={'mx-3 mt-4'} justifyContent={'between'}>
      <div className="me-5" style={{ minWidth: '20%' }}>
        <h5 className="text-dark">Creating new account</h5>
        <hr className="text-dark my-3 border border-1 border-dark" />
        {widgetMenus.map(widgetMenu => (
          <div
            key={widgetMenu.key}
            className="d-flex align-items-baseline ms-3"
          >
            {step == widgetMenu.key ? (
              <FontAwesomeIcon className="text-dark" icon={'arrow-right'} />
            ) : (
              <>&nbsp;&nbsp;&nbsp;</>
            )}
            <span
              className={classNames(
                'ms-2 btn text-dark',
                step === widgetMenu.key ? 'fw-bold' : ''
              )}
            >
              {widgetMenu.value}
            </span>
          </div>
        ))}
      </div>
      <div className="flex-fill container">
        {/* Render form content based on the current step */}
        {renderForm()}
      </div>
    </Flex>
  );
};

export default CreateAccountWizardForm;
