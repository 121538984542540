import React, { useMemo, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import AdPreview from './AdPreview';
import SiteLinksTable from './SiteLinksTable';

const SiteLinks = () => {
  return (
    <Row className="gap-3">
      <Col>
        <SiteLinksTable />
      </Col>
      <Col md={5}>
        <AdPreview />
      </Col>
    </Row>
  );
};

export default SiteLinks;
