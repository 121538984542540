import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useAccountCategorySelectBox from 'components/aida/custom-form-hooks/useAccountCategorySelectBox';
import useAccountTypeSelectBox from 'components/aida/custom-form-hooks/useAccountTypeSelectBox';
import useClientSelectBox from 'components/aida/custom-form-hooks/useClientSelectBox';
import useCountrySelectBox from 'components/aida/custom-form-hooks/useCountrySelectBox';
import useLanguageSelectBox from 'components/aida/custom-form-hooks/useLanguageSelectBox';
import CallApi from 'components/common/custom-components/CallApi';
import { validateJson } from 'helpers/utils';
import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Row, Table } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import StepDetailsCard from './StepDetailsCard';
import LocalStoragePageInfo from 'components/common/custom-components/LocalStoragePageInfo';

const FinishForm = ({ onNext, onPrevious, accountDraftId }) => {
  const [info, setInfo] = useState([]);
  const [competitor, setCompetitor] = useState([]);
  const [keyword, setKeyword] = useState([]);
  const [website, setWebsite] = useState([]);
  const [region, setRegion] = useState([]);
  const [target, setTarget] = useState([]);
  const [budget, setBudget] = useState([]);

  useEffect(() => {
    CallApi.get(
      `/account-details-draft/get-details-by-draft-id/` + accountDraftId,
      false
    ).then(json => {
      if (json.status == 200) {
        if (json.data.basic_information) {
          setInfo(JSON.parse(json.data.basic_information));
        }

        if (json.data.competitor) {
          setCompetitor(
            json.data.competitor
              ?.replaceAll('"', '')
              ?.replaceAll('\\/', '/')
              ?.split(',')
          );
        }

        if (json.data.website) {
          setWebsite(
            validateJson(json.data.website)
              ? JSON.parse(json.data.website)?.map(linkObj => linkObj.link)
              : json.data.website
                  ?.replaceAll('"', '')
                  ?.replaceAll('\\/', '/')
                  ?.split(',')
          );
        }

        if (json.data.keyword) {
          setKeyword(
            json.data.keyword
              ?.replaceAll('"', '')
              ?.replaceAll('\\/', '/')
              ?.split(',')
          );
        }

        if (json.data.region) {
          var regionRecord = JSON.parse(json.data.region);

          var targets = JSON.parse(regionRecord.target);
          setTarget(targets);
          setBudget(regionRecord.budget);
          setRegion(JSON.parse(regionRecord.regions));
        }
      }
    });
  }, []);

  const findSelectedValue = obj => {
    for (const key in obj) {
      if (typeof obj[key] === 'object') {
        const result = findSelectedValue(obj[key]);
        if (result) return result;
      } else if (obj[key] === 'selected') {
        return key;
      }
    }
    return null;
  };

  const history = useHistory();

  const save = () => {
    var data = new FormData();
    data.append('draft_id', accountDraftId);

    CallApi.post(`/account-details-draft/save-records`, data).then(json => {
      if (json.status == 200) {
        onNext();
      }
    });
  };
  const { ClientSelectBox } = useClientSelectBox();
  const { AccountCategorySelectBox } = useAccountCategorySelectBox();
  const { AccountTypeSelectBox } = useAccountTypeSelectBox();
  const { LanguageSelectBox } = useLanguageSelectBox();
  const { CountrySelectbox } = useCountrySelectBox('iso_3166_2');
  return (
    <Col>
      <LocalStoragePageInfo slug="account_form_confirm_account">
        <Row>
          {/*{progressBar && <ProgressBar now={25} style={{ height: 2 }} />}*/}
          <StepDetailsCard
            step="6"
            text="Take a final look to confirm your settings are correct before creating your account. And don't worry - everything can be updated afterwards."
            title="Confirm Your Account"
          />
        </Row>
      </LocalStoragePageInfo>
      <Card>
        <Card.Body className="m-3">
          <h5 className="text-dark border-bottom border-2 border-dark pb-3 mb-4">
            Basic information
          </h5>
          <Table>
            <tbody>
              <tr>
                <td>Account name: {info.account_name}</td>
                <td>Website: {info.website}</td>
              </tr>
              <tr>
                <td>
                  <Row>
                    <Col xs="auto">Country: </Col>
                    <Col xs="5">
                      <CountrySelectbox value={info?.country} isDisabled />
                    </Col>
                  </Row>
                </td>
                <td>
                  <Row>
                    <Col xs="auto">Type: </Col>
                    <Col xs="5">
                      <AccountTypeSelectBox value={info?.type} isDisabled />
                    </Col>
                  </Row>
                </td>
              </tr>
              <tr>
                <td>
                  <Row>
                    <Col xs="auto">Language: </Col>
                    <Col xs="5">
                      <LanguageSelectBox value={info?.language} isDisabled />
                    </Col>
                  </Row>
                </td>
                <td>
                  <Row>
                    <Col xs="auto">Category:</Col>
                    <Col xs="5">
                      <AccountCategorySelectBox
                        value={info?.category}
                        isDisabled
                      />
                    </Col>
                  </Row>
                </td>
              </tr>
              <tr>
                <td>Goal with ads: {info.goal}</td>
                <td>
                  <Row>
                    <Col xs="auto">Client:</Col>
                    <Col xs="5">
                      <ClientSelectBox value={info?.client_id} isDisabled />
                    </Col>
                  </Row>
                </td>
              </tr>
            </tbody>
          </Table>
          <h5 className="text-dark border-bottom border-2 border-dark pb-3 my-4">
            Your Website (pages that best describe your product/service)
          </h5>
          {website?.map((item, i) => (
            <>
              {i + 1}. {item}
              <br />
            </>
          ))}

          <h5 className="text-dark border-bottom border-2 border-dark pb-3 my-4">
            Main Competitors
          </h5>
          {competitor?.map((item, i) => (
            <>
              {i + 1}. {item}
              <br />
            </>
          ))}

          <h5 className="text-dark border-bottom border-2 border-dark pb-3 my-4">
            Keywords
          </h5>
          <span>
            {keyword?.map((item, i) => (
              <>
                {i + 1}. {item}
                <br />
              </>
            ))}
          </span>

          <Row className="my-4">
            <Col lg={4}>
              <h5 className="text-dark border-bottom border-2 border-dark pb-3 my-4">
                Regions
              </h5>
              <p>{region.map(item => item.label).join(',  ')}</p>
            </Col>
            <Col lg={4}>
              <h5 className="text-dark border-bottom border-2 border-dark pb-3 my-4">
                Target group
              </h5>
              {target.map(item => (
                <div key={item.index}>
                  Age: {item?.age_group}, M: {item?.male}, F: {item?.female}
                  <br />
                </div>
              ))}
            </Col>
            <Col lg={4}>
              <h5 className="text-dark border-bottom border-2 border-dark pb-3 my-4">
                Budget
              </h5>
              <p className="text-center">{budget} sek/month</p>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <div className="button-group mt-4 text-center">
        <Button
          className="rounded-pill me-3"
          variant="outline-primary"
          onClick={onPrevious}
        >
          <FontAwesomeIcon icon={'arrow-left'} /> Previous
        </Button>
        <Button
          onClick={save}
          variant="outline-primary"
          className="rounded-pill"
        >
          Finish <FontAwesomeIcon icon={'arrow-right'} />
        </Button>
      </div>
    </Col>
  );
};

export default FinishForm;
