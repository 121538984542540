import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ExportXLSX from 'components/aida/budget-overviews/components/ExportXLSX';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import CallApi from 'components/common/custom-components/CallApi';
import FalconCardHeader from 'components/common/FalconCardHeader';
import Flex from 'components/common/Flex';
import { getYYYYMMDDFormatDate } from 'helpers/utils';
import React, { useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const CompetitorsTable = ({ source, domain, refetch }) => {
  const columns = [
    {
      accessor: 'domain',
      Header: 'Domain',
      Cell: ({ cell }) => (
        <a
          href={`https://${cell.value}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon icon={'external-link-alt'} /> {cell.value}
        </a>
      )
    },
    {
      accessor: 'common_keywords',
      Header: 'Common Keyword',
      headerProps: { className: 'text-end' },
      cellProps: { className: 'text-end' }
    },
    {
      accessor: 'total_keywords',
      Header: 'Total Keyword',
      headerProps: { className: 'text-end' },
      cellProps: { className: 'text-end' }
    },
    {
      accessor: 'relevance',
      Header: 'Relevance',
      headerProps: { className: 'text-end' },
      cellProps: { className: 'text-end' }
    },
    {
      accessor: 'points',
      Header: 'Points',
      headerProps: { className: 'text-end' },
      cellProps: { className: 'text-end' }
    }
  ];
  const [data, setData] = useState([]);
  const [apiCallInProcess, setApiCallInProcess] = useState(false);
  const loadListing = () => {
    if (source && domain) {
      setApiCallInProcess(true);
      CallApi.get(
        `/competitors/se-ranking-reports/competitors?source=${source}&domain=${domain}`,
        true
      ).then(json => {
        if (json.status == 200) {
          setApiCallInProcess(false);
          setData(json.data);
        }
      });
    }
  };

  useEffect(() => {
    loadListing();
  }, [refetch]);
  return (
    <Card>
      <Card.Body className="m-3">
        <AdvanceTableWrapper columns={columns} data={data} sortable pagination>
          <Row className="align-items-center mb-3">
            <Col>
              <h5>Competitors</h5>
            </Col>
            <Col xs="auto" className="text-right">
              <Row xs="auto">
                <Col className="pe-0">
                  <AdvanceTableSearchBox table />
                </Col>
                <Col xs="auto" className="ps-0">
                  <ExportXLSX
                    table
                    sheetHeaderRow={[
                      'Domain',
                      'Common Keyword',
                      'Total Keyword',
                      'Relevance',
                      'Points'
                    ]}
                    filename={`${domain} - ${source} - Competitor Analysis`}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <AdvanceTable
            table
            headerClassName="bg-aida-table-header text-900 text-nowrap align-middle"
            rowClassName="align-middle white-space-nowrap"
            tableProps={{
              className: 'fs--1 mb-0 overflow-hidden'
            }}
            apiCallInProcess={apiCallInProcess}
          />
          <AdvanceTableFooter
            table
            className="mt-3 mx-3"
            rowCount={data.length}
            rowInfo
            navButtons
            rowsPerPageSelection
          />
        </AdvanceTableWrapper>
      </Card.Body>
    </Card>
  );
};

export default CompetitorsTable;
