import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import FalconCardHeader from 'components/common/FalconCardHeader';
import Flex from 'components/common/Flex';
import IconButton from 'components/common/IconButton';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import CallApi from 'components/common/custom-components/CallApi';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import React, { useContext } from 'react';
import { Card, Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useMutation, useQuery } from 'react-query';
import { AdsAccountContext } from '../AdsAccountContext';
import AddSiteLinkAd from './AddSiteLinkAd';

const SiteLinksTable = () => {
  const adsAccountContext = useContext(AdsAccountContext);
  const { currentAccount } = adsAccountContext;

  const { data, refetch, isLoading } = useQuery(
    'sitelinks',
    () => CallApi.get(`/account/${currentAccount?.id}/sitelinks`, false),
    {
      enabled: !!currentAccount?.id
    }
  );

  const DeleteSitelinkAdMutation = useMutation('delete-sitelink', id =>
    CallApi.delete(`/sitelink/${id}`)
  );

  const columns = [
    {
      accessor: 'selected',
      Header: '',
      Cell: ({ cell }) => {
        const ToggleSelectionMutation = useMutation(
          ['select-sitelink'],
          () =>
            CallApi.put(`/sitelinks/${cell.row.original?.id}/toggle-selected`),
          {
            onSuccess: json => {
              if (json?.status == 200) {
                refetch();
              }
            }
          }
        );

        dayjs.extend(localizedFormat);

        const selectedAt = dayjs(cell.row.original?.selected_at);

        return ToggleSelectionMutation.isLoading ? (
          <FontAwesomeIcon icon="spinner" spin />
        ) : (
          <Form.Check
            type="checkbox"
            className={classNames(
              'form-check cursor-pointer fs-0 mb-0 d-flex align-items-center'
            )}
          >
            {cell.value ? (
              <OverlayTrigger
                key={cell.value}
                placement="top"
                overlay={
                  <Tooltip>Selected at: {selectedAt.format('llll')}</Tooltip>
                }
              >
                <Form.Check.Input
                  onChange={() => ToggleSelectionMutation.mutate()}
                  type="checkbox"
                  checked={cell.value}
                />
              </OverlayTrigger>
            ) : (
              <Form.Check.Input
                onChange={() => ToggleSelectionMutation.mutate()}
                type="checkbox"
                checked={cell.value}
              />
            )}
          </Form.Check>
        );
      }
    },
    {
      accessor: 'link_url',
      Header: 'Ads',
      Cell: ({ cell }) => (
        <>
          <h6 className="text-bold">{cell.row.original?.title}</h6>
          <span>{cell.row.original?.description1}</span>
          <br />
          <span>{cell.row.original?.description2}</span>
          <br />
          <a href={cell.value}>{cell.value}</a>
        </>
      )
    },
    {
      accessor: 'id',
      Header: 'Actions',
      Cell: ({ cell }) => (
        <IconButton
          icon={'trash-alt'}
          onClick={() =>
            DeleteSitelinkAdMutation.mutate(cell.value, {
              onSuccess: json => refetch()
            })
          }
          variant="danger"
          size="sm"
        />
      )
    }
  ];

  return (
    <Card>
      <Card.Body className="m-3">
        <h5 className="border-bottom border-2 border-dark pb-3 mb-4">
          Sitelinks
        </h5>
        <Flex className="mb-3" justifyContent={'between'} alignItems={'center'}>
          <h5>Texts</h5>
          <AddSiteLinkAd table refetchAds={refetch} />
        </Flex>
        <AdvanceTableWrapper
          columns={columns}
          data={data?.data || []}
          sortable
          pagination
        >
          <AdvanceTable
            table
            headerClassName="bg-aida-table-header text-900 text-nowrap align-middle"
            rowClassName="align-middle white-space-nowrap"
            tableProps={{
              bordered: true,
              striped: true,
              className: 'fs--1 mb-0 overflow-hidden'
            }}
            apiCallInProcess={isLoading}
          />
        </AdvanceTableWrapper>
      </Card.Body>
    </Card>
  );
};

export default SiteLinksTable;
