import { faHome } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ManHole500 } from 'assets/img/aida-auth/images';
import AidaErrorLayout from 'layouts/AidaErrorLayout';
import React from 'react';
import { Link } from 'react-router-dom';

const Error500 = () => (
  <AidaErrorLayout
    imgStyle={{ backgroundPositionX: 'center' }}
    backgroundImage={ManHole500}
  >
    <div className="text-center p-5 h-100">
      <div className="display-1 text-300 fs-error">500</div>
      <p className="lead mt-4 text-800 font-ibm-plex-sans fw-semi-bold">
        Sorry! Something went oops.
      </p>
      <hr />
      <p>
        Try refreshing the page, or going back and attempting the action again.
        If this problem persists,
        <a href="mailto:support@digipartner.se" className="ms-1">
          contact us
        </a>
        .
      </p>
      <Link className="btn btn-primary rounded-pill mt-3" to="/">
        <FontAwesomeIcon icon={faHome} className="me-2" />
        Take me home
      </Link>
    </div>
  </AidaErrorLayout>
);

export default Error500;
