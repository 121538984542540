import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CallApi from 'components/common/custom-components/CallApi';
import React, { useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';

const LoginDetails = ({ userDetails }) => {
  const defaultValues = {
    email: '',
    password: '',
    password_confirmation: ''
  };

  const [errors, setErrors] = useState({});
  const { handleSubmit, control, register, watch, setValue, reset } = useForm({
    defaultValues: defaultValues
  });

  useEffect(() => {
    if (userDetails) reset(userDetails);

    return () => {};
  }, [userDetails]);

  const UpdateDetails = useMutation(
    form => CallApi.patch('/me/details/auth', new FormData(form.target), true),
    {
      onSuccess: response => {
        if (response.errors) {
          setErrors(response.errors);
        } else {
          setValue('password', null);
          setValue('password_confirmation', null);
        }
      }
    }
  );

  const onSubmit = (data, form) => {
    UpdateDetails.mutate(form);
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Form.Group className="mb-3" controlId="newPassword">
        <Form.Label>E-mail</Form.Label>
        <Form.Control type="text" {...register('email', { required: true })} />
      </Form.Group>
      <Form.Group className="mb-3" controlId="confirmPassword">
        <Form.Label>New Password</Form.Label>
        <Form.Control
          type="text"
          placeholder="Leave blank if you don't want to change password"
          {...register('password')}
        />
      </Form.Group>
      <Form.Group className="mb-3" controlId="confirmPassword">
        <Form.Label>Confirm New Password</Form.Label>
        <Form.Control
          type="text"
          placeholder="Leave blank if you don't want to change password"
          {...register('password_confirmation')}
        />
      </Form.Group>
      <div className="text-end">
        <Button variant="primary" type="submit">
          {UpdateDetails.isLoading ? (
            <>
              <FontAwesomeIcon icon="spinner" className="me-2" spin />
              Updating...
            </>
          ) : (
            'Update'
          )}
        </Button>
      </div>
    </Form>
  );
};

export default LoginDetails;
