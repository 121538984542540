import classNames from 'classnames';
import CallApi from 'components/common/custom-components/CallApi';
import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

const Footer = () => {
  const [version, setVersion] = useState('');
  const history = useHistory();

  useQuery(
    ['application-version'],
    () => CallApi.get(`/dashboard/application-version`, false),
    {
      onSuccess: json => {
        if (json?.status == 200) {
          setVersion(json.data);
        }

        if (json?.status == 401) {
          history.push('/app/login');
        }
      }
    }
  );

  return (
    <footer className="footer">
      <Row className="justify-content-between sticky-footer text-center fs--1">
        <Col sm="auto">
          <p
            className={classNames('mb-0 text-600', {
              // 'text-white': !aidaAppContext.is_background_color_too_dark,
              // 'text-dark': aidaAppContext.is_background_color_too_dark
            })}
          >
            Copyright © {new Date().getFullYear()}{' '}
            <Link
              to={{
                pathname: `https://digipartner.se`
              }}
              target="_blank"
            >
              Digipartner AB.
            </Link>{' '}
            All rights reserved.
          </p>
        </Col>
        <Col sm="auto">
          <p className="mb-0 text-600">{version}</p>
        </Col>
      </Row>
    </footer>
  );
};

export default Footer;
