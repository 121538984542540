import LocalStoragePageInfo from 'components/common/custom-components/LocalStoragePageInfo';
import React, { createContext, useContext } from 'react';
import { useState } from 'react';
import MainTable from './components/MainTable';

export const searchQueryKeywordsContext = createContext({
  isBulkSelectedAndUpdating: false,
  refreshListing: true,
  bulkSelectAction: '',
  adgroupChangeableKeyword: {},
  pagePermissions: { can: {} },
  setSearchQueryContext: context => {}
});

function SearchQueryKeywords() {
  const [searchQueryContext, setSearchQueryContext] = useState(
    useContext(searchQueryKeywordsContext)
  );

  return (
    <searchQueryKeywordsContext.Provider
      value={{ searchQueryContext, setSearchQueryContext }}
    >
      <LocalStoragePageInfo slug="search_query_keywords" />
      <MainTable />
    </searchQueryKeywordsContext.Provider>
  );
}

export default SearchQueryKeywords;
