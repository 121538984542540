import React, { useContext } from 'react';
import { AdsAccountContext } from './AdsAccountContext';
import { useLocation } from 'react-router-dom';
import { Alert } from 'react-bootstrap';

const AccountStatusNotice = () => {
  const adsAccountContext = useContext(AdsAccountContext);
  const { currentAccount } = adsAccountContext;

  return currentAccount?.status == 'need approval' ? (
    <div className=" bg-pink text-light fw-bold py-2 alert text-center">
      Note: This account setup requires your approval
    </div>
  ) : null;
};

export default AccountStatusNotice;
