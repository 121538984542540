import { useContext, useEffect } from 'react';
import { SeoWizardContext } from './Context';

const SeoWizardForm = () => {
  const seoWizardContext = useContext(SeoWizardContext);
  const { currentStep } = seoWizardContext;

  useEffect(() => {
    return () => {
      seoWizardContext.resetContext();
    };
  }, []);

  return currentStep.component;
};

export default SeoWizardForm;
