import LocalStoragePageInfo from 'components/common/custom-components/LocalStoragePageInfo';
import React from 'react';
import Listing from './components/Listing';

const SeoProjects = () => {
  return (
    <>
      <LocalStoragePageInfo slug="seo-projects" />
      <Listing />
    </>
  );
};

export default SeoProjects;
