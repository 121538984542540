import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getItemFromStore, setItemToStore } from 'helpers/utils';
import React, { useEffect, useState } from 'react';
import {
  Card,
  Col,
  Collapse,
  Image,
  OverlayTrigger,
  Row,
  Tooltip
} from 'react-bootstrap';
import Flex from '../Flex';
import CallApi from './CallApi';
import { useQuery } from 'react-query';

const LocalStoragePageInfo = ({ slug = '', children = null, ...rest }) => {
  const defaultPage = {
    name: 'Unnamed Page',
    description: ``,
    image: ''
  };

  const [frontendPages, setFrontendPages] = useState(
    getItemFromStore('frontend_pages', [])
  );
  const [frontendPage, setFrontendPage] = useState(defaultPage);
  const [tooltipContent, setTooltipContent] = useState(<>Click to copy slug</>);
  const [showDetails, setShowDetails] = useState(false);

  useQuery(['frontend-pages'], () => CallApi.get('/frontend-pages', false), {
    onSuccess: json => {
      setItemToStore('frontend_pages', json?.data || []);
      setFrontendPages(json?.data);
    },
    enabled: frontendPages?.length == 0
  });

  useEffect(() => {
    const page = frontendPages?.find(
      frontendPage => frontendPage?.slug == slug
    );

    if (page) {
      setFrontendPage(page);
      document.title =
        'Trakk.ai | ' +
        (page?.page_title || page?.name || 'Personal seo service');
    } else {
      setFrontendPage(defaultPage);
      document.title = 'Trakk.ai | Personal seo service';
    }

    return () => {
      document.title = 'Trakk.ai | Personal seo service';
    };
  }, [slug, frontendPages]);

  return (
    <Card className="mb-5" {...rest}>
      <Card.Body
        title={
          children
            ? frontendPage.name == 'Unnamed Page'
              ? `Double click to copy '${slug}' slug.`
              : ''
            : ''
        }
        onDoubleClick={() => {
          if (frontendPage.name == 'Unnamed Page')
            navigator.clipboard.writeText(slug);
        }}
      >
        {children ? (
          children
        ) : (
          <>
            <Row>
              <Col>
                <Flex
                  className="h-100 px-3 py-2"
                  justifyContent="between"
                  direction="column"
                >
                  <div>
                    <h3 className="mb-0 font-ibm-plex-serif">
                      {frontendPage.name}
                    </h3>
                    <p
                      className="mt-2 text-dark"
                      onMouseLeave={() =>
                        setTooltipContent(<>Click to copy slug</>)
                      }
                    >
                      {frontendPage.description || (
                        <>
                          To display correct name, add
                          <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip>{tooltipContent}</Tooltip>}
                          >
                            <code
                              onClick={() => {
                                navigator.clipboard.writeText(slug);
                                setTooltipContent(
                                  <>
                                    <FontAwesomeIcon
                                      className="text-success"
                                      icon={'check'}
                                    />{' '}
                                    Copied
                                  </>
                                );
                              }}
                              className="bg-light mx-1 py-1 px-2 rounded-pill cursor-pointer"
                            >
                              {slug}
                            </code>
                          </OverlayTrigger>
                          in <samp className="mx-1">frontend_pages</samp> table
                        </>
                      )}
                    </p>
                  </div>
                </Flex>
              </Col>
              {frontendPage.image ? (
                <Col lg={4} xxl={3}>
                  <Image src={frontendPage.image} className="w-100" />
                </Col>
              ) : null}
            </Row>
            {frontendPage?.details ? (
              <Row>
                <Col>
                  <Collapse in={showDetails}>
                    <div
                      className="px-3"
                      dangerouslySetInnerHTML={{
                        __html: frontendPage.details
                      }}
                    ></div>
                  </Collapse>
                  <div className="text-end pe-3">
                    <span
                      onClick={() => setShowDetails(!showDetails)}
                      className="btn-link cursor-pointer"
                    >
                      {showDetails ? 'Hide Details' : 'View Details'}
                    </span>
                  </div>
                </Col>
              </Row>
            ) : null}
          </>
        )}
      </Card.Body>
    </Card>
  );
};

export default LocalStoragePageInfo;
