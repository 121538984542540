import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import ManualAddWebsiteLinkModal from 'components/aida/accounts/wizard-sub-forms/ManualAddWebsiteLinkModal';
import IconButton from 'components/common/IconButton';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import React, { useContext, useState, useRef } from 'react';
import {
  Button,
  Col,
  Modal,
  OverlayTrigger,
  Row,
  Tooltip
} from 'react-bootstrap';
import { SeoWizardContext } from './Context';
import CallApi from 'components/common/custom-components/CallApi';
import { useQuery } from 'react-query';

const MatchKeywordsToUrlModal = ({
  addLater,
  keyword,
  setKeywords,
  keywords,
  updateUrl = url => {},
  competitorDomains,
  animate,
  show,
  setShow
}) => {
  const seoWizardContext = useContext(SeoWizardContext);
  const { currentSeoProject, setSeoWizardContext } = seoWizardContext;

  const handleClose = () => {
    setShow(false);
  };

  const DataForSeoLinksQuery = useQuery(
    ['top-10-data-for-seo-links', currentSeoProject?.id, keyword],
    () =>
      CallApi.get(
        `/seo-project-drafts/${currentSeoProject?.id}/top-10-urls?keyword=${keyword}`,
        false,
        {},
        false
      ),
    {
      onSuccess: json => {
        const top10urls = json?.data || [];

        setSeoWizardContext({
          ...seoWizardContext,
          matchKeywordUrlScreen: {
            ...seoWizardContext.matchKeywordUrlScreen,
            top10urls: {
              ...seoWizardContext.matchKeywordUrlScreen.top10urls,
              [keyword]: top10urls
            }
          }
        });
      },
      placeholderData: {
        data: []
      },
      enabled: !Object.keys(
        seoWizardContext.matchKeywordUrlScreen.top10urls
      ).includes(keyword)
    }
  );

  const columns = [
    {
      id: 'choose_link',
      Header: 'Choose',
      Cell: ({ cell }) => {
        const matched = keywords
          ?.map(keyword => keyword.url)
          ?.includes(cell.row.original?.link);

        return (
          <Button
            variant={matched ? 'outline-success' : 'outline-primary'}
            className="rounded-pill"
            size="sm"
            onClick={() => {
              if (!matched) {
                const newKeywords = [...keywords].map(kwrd =>
                  kwrd.keyword == keyword
                    ? { ...kwrd, url: cell.row.original?.link }
                    : kwrd
                );
                setKeywords(newKeywords);
                updateUrl(cell.row.original?.link);
                setShow(false);
              }
            }}
          >
            {matched ? (
              <OverlayTrigger
                placement="bottom"
                overlay={
                  <Tooltip>Same URL canot be matched with two keywords</Tooltip>
                }
              >
                <span>Matched</span>
              </OverlayTrigger>
            ) : (
              'Choose'
            )}
          </Button>
        );
      }
    },
    {
      accessor: 'link',
      Header: 'Page link',
      Cell: ({ cell }) => {
        return (
          <a
            href={
              cell.value?.includes('http')
                ? cell.value
                : `https://${cell.value}`
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={'external-link-alt'} /> {cell.value}{' '}
          </a>
        );
      }
    },
    {
      accessor: 'connected_to',
      Header: 'Connected to',
      Cell: ({ cell }) =>
        keywords.find(keyword => keyword.url == cell.row.original.link)
          ?.keyword || ''
    }
  ];

  const getLinks = () => {
    const top10urls = Object.keys(
      seoWizardContext.matchKeywordUrlScreen.top10urls
    ).includes(keyword)
      ? seoWizardContext.matchKeywordUrlScreen.top10urls[keyword]
      : [];

    const links = new Set(top10urls.map(d => d.link));
    const merged = [
      ...top10urls,
      ...competitorDomains.filter(d => !links.has(d.link))
    ];
    return merged;
  };

  let initialLinks = show ? getLinks() : [];
  const [websiteLinks, setWebsiteLinks] = useState(initialLinks);

  return (
    <>
      {DataForSeoLinksQuery?.isFetching ? (
        <span className={`ms-2 cursor-pointer`}>
          <FontAwesomeIcon icon="spinner" className={`me-2`} spin /> Loading...
        </span>
      ) : (
        <IconButton
          variant={addLater ? 'secondary' : 'primary'}
          disabled={DataForSeoLinksQuery.isFetching || addLater}
          onClick={() => {
            setShow(true);
          }}
          icon={'paperclip'}
          size="sm"
        />
      )}
      <Modal
        show={show}
        size="lg"
        centered
        onHide={handleClose}
        animation={animate}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Choose url for keyword: <b>{keyword}</b>
            {DataForSeoLinksQuery?.isFetching ? (
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip>
                    Please wait, now we are fetching your website's top 10
                    pages...
                  </Tooltip>
                }
              >
                <span className={`ms-2 cursor-pointer`}>
                  <FontAwesomeIcon icon="spinner" spin />
                </span>
              </OverlayTrigger>
            ) : null}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-3">
          <AdvanceTableWrapper
            columns={columns}
            data={websiteLinks || []}
            perPage={10}
            sortable
            pagination
          >
            <Row className="align-items-center mb-3">
              <Col xs="auto">
                <AdvanceTableSearchBox table />
              </Col>
              <Col />
              <Col xs="auto">
                <ManualAddWebsiteLinkModal
                  setWebsiteLinks={setWebsiteLinks}
                  websiteDomain={currentSeoProject?.main_information?.domain}
                  websiteLinks={websiteLinks}
                  table
                />
              </Col>
            </Row>
            <AdvanceTable
              table
              headerClassName="bg-aida-table-header text-900 text-nowrap align-middle"
              rowClassName="align-middle"
              tableProps={{
                className: 'fs--1 mb-0 overflow-hidden'
              }}
              rowProps={row => {
                return {
                  className: classNames({
                    'bg-soft-success': row.isSelected
                  })
                };
              }}
            />
            <AdvanceTableFooter
              table
              className="mt-3 mx-3"
              rowCount={websiteLinks?.length}
              rowInfo
              navButtons
              rowsPerPageSelection
              rowsPerPageOptions={[10, 20, 30, 50, 100]}
            />
          </AdvanceTableWrapper>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default MatchKeywordsToUrlModal;
