import React from 'react';
import { Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import AidaErrorLayout from 'layouts/AidaErrorLayout';
import { Hug404 } from 'assets/img/aida-auth/images';

const Error404 = () => {
  return (
    <AidaErrorLayout
      imgStyle={{ backgroundPositionX: 'center' }}
      backgroundImage={Hug404}
    >
      <div className="p-5 text-center">
        <div className="display-1 text-300 fs-error">404</div>
        <p className="lead mt-4 text-800 font-ibm-plex-sans fw-semi-bold">
          Sorry! Something <br /> went oops.
        </p>
        <p>
          Make sure you typed the correct address.
          <br /> if this is on us, we apologize!.
        </p>
        <Link className="btn btn-primary rounded-pill mt-3" to="/">
          <FontAwesomeIcon icon={faHome} className="me-2" />
          Take me home
        </Link>
      </div>
    </AidaErrorLayout>
  );
};

export default Error404;
