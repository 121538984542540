import React from 'react';
import PasswordResetForm from 'components/authentication/PasswordResetForm';
import AuthAidaLayout from 'layouts/AuthAidaLayout';
import { KeyBackground } from 'assets/img/aida-auth/images';

const PasswordReset = () => {
  return (
    <AuthAidaLayout backgroundImage={KeyBackground}>
      <h3 className="font-ibm-plex-sans">Reset password</h3>
      <PasswordResetForm layout="app" hasLabel={true} />
    </AuthAidaLayout>
  );
};

export default PasswordReset;
