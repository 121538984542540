import AuthCardRoutes from 'components/authentication/card/AuthCardRoutes';
import AuthSplitRoutes from 'components/authentication/split/AuthSplitRoutes';
import WizardAuth from 'components/authentication/wizard/WizardAuth';
import Landing from 'components/pages/landing/Landing';
import is from 'is_js';
import React, { useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import AuthSimpleLayout from './AuthSimpleLayout';
import MainLayout from './MainLayout';
import AidaCardRoutes from 'components/authentication/aida/AidaCardRoutes';
import AidaErrorRoutes from 'components/errors/AidaErrorRoutes';
import VerifyProject from 'components/aida/seo-projects/VerifyProject';

const Layout = () => {
  const HTMLClassList = document.getElementsByTagName('html')[0].classList;

  useEffect(() => {
    if (is.windows()) {
      HTMLClassList.add('windows');
    }
    if (is.chrome()) {
      HTMLClassList.add('chrome');
    }
    if (is.firefox()) {
      HTMLClassList.add('firefox');
    }
  }, [HTMLClassList]);

  return (
    <>
      <Switch>
        <Route path="/landing" component={Landing} />
        <Route path="/errors" component={AidaErrorRoutes} />
        <Route path="/authentication/simple" component={AuthSimpleLayout} />
        <Route path="/authentication/card" component={AuthCardRoutes} />
        <Route path="/authentication/split" component={AuthSplitRoutes} />
        <Route path="/authentication/wizard" component={WizardAuth} />
        <Route path="/app" component={AidaCardRoutes} />
        <Route path="/verify-seo-project" exact component={VerifyProject} />
        <Route component={MainLayout} />
        <Redirect to="/login" />
      </Switch>
      <ToastContainer></ToastContainer>
    </>
  );
};

export default Layout;
