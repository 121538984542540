import { faKeyboard } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useState } from 'react';
import { Button, Row, Col, Nav, Card } from 'react-bootstrap';
import { SeoWizardContext } from './Context';
import MainInformationForm from './MainInformationForm';
import SelectCompetitorsForm from './SelectCompetitors';
import SelectKeywords from './SelectKeywords';
import MatchKeywordsToUrl from './MatchKeywordsToUrl';
import FinishScreen from './FinishScreen';
import { AppSettings } from 'settings';

export const steps = [
  {
    step: 'main_information',
    name: 'Main Information',
    icon: 'home',
    component: (
      <Card>
        <Card.Body className="m-3">
          <MainInformationForm />
        </Card.Body>
      </Card>
    )
  },
  {
    step: 'competitors',
    name: 'Select competitors',
    icon: 'users',
    component: (
      <Card>
        <Card.Body className="m-3">
          <SelectCompetitorsForm />
        </Card.Body>
      </Card>
    )
  },
  {
    step: 'keywords',
    name: 'Select Keywords',
    icon: faKeyboard,
    component: <SelectKeywords />
  },
  {
    step: 'link_keywords_to_url',
    name: 'Match your keywords to URLs',
    icon: 'link',
    component: (
      <Card>
        <Card.Body className="m-3">
          <MatchKeywordsToUrl />
        </Card.Body>
      </Card>
    )
  },
  {
    step: 'finish',
    name: 'Finish',
    icon: 'check',
    component: (
      <Card>
        <Card.Body className="m-3">
          <FinishScreen />
        </Card.Body>
      </Card>
    )
  }
];

const Sidebar = () => {
  const seoProjectContext = useContext(SeoWizardContext);
  const { currentStep, currentSeoProject, setSeoWizardContext } =
    seoProjectContext;

  const SidebarItem = (step, i) => (
    <React.Fragment key={i}>
      <Button
        className={`text-start my-1  ${
          currentStep?.step === step.step
            ? 'btn-light '
            : 'btn-outline-light bg-transparent text-dark shadow-none border-0'
        }`}
        onClick={() => {
          if (AppSettings.apiUrl?.includes('.test')) {
            setSeoWizardContext({ ...seoProjectContext, currentStep: step });
          }
        }}
      >
        <Row>
          <Col xs={1}>
            <FontAwesomeIcon icon={step.icon} />
          </Col>
          <Col>{step.name}</Col>
        </Row>
      </Button>
      {step == 'Basic' ? <hr className="text-dark my-2" /> : null}
    </React.Fragment>
  );
  return (
    <div className="me-5" style={{ minWidth: '20%' }}>
      <h5 className="text-dark mb-3">
        {currentSeoProject?.id ? 'Updating' : 'Creating'} Seo Project Draft
      </h5>
      <Nav className="flex-column d-grid gap-2 ">{steps.map(SidebarItem)}</Nav>
    </div>
  );
};

export default Sidebar;
