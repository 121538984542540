import { faFirstdraft } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CallApi from 'components/common/custom-components/CallApi';
import IconButton from 'components/common/IconButton';
import React, { useContext, useState } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { SeoWizardContext } from './Context';

const SaveAsDraftWithNoteModal = ({ dataToBeDrafted }) => {
  const [showDraftNote, setShowDraftNote] = useState(false);
  const [toBeDraftedFormData, setToBeDraftedFormData] = useState(null);
  const seoProjectContext = useContext(SeoWizardContext);
  const { currentStep, currentSeoProject, lastStepAsDrafted } =
    seoProjectContext;

  const { setValue, register, handleSubmit } = useForm();

  const toggleDraftNote = () => {
    if (!showDraftNote && currentSeoProject?.id) {
      setValue('note', currentSeoProject?.note);
    }

    setShowDraftNote(!showDraftNote);
  };

  const DraftWithNoteMutation = useMutation(
    'draft-seo-project-with-note',
    formData =>
      currentSeoProject?.id
        ? CallApi.put(`/seo-project-drafts/${currentSeoProject?.id}`, formData)
        : CallApi.post(`/seo-project-drafts`, formData)
  );

  const onDraftFormSubmit = (data, form) => {
    let formData = new FormData(form.target);

    formData.append(currentStep.step, JSON.stringify(toBeDraftedFormData));

    DraftWithNoteMutation.mutate(formData, {
      onSuccess: json => {
        if (json?.status == 200) {
          lastStepAsDrafted({
            currentSeoProject: json?.data
          });
        }
      }
    });
  };

  return (
    <>
      <Button
        variant="outline-primary"
        className="rounded-pill ms-2"
        type="button"
        onClick={() => {
          toggleDraftNote();
          setToBeDraftedFormData(
            typeof dataToBeDrafted === 'function'
              ? dataToBeDrafted()
              : dataToBeDrafted
          );
        }}
      >
        <FontAwesomeIcon icon={faFirstdraft} /> Save as draft
      </Button>
      <Modal show={showDraftNote} centered onHide={toggleDraftNote}>
        <Form
          id="draft-with-note-form"
          onSubmit={handleSubmit(onDraftFormSubmit)}
        >
          <Modal.Header closeButton>
            <Modal.Title>Write a note to your draft</Modal.Title>
          </Modal.Header>
          <Modal.Body className="px-3">
            <Form.Group className="mb-3">
              <Form.Label>Note</Form.Label>
              <Form.Control
                as="textarea"
                rows={5}
                autoFocus
                {...register('note', {
                  required: true
                })}
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <IconButton
              icon="times-circle"
              size="sm"
              onClick={toggleDraftNote}
              variant="falcon-light"
            >
              Cancel
            </IconButton>
            <IconButton
              variant="outline-primary"
              className="rounded-pill"
              form="draft-with-note-form"
              type="submit"
              icon="save"
              size="sm"
            >
              Save
            </IconButton>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default SaveAsDraftWithNoteModal;
