import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useRegionSelectBox from 'components/aida/custom-form-hooks/useRegionSelectBox';
import FalconCardHeader from 'components/common/FalconCardHeader';
import CallApi from 'components/common/custom-components/CallApi';
import React, { useContext, useState } from 'react';
import { Card, Form, Table } from 'react-bootstrap';
import { useMutation } from 'react-query';
import { AdsAccountContext } from '../../AdsAccountContext';

const YourRegions = () => {
  const adsAccountContext = useContext(AdsAccountContext);
  const { currentAccount, refetchCurrentAccount } = adsAccountContext;
  const [selectedRegions, setSelectedRegions] = useState(
    currentAccount?.selected_regions
      ?.replaceAll('"', '')
      ?.replaceAll('\\/', '/')
      ?.split(',') || []
  );
  const RegionSelectBox = useRegionSelectBox();
  const updateUrl = `/account/${currentAccount?.id}/regions`;
  const UpdateRegionsMutation = useMutation(
    ['account-regions', currentAccount?.id],
    regions => {
      let formData = new FormData();
      formData.append('selected_regions', regions.join(','));
      return CallApi.post(updateUrl, formData);
    },
    {
      onSuccess: json => {
        refetchCurrentAccount();
      }
    }
  );
  const handleSelectChange = region => {
    const isRegionAlreadyAdded = selectedRegions.some(
      selectedRegion => selectedRegion === region.name
    );

    if (!isRegionAlreadyAdded) {
      const newRegions = [...selectedRegions, region.name];
      setSelectedRegions(newRegions);
      UpdateRegionsMutation.mutate(newRegions);
    }
  };

  const removeAndUpdateRegions = item => {
    const newRegions = selectedRegions?.filter(region => region != item);
    UpdateRegionsMutation.mutate(newRegions, {
      onSuccess: json => {
        if (json?.status == 200) setSelectedRegions(newRegions);
      }
    });
  };

  return (
    <Card>
      <Card.Body className="m-3">
        <h5 className="border-bottom border-2 border-dark pb-3 mb-4">
          Your Region
        </h5>
        <Form.Group className="mb-3">
          <Form.Label>Add new region</Form.Label>
          <RegionSelectBox onChange={handleSelectChange} />
        </Form.Group>
        <div className="mt-4">
          <Table className="fs--1 mb-0 text-left overflow-hidden">
            <thead className="bg-aida-table-header exclude-last-row text-900 text-nowrap align-middle">
              <tr>
                <th>Selected regions</th>
                <th className="text-end">Remove</th>
              </tr>
            </thead>
            <tbody>
              {selectedRegions.map((item, id) => (
                <tr className="align-middle white-space-nowrap" key={id}>
                  <td>{item}</td>
                  <td className="text-end">
                    <FontAwesomeIcon
                      onClick={() =>
                        confirm('Do you really want to remove this region?')
                          ? removeAndUpdateRegions(item)
                          : console.log('removing cancelled.')
                      }
                      icon="trash-alt"
                      className="text-dark cursor-pointer"
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </Card.Body>
    </Card>
  );
};

export default YourRegions;
