import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMutation } from 'react-query';
import CallApi from 'components/common/custom-components/CallApi';
import IconButton from 'components/common/IconButton';
import { faMailBulk } from '@fortawesome/free-solid-svg-icons';
import LocalStorageTooltip from 'components/common/custom-components/LocalStorageTooltip';

const SendMonthlyReportModal = ({ seoProject, seoProjectAction }) => {
  const [showDetailsModal, setShowDetailsModal] = useState(false);

  const toggleDetailsModal = () => setShowDetailsModal(!showDetailsModal);

  const SendMailMutation = useMutation(
    ['send-monthly-report', seoProjectAction?.id],
    () =>
      CallApi.post(
        `/seo-project-actions/${seoProjectAction?.id}/send-monthly-report-email`
      ),
    {
      onSuccess: json => {
        toggleDetailsModal();
      }
    }
  );

  return (
    <>
      <span onClick={toggleDetailsModal} className={`cursor-pointer`}>
        <LocalStorageTooltip
          identifier={'send-report-via-email-button'}
          customIcon={
            <FontAwesomeIcon
              className="text-dark ms-2"
              icon={SendMailMutation.isLoading ? 'spinner' : 'paper-plane'}
              spin={SendMailMutation.isLoading}
            />
          }
        />
      </span>
      <Modal
        show={showDetailsModal}
        style={{
          backgroundColor: 'rgba(3, 0, 0, 0.5)'
        }}
        centered
        onHide={toggleDetailsModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>Send monthly report e-mail</Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-3">
          <p>
            <FontAwesomeIcon icon={'envelope'} /> Main E-mail:{' '}
            <b>{seoProject?.client_main_email}</b>
          </p>

          <p className="mb-1">
            <FontAwesomeIcon icon={faMailBulk} /> CC E-mails:{' '}
          </p>
          <ul>
            {seoProject?.seo_project_client_emails?.map(email => (
              <li key={email?.email}>{email?.email}</li>
            ))}
            <li
              className={
                seoProject?.managing_user_receives_monthly_report
                  ? ''
                  : 'text-decoration-line-through'
              }
            >
              {seoProject?.managing_user?.email}{' '}
              <OverlayTrigger
                overlay={
                  <Tooltip>
                    This is contact person's email
                    {seoProject?.managing_user_receives_monthly_report
                      ? ''
                      : ', but he will not receive monthly report'}
                  </Tooltip>
                }
              >
                <span className="cursor-pointer">
                  <FontAwesomeIcon icon="user" />
                </span>
              </OverlayTrigger>
            </li>
          </ul>
        </Modal.Body>
        <Modal.Footer>
          <IconButton
            variant="outline-primary"
            onClick={() => {
              SendMailMutation.mutate();
            }}
            className="rounded-pill"
            type="button"
            disabled={SendMailMutation.isLoading}
            icon={SendMailMutation.isLoading ? 'spinner' : 'paper-plane'}
            spin={SendMailMutation.isLoading}
            size="sm"
          >
            {SendMailMutation.isLoading ? 'Sending mail...' : 'Send mail'}
          </IconButton>
        </Modal.Footer>
      </Modal>
    </>
  );
};

SendMonthlyReportModal.propTypes = {
  actionId: PropTypes.number
};

export default SendMonthlyReportModal;
