import React from 'react';

const ReactSelectBoxImageLabel = ({ src, title, height = 20, width = 20 }) => {
  return (
    <div className="d-flex align-items-center text-dark">
      <img className="rounded me-1" src={src} width={width} height={height} />
      {title}
    </div>
  );
};

export default ReactSelectBoxImageLabel;
