import CallApi from 'components/common/custom-components/CallApi';
import IconButton from 'components/common/IconButton';
import React, { useContext, useState } from 'react';
import { Form, Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { AdsAccountContext } from '../AdsAccountContext';

const AddSiteLinkAd = ({ refetchAds = () => {} }) => {
  const adsAccountContext = useContext(AdsAccountContext);
  const { currentAccount } = adsAccountContext;
  const [show, setShow] = useState(false);
  const defaultErrors = {
    link_url: [],
    title: [],
    description1: [],
    description2: []
  };
  const [errors, setErrors] = useState(defaultErrors);
  const handleClose = () => {
    setShow(false);
    setErrors(defaultErrors);
    reset();
  };

  const CreateSitelinkMutation = useMutation(
    'create-sitelink-mutation',
    formData => CallApi.post(`/sitelink`, formData),
    {
      onSuccess: json => {
        if (json.status == 200) {
          refetchAds();
          handleClose();
        }
        if (json.status == 422) {
          setErrors(json.errors);
        }
      }
    }
  );

  const { handleSubmit, register, watch, setValue, reset } = useForm();

  const onSubmit = (data, form) => {
    let formData = new FormData(form.target);

    if (
      !(
        data?.link_url?.includes('http://') ||
        data?.link_url?.includes('https://')
      )
    ) {
      const fullLink = `https://${data.link_url}`;

      setValue('link_url', fullLink);
      formData.set('link_url', fullLink);
    }

    CreateSitelinkMutation.mutate(formData);
  };

  const titleIsMoreThenAllowed = watch('title')?.length > 25;
  const description1IsMoreThenAllowed = watch('description1')?.length > 35;
  const description2IsMoreThenAllowed = watch('description2')?.length > 35;

  const CounterText = ({ currentCount, limit = 35, limitCheck }) => (
    <div className="text-end mt-1">
      <span className={limitCheck ? 'text-danger' : ''}>
        {currentCount}/{limit}
      </span>
    </div>
  );

  return (
    <>
      <IconButton
        icon={'plus'}
        className="rounded-pill"
        variant="outline-primary"
        size="sm"
        onClick={() => setShow(true)}
      >
        Add
      </IconButton>
      <Modal show={show} centered onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Sitelink</Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-3">
          <Form
            noValidate
            id="add-sitelink-form"
            onSubmit={handleSubmit(onSubmit)}
          >
            <input
              type="hidden"
              name="account_id"
              value={currentAccount?.id}
              readOnly
            />
            <Form.Group controlId="title">
              <Form.Label>Title</Form.Label>
              <Form.Control
                type="text"
                {...register('title', {
                  required: true,
                  maxLength: 25
                })}
                placeholder="Enter title"
                isInvalid={titleIsMoreThenAllowed || errors?.title?.length > 0}
              />
              <Form.Control.Feedback type="invalid">
                {errors?.title?.join(',') || ''}
              </Form.Control.Feedback>
              <CounterText
                limitCheck={titleIsMoreThenAllowed}
                limit={25}
                currentCount={watch('title')?.length || 0}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="sitelink">
              <Form.Label>Link</Form.Label>
              <Form.Control
                type="text"
                {...register('link_url', {
                  required: true
                })}
                placeholder="Enter link"
                isInvalid={errors?.link_url?.length > 0}
              />
              <Form.Control.Feedback type="invalid">
                {errors?.link_url?.join(',') || ''}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="description1">
              <Form.Label>Description 1</Form.Label>
              <Form.Control
                type="text"
                {...register('description1', {
                  required: true,
                  maxLength: 35
                })}
                placeholder="Enter first description "
                isInvalid={
                  description1IsMoreThenAllowed ||
                  errors?.description1?.length > 0
                }
              />
              <Form.Control.Feedback type="invalid">
                {errors?.description1?.join(',') || ''}
              </Form.Control.Feedback>
              <CounterText
                limitCheck={description1IsMoreThenAllowed}
                limit={35}
                currentCount={watch('description1')?.length || 0}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="description2">
              <Form.Label>Description 2</Form.Label>
              <Form.Control
                type="text"
                {...register('description2', {
                  required: true,
                  maxLength: 35
                })}
                placeholder="Enter second description"
                isInvalid={
                  description2IsMoreThenAllowed ||
                  errors?.description2?.length > 0
                }
              />
              <Form.Control.Feedback type="invalid">
                {errors?.description2?.join(',') || ''}
              </Form.Control.Feedback>
              <CounterText
                limitCheck={description2IsMoreThenAllowed}
                limit={35}
                currentCount={watch('description2')?.length || 0}
              />
            </Form.Group>{' '}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <IconButton
            icon="times-circle"
            size="sm"
            onClick={handleClose}
            variant="falcon-light"
          >
            Cancel
          </IconButton>
          <IconButton
            form="add-sitelink-form"
            type="submit"
            icon="save"
            size="sm"
          >
            Save
          </IconButton>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddSiteLinkAd;
