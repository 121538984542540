import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useCountrySelectBox from 'components/aida/custom-form-hooks/useCountrySelectBox';
import useLanguageSelectBox from 'components/aida/custom-form-hooks/useLanguageSelectBox';
import CallApi from 'components/common/custom-components/CallApi';
import FalconCardHeader from 'components/common/FalconCardHeader';
import React, { useEffect, useState } from 'react';
import { Button, Card, Form } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';

const ProfileSettings = ({ userDetails }) => {
  const [errors, setErrors] = useState({});

  const defaultValues = {
    first_name: '',
    last_name: '',
    phone: '',
    birthday: '',
    address: '',
    country_id: '',
    language_id: ''
  };

  const { handleSubmit, control, register, watch, setValue, reset } = useForm({
    defaultValues: defaultValues
  });

  const UpdateDetails = useMutation(
    formData => CallApi.patch('/me/details', formData, true),
    {
      onSuccess: response => {
        if (response.status !== 200) {
          setErrors(response.errors);
        }
      }
    }
  );

  const { CountrySelectbox } = useCountrySelectBox();
  const { LanguageSelectBox } = useLanguageSelectBox();

  useEffect(() => {
    if (userDetails) reset(userDetails);

    return () => {};
  }, [userDetails]);

  const onSubmit = (data, form) => {
    let formData = new FormData(form.target);
    UpdateDetails.mutate(formData);
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Form.Group className="mb-3" controlId="firstName">
        <Form.Label>First Name</Form.Label>
        <Form.Control
          type="text"
          placeholder="First Name"
          {...register('first_name')}
        />
      </Form.Group>

      <Form.Group className="mb-3" controlId="lastName">
        <Form.Label>Last Name</Form.Label>
        <Form.Control
          type="text"
          placeholder="Last Name"
          {...register('last_name')}
        />
      </Form.Group>
      <Form.Group className="mb-3" controlId="birthday">
        <Form.Label>Birthday</Form.Label>
        <Form.Control
          type="date"
          placeholder="birthday"
          {...register('birthday')}
        />
      </Form.Group>

      <Form.Group className="mb-3" controlId="phone">
        <Form.Label>Phone</Form.Label>
        <Form.Control type="text" placeholder="Phone" {...register('phone')} />
      </Form.Group>

      <Form.Group className="mb-3" controlId="country">
        <Form.Label>Country</Form.Label>
        <Controller
          control={control}
          name="country_id"
          render={({ field }) => (
            <CountrySelectbox
              isSearchable
              value={field.value}
              onChange={country => field.onChange(country.id)}
            />
          )}
        />
      </Form.Group>

      <Form.Group className="mb-3" controlId="phone">
        <Form.Label>Language</Form.Label>
        <Controller
          control={control}
          name="language_id"
          render={({ field }) => (
            <LanguageSelectBox
              isSearchable
              value={field.value}
              onChange={language => field.onChange(language.id)}
            />
          )}
        />
      </Form.Group>

      <div className="text-end">
        <Button variant="primary" type="submit">
          {UpdateDetails.isLoading ? (
            <>
              <FontAwesomeIcon icon="spinner" className="me-2" spin />
              Updating...
            </>
          ) : (
            'Update'
          )}
        </Button>
      </div>
    </Form>
  );
};

export default ProfileSettings;
