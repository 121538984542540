import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { EditTextsImage } from 'assets/img/accounts/images';
import Flex from 'components/common/Flex';
import LocalStoragePageInfo from 'components/common/custom-components/LocalStoragePageInfo';
import React, { useState } from 'react';
import { Button, Col, Nav, Row } from 'react-bootstrap';
import Lists from './edit-texts/Lists';
import PhoneNumber from './edit-texts/PhoneNumber';
import SiteLinks from './edit-texts/SiteLinks';
import TextAds from './edit-texts/TextAds';

const EditTexts = () => {
  const ads = [
    {
      name: 'Text ads',
      description: ``,
      component: <TextAds />
    },
    {
      name: 'Sitelinks',
      description: '',
      component: <SiteLinks />
    },
    {
      name: 'Phone number',
      description: '',
      component: <PhoneNumber />
    },
    {
      name: 'Lists',
      description: '',
      component: <Lists />
    }
  ];

  const [activeTextEdit, setActiveTextEdit] = useState({
    component: () => <></>
  });

  const adsLink = (link, i) => (
    <Nav.Item
      key={i}
      className={`fs--1 border-top border-2 px-3 w-100 border-dark py-2`}
    >
      <Flex justifyContent={'between'} alignItems={'center'}>
        <span>
          <span className="fw-medium">{link.name}</span> {link.description}
        </span>
        <Button
          className={`rounded-pill border-0 ${
            activeTextEdit?.name == link?.name
              ? 'bg-soft-primary text-primary'
              : ''
          }`}
          onClick={() => setActiveTextEdit(link)}
          size="sm"
        >
          Edit <FontAwesomeIcon icon={'angle-right'} />
        </Button>
      </Flex>
    </Nav.Item>
  );

  return (
    <>
      <LocalStoragePageInfo slug="edit_text">
        <Row className="text-dark">
          <Col>
            <Flex
              className="h-100 px-4 py-2"
              justifyContent="between"
              direction="column"
            >
              <div>
                <h2 className="mb-0 font-ibm-plex-serif">Edit text</h2>
                <p className="fs-1 mt-1"></p>
              </div>
              <Nav>{ads.map(adsLink)}</Nav>
            </Flex>
          </Col>
          <Col lg={5} xxl={4}>
            <img src={EditTextsImage} className="w-100" />
          </Col>
        </Row>
      </LocalStoragePageInfo>
      {activeTextEdit?.component}
    </>
  );
};

export default EditTexts;
