import IconButton from 'components/common/IconButton';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import CallApi from 'components/common/custom-components/CallApi';
import NameWithIcon from 'components/common/custom-components/NameWithIcon';
import LocalStoragePageInfo from 'components/common/custom-components/LocalStoragePageInfo';
import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import EditAccountModal from './EditAccountModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';

const Accounts = () => {
  const { data, refetch, isFetching } = useQuery(
    'accounts',
    () => CallApi.get(`/accounts`, true),
    {
      placeholderData: { data: [] }
    }
  );

  const columns = [
    {
      accessor: 'id',
      Header: 'Actions',
      headerProps: {
        className: 'text-center'
      },
      cellProps: {
        className: 'text-center'
      },
      Cell: ({ cell }) => {
        return (
          <>
            <EditAccountModal refetch={refetch} account={cell.row.original} />
            <Link to={'/account?id=' + cell.row.values.id}>
              <FontAwesomeIcon icon="eye" className="ms-3 text-secondary" />
            </Link>
          </>
        );
      }
    },
    {
      accessor: 'account_name',
      Header: 'Name',
      Cell: ({ cell }) => (
        <NameWithIcon name={cell.value} icon={cell.row.original.logo} />
      )
    },
    {
      accessor: 'status',
      Header: 'Active',
      Cell: ({ cell }) => (
        <FontAwesomeIcon
          className={classNames({
            'text-success': cell.value == 'active',
            'text-warning': cell.value == 'paused'
          })}
          icon={classNames({
            check: cell.value == 'active',
            pause: cell.value == 'paused'
          })}
        />
      )
    },
    {
      accessor: 'account_id',
      Header: 'Account ID'
    },
    {
      accessor: 'client.name',
      Header: 'Client'
    },
    {
      accessor: 'country.name',
      Header: 'Country'
    },
    {
      accessor: 'domain',
      Header: 'Domain'
    },
    {
      accessor: 'status_account',
      Header: 'Status'
    }
  ];

  return (
    <>
      <LocalStoragePageInfo slug="accounts" />
      <Card>
        <Card.Body className="m-3">
          <AdvanceTableWrapper
            columns={columns}
            data={data?.data || []}
            sortable
            pagination
          >
            <Row className="align-items-end g-2 row mb-3">
              <Col xs="auto">
                <AdvanceTableSearchBox table />
              </Col>
              <Col />
              <Col xs="auto">
                <Link to={'/drafts'} className={'text-sm me-3'}>
                  Drafts
                </Link>
                <IconButton
                  to={'/create-account'}
                  as={Link}
                  icon="plus"
                  className="rounded-pill"
                  variant="outline-primary"
                  size="sm"
                >
                  Add New
                </IconButton>
              </Col>
            </Row>
            <AdvanceTable
              table
              headerClassName="bg-aida-table-header text-900 text-nowrap align-middle"
              rowClassName="align-middle white-space-nowrap"
              tableProps={{
                bordered: true,
                striped: true,
                className: 'fs--1 mb-0 overflow-hidden'
              }}
              apiCallInProcess={isFetching}
            />
            <div className="mt-3">
              <AdvanceTableFooter
                rowCount={data?.data?.length}
                table
                rowInfo
                navButtons
                rowsPerPageSelection
              />
            </div>
          </AdvanceTableWrapper>
        </Card.Body>
      </Card>
    </>
  );
};

export default Accounts;
