import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import Avatar, { AvatarGroup } from 'components/common/Avatar';
import CallApi from 'components/common/custom-components/CallApi';
import NameWithIcon from 'components/common/custom-components/NameWithIcon';
import IconButton from 'components/common/IconButton';
import { inArray } from 'is_js';
import React from 'react';
import { Card, Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useQuery } from 'react-query';
import AvatarPickerModal from './AvatarPickerModal';
import UpsertModal from './UpsertModal';

const Listing = () => {
  const { data, refetch, isLoading } = useQuery(
    'users',
    () => CallApi.get('/users'),
    {
      placeholderData: { data: [] }
    }
  );

  const columns = [
    {
      accessor: 'id',
      Header: 'Actions',
      headerProps: { className: 'text-center' },
      cellProps: { className: 'text-center' },
      Cell: ({ cell }) => (
        <>
          <UpsertModal
            iconOnly
            userId={cell.value}
            isEditing={true}
            refreshListing={refetch}
          />
          <AvatarPickerModal
            iconOnly
            userId={cell.value}
            title="Change Avatar"
            variant="secondary"
            className="ms-2 cursor-pointer"
            refetchCurrent={refetch}
          />
          <FontAwesomeIcon
            icon="trash-alt"
            className="mx-2 cursor-pointer text-dark"
            onClick={() =>
              confirm('Do you really want to delete this user?') &&
              CallApi.delete('/users/' + cell.value).then(json => {
                if (json.status == 200) refetch();
              })
            }
          />
        </>
      )
    },
    {
      accessor: 'name',
      Header: 'Name',
      Cell: ({ cell }) => (
        <NameWithIcon name={cell.value} icon={cell.row.original.avatar_url} />
      )
    },
    {
      accessor: 'role.display_name',
      Header: 'Role'
    },
    // {
    //   accessor: 'clients',
    //   Header: 'Clients',
    //   Cell: ({ cell }) => {
    //     const role = cell.row.original.role.name;
    //     const client = cell.row.original.client;

    //     return inArray(role, ['Client Admin', 'Client']) ? (
    //       <NameWithIcon name={client?.name} icon={client?.favicon} />
    //     ) : (
    //       <AvatarGroup>
    //         {cell.value?.map(client => (
    //           <OverlayTrigger
    //             key={client.name}
    //             placement="top"
    //             overlay={<Tooltip>{client.name}</Tooltip>}
    //           >
    //             <div>
    //               <Avatar size="l" src={client?.favicon} className="ms-n1" />
    //             </div>
    //           </OverlayTrigger>
    //         ))}
    //       </AvatarGroup>
    //     );
    //   }
    // },
    // {
    //   accessor: 'accounts',
    //   Header: 'Accounts',
    //   Cell: ({ cell }) => {
    //     const role = cell.row.original.role.name;

    //     return inArray(role, ['Client']) ? (
    //       <AvatarGroup>
    //         {cell.value?.map(account => (
    //           <OverlayTrigger
    //             key={account.name}
    //             placement="top"
    //             overlay={<Tooltip>{account.account_name}</Tooltip>}
    //           >
    //             <div>
    //               <Avatar
    //                 src={account.logo}
    //                 name={account.account_name}
    //                 rounded="circle"
    //                 className="ms-n1"
    //               />
    //             </div>
    //           </OverlayTrigger>
    //         ))}
    //       </AvatarGroup>
    //     ) : null;
    //   }
    // },
    {
      accessor: 'status',
      Header: 'Status',
      // cellProps: { className: 'text-center' },
      Cell: ({ cell }) => (
        <>
          <FontAwesomeIcon
            className={cell.value == 'Active' ? 'text-success' : 'text-danger'}
            icon={['fas', cell.value == 'Active' ? 'check' : 'times']}
            size="lg"
            title={cell.value}
          />
          <span className="ms-1">{cell.value}</span>
        </>
      )
    },
    { accessor: 'email', Header: 'E-mail' },
    { accessor: 'phone', Header: 'Phone' },
    {
      accessor: 'created_at',
      Header: 'Created at'
    },
    {
      accessor: 'updated_at',
      Header: 'Updated at'
    }
  ];

  return (
    <Card>
      <Card.Body className="m-3">
        <AdvanceTableWrapper
          columns={columns}
          data={data?.data || []}
          sortable
          pagination
        >
          <Row className="align-items-end g-2 row mb-3">
            <Col xs="auto">
              <AdvanceTableSearchBox table />
            </Col>
            <Col />
            <Col xs="auto">
              <UpsertModal refreshListing={refetch} />
            </Col>
          </Row>
          <AdvanceTable
            table
            headerClassName="bg-aida-table-header text-900 text-nowrap align-middle"
            rowClassName="align-middle white-space-nowrap"
            tableProps={{
              bordered: true,
              striped: true,
              className: 'fs--1 mb-0 overflow-hidden'
            }}
            apiCallInProcess={isLoading}
          />
          <div className="mt-3">
            <AdvanceTableFooter
              rowCount={data.data?.length}
              table
              rowInfo
              navButtons
              rowsPerPageSelection
            />
          </div>
        </AdvanceTableWrapper>
      </Card.Body>
    </Card>
  );
};

export default Listing;
