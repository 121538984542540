export const moduleRoutes = ['/sales', '/create-account'];

export const RoutesDontRequireSidebar = [
  '/create-account',
  '/edit-draft',
  '/account'
];

export const SalesModuleRoutes = {
  label: 'Sales Modules',
  children: [
    {
      name: 'Research Competitor',
      icon: 'chart-pie',
      to: '/sales/research-competitor',
      active: true
    }
  ]
};

export const CreateAccountModuleRoutes = {
  label: 'Account Modules',
  children: [
    {
      name: 'Drafts',
      icon: 'chart-pie',
      to: '/drafts',
      active: true
    }
  ]
};

export const pagesRoutes = {
  label: 'Coming Soon',
  children: [
    {
      name: 'Budget Overviews',
      icon: 'comments',
      to: '/budget-overviews',
      active: false,
      clientAppendable: false
    },
    {
      name: 'Tasks',
      icon: 'tasks',
      to: '/tasks',
      active: false,
      clientAppendable: false
    },
    {
      name: 'Automations',
      icon: 'cogs',
      to: '/lambda-automations',
      active: false,
      clientAppendable: true
    },
    {
      name: 'Reports',
      icon: 'chart-pie',
      to: '/reports',
      active: false,
      clientAppendable: false
    }
  ]
};

export const toolRoutes = {
  label: 'tools',
  dropdownType: 'app',
  children: [
    {
      name: 'Avaza: Overview',
      icon: 'clock',
      to: '/avaza-ads-overviews',
      active: true
    },
    {
      name: 'Avaza: Invoice Rows',
      icon: 'clock',
      to: '/avaza-timerows',
      active: true
    },
    {
      name: 'Competitor Analysis',
      icon: 'chart-pie',
      to: '/competitor-analysis',
      active: true
    },
    {
      name: 'Keyword Research',
      icon: 'chart-pie',
      to: '/keyword-research',
      active: true
    }
  ]
};

export const appRoutes = {
  label: 'settings',
  icon: 'cog',
  dropdownType: 'app',
  children: [
    {
      name: 'Clients',
      icon: 'users',
      to: '/clients',
      active: true,
      moduleType: 'agency',
      identifier: 'google-ads',
      rolesCanAccess: ['Admin']
    },
    {
      name: 'Accounts',
      icon: 'user-circle',
      to: '/accounts',
      active: true,
      moduleType: 'agency',
      identifier: 'google-ads',
      rolesCanAccess: ['Admin']
    },
    {
      name: 'Users',
      icon: 'users',
      to: '/users',
      active: true,
      rolesCanAccess: ['Admin', 'Client Admin']
    },
    {
      name: 'Seo Projects',
      icon: 'seo-projects',
      to: '/seo-projects',
      active: true,
      moduleType: 'agency',
      identifier: 'seo-projects',
      rolesCanAccess: ['Admin', 'Manager', 'Sales']
    }
    // {
    //   name: 'Networks',
    //   icon: 'project-diagram',
    //   to: '/networks',
    //   active: true
    // },
    // {
    //   name: 'Metrics',
    //   icon: 'chart-line',
    //   to: '/metrics',
    //   active: true
    // },
    // {
    //   name: 'SEO Projects',
    //   icon: 'search',
    //   to: '/seo-projects',
    //   active: true
    // }
  ]
};

export const profileDropdownRoutes = [
  // {
  //   name: 'Flex Absence',
  //   to: '/flex-absence'
  // }
];

export const dashboardRoutes = {};

export const modulesRoutes = {};

export const agencyModuleRoutes = [
  {
    name: 'Clients',
    icon: 'users',
    to: '/clients',
    active: true
  },
  {
    name: 'Accounts',
    icon: 'user-circle',
    to: '/accounts',
    active: true
  }
];

export default [pagesRoutes];
