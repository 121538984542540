import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import IconButton from 'components/common/IconButton';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import CallApi from 'components/common/custom-components/CallApi';
import LocalStoragePageInfo from 'components/common/custom-components/LocalStoragePageInfo';
import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';

const DraftsWizardForm = () => {
  const { data, refetch, isLoading } = useQuery(
    'accounts',
    () => CallApi.get(`/account-details-draft`, true),
    {
      placeholderData: { data: [] }
    }
  );

  const columns = [
    {
      accessor: 'id',
      Header: 'Actions',
      headerProps: {
        className: 'text-center'
      },
      Cell: ({ cell }) => {
        return (
          <div className="text-center">
            <FontAwesomeIcon
              icon="edit"
              className="text-primary me-2 cursor-pointer"
              onClick={() => updateDraftAccount(cell.row.original.draft_id)}
            />
            <FontAwesomeIcon
              icon="trash-alt"
              className="text-dark"
              onClick={() => deleteDraftAccount(cell.row.values.id)}
            />
          </div>
        );
      }
    },
    {
      accessor: 'basic_information',
      Header: 'Account Name',
      Cell: ({ cell }) =>
        cell.value ? JSON.parse(cell.value)?.account_name : ''
    },
    {
      accessor: 'created_at',
      Header: 'Created At',
      Cell: ({ cell }) => {
        const dateObj = new Date(cell.value);
        const formattedDate = dateObj.toLocaleString('en-GB', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit'
        });

        return <span>{formattedDate}</span>;
      }
    }
  ];

  function deleteDraftAccount(id) {
    if (confirm("Attention! Are you sure you'd like to delete?")) {
      CallApi.delete(`/account-details-draft/${id}`).then(json => {
        if (json.status == 200) refetch();
      });
    }
  }

  const history = useHistory();

  function updateDraftAccount(id) {
    history.push(`/edit-draft/${id}`);
  }

  return (
    <>
      <LocalStoragePageInfo slug="account_drafts" />
      <Card>
        <Card.Body className="m-3">
          <AdvanceTableWrapper
            columns={columns}
            data={data?.data || []}
            sortable
            pagination
          >
            <Row className="align-items-end g-2 row mb-3">
              <Col xs="auto">
                <AdvanceTableSearchBox table />
              </Col>
              <Col />
            </Row>
            <AdvanceTable
              table
              headerClassName="bg-aida-table-header text-900 text-nowrap align-middle"
              rowClassName="align-middle white-space-nowrap"
              tableProps={{
                bordered: true,
                striped: true,
                className: 'fs--1 mb-0 overflow-hidden'
              }}
              apiCallInProcess={isLoading}
            />
            <div className="mt-3">
              <AdvanceTableFooter
                rowCount={data?.data?.length}
                table
                rowInfo
                navButtons
                rowsPerPageSelection
              />
            </div>
          </AdvanceTableWrapper>
        </Card.Body>
      </Card>
    </>
  );
};

export default DraftsWizardForm;
