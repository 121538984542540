import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { Button, Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import classNames from 'classnames';
import CallApi from 'components/common/custom-components/CallApi';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom';
import useUrl from 'hooks/useUrl';
import PasswordStrengthBar from 'react-password-strength-bar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const PasswordResetForm = ({ hasLabel }) => {
  const history = useHistory();
  const routeParams = useParams();
  const { token } = routeParams;

  // State
  const [formData, setFormData] = useState({
    password: '',
    email: '',
    password_confirmation: '',
    logout_from_other_devices: true
  });

  // Handler
  const handleSubmit = e => {
    e.preventDefault();
    CallApi.post('/password/reset', new FormData(e.target)).then(json => {
      if (json?.success) {
        toast.success('Login with your new password');
        history.push('/app/login');
      }
    });
  };

  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  useEffect(() => {
    if (!token) {
      history.push('/app/login');
    }
    return () => {};
  }, []);

  return (
    <Form
      className={classNames('mt-3', { 'text-left': hasLabel })}
      onSubmit={handleSubmit}
    >
      <Form.Control readOnly type="text" name="token" hidden value={token} />
      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>E-mail</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'Enter your E-mail' : ''}
          value={formData.email}
          name="email"
          onChange={handleFieldChange}
          type="email"
        />
      </Form.Group>

      <Form.Group className="mb-3">
        {hasLabel && (
          <Form.Label>
            New Password
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip>
                  Use combination of letters and symbols. e.g. Ksi@234#!
                </Tooltip>
              }
            >
              <span className={`ms-2 cursor-pointer`}>
                <FontAwesomeIcon icon="info-circle" />
              </span>
            </OverlayTrigger>
          </Form.Label>
        )}
        <Form.Control
          placeholder={!hasLabel ? 'New Password' : ''}
          value={formData.password}
          name="password"
          onChange={handleFieldChange}
          type="password"
        />
      </Form.Group>

      {formData?.password && formData.password != '' ? (
        <PasswordStrengthBar password={formData.password} />
      ) : null}

      <Form.Group>
        {hasLabel && <Form.Label>Confirm Password</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'Confirm Password' : ''}
          value={formData.password_confirmation}
          name="password_confirmation"
          onChange={handleFieldChange}
          type="password"
        />
      </Form.Group>
      <div className="text-end">
        <p>
          {formData.password_confirmation &&
          formData.password_confirmation != formData.password ? (
            <small className="text-danger">Passwords don't match </small>
          ) : null}
        </p>
      </div>

      <Form.Check
        value={`${formData.logout_from_other_devices}`}
        checked={formData.logout_from_other_devices}
        name="logout_from_other_devices"
        onChange={e =>
          setFormData({
            ...formData,
            logout_from_other_devices: !formData.logout_from_other_devices
          })
        }
        className="text-start"
        label="Logout from all other devices?"
      />

      <Button
        type="submit"
        className="w-100 rounded-pill"
        disabled={
          !formData.email ||
          !formData.password ||
          !formData.password_confirmation ||
          formData.password != formData.password_confirmation
        }
      >
        Set password
      </Button>
    </Form>
  );
};

PasswordResetForm.propTypes = {
  hasLabel: PropTypes.bool
};

export default PasswordResetForm;
