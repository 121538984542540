import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import CallApi from 'components/common/custom-components/CallApi';
import React, { useState } from 'react';
import {
  Button,
  ButtonGroup,
  Card,
  Col,
  OverlayTrigger,
  Row,
  Tooltip
} from 'react-bootstrap';
import { useQuery } from 'react-query';
import ManualAddWebsiteLinkModal from './ManualAddWebsiteLinkModal';
import StepDetailsCard from './StepDetailsCard';
import { validateJson } from 'helpers/utils';
import LocalStoragePageInfo from 'components/common/custom-components/LocalStoragePageInfo';

const YourWebsiteForm = ({ onNext, onPrevious, accountDraftId }) => {
  const [domain, setDomain] = useState('');
  const [initiallySelectedRowIds, setInitiallySelectedRowIds] = useState({});
  const [websiteLinks, setWebsiteLinks] = useState([]);

  const AccountDraftQuery = useQuery(
    ['account-draft-query', accountDraftId],
    () =>
      CallApi.get(
        `/account-details-draft/get-details-by-draft-id/` + accountDraftId
      ),
    {
      onSuccess: json => {
        const basicInfo = JSON.parse(json.data.basic_information);

        setDomain(basicInfo?.website);
      }
    }
  );

  const WebsiteLinksQuery = useQuery(
    'website-links',
    () => CallApi.get(`/scrape-links?domain=` + domain, false),
    {
      onSuccess: json => {
        const pages = AccountDraftQuery?.data?.data?.website;
        // this step is present due to not cause error as we are changing the format of storing links in db
        const savedLinks =
          (validateJson(pages)
            ? JSON.parse(pages)
            : pages
                ?.replaceAll('"', '')
                ?.replaceAll('\\/', '/')
                ?.split(',')
                ?.filter(item => item != '')) || [];

        const savedJsonLinks = validateJson(pages)
          ? savedLinks
          : savedLinks?.map(link => ({ link: link })) || [];

        const newLinks =
          json?.data?.filter(
            item => !savedLinks?.map(link => link.link)?.includes(item?.link)
          ) || [];

        const collapsedLinks = [...savedJsonLinks, ...newLinks];

        let initialSelectedRowIndexes = {};

        const indexesOfPreviouslySelectedRows = collapsedLinks
          ?.map((urlObj, i) =>
            savedLinks?.map(link => link.link).includes(urlObj?.link) ? i : null
          )
          ?.filter(index => index != null);

        indexesOfPreviouslySelectedRows.forEach(index => {
          initialSelectedRowIndexes[index] = true;
        });

        setInitiallySelectedRowIds(initialSelectedRowIndexes);

        setWebsiteLinks(collapsedLinks);
      },
      enabled: !!(domain && AccountDraftQuery.isFetched)
    }
  );

  const saveWebsite = selectedRows => {
    const links = selectedRows.map(row => row.original);

    var data = new FormData();

    data.append('links', JSON.stringify(links));
    data.append('draft_id', accountDraftId);
    data.append('form_type', 'website');

    CallApi.post(`/account-details-draft`, data).then(json => {
      if (json?.status == 200) {
        onNext();
      }
    });
  };

  const columns = [
    {
      accessor: 'link',
      Header: 'Page link',
      Cell: ({ cell }) => (
        <a
          href={
            cell.value?.includes('http') ? cell.value : `https://${cell.value}`
          }
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon icon={'external-link-alt'} /> {cell.value}{' '}
          {cell.row.original?.manually_added ? (
            <Button
              variant="outline-warning"
              className="rounded-pill"
              size="sm"
            >
              Manually Added
            </Button>
          ) : null}
        </a>
      )
    },
    {
      id: 'title',
      Header: 'Title',
      Cell: ({ cell }) => {
        if (cell.value) {
          return cell.value;
        }

        const fetchTitleQuery = useQuery(
          ['title-query', cell.row.original?.link],
          ({ signal }) =>
            CallApi.get(
              `/scrape-title?url=${cell.row.original?.link}`,
              false,
              {},
              signal
            ),
          {
            refetchOnWindowFocus: false,
            refetchOnMount: false
          }
        );

        return fetchTitleQuery.isLoading ? (
          <FontAwesomeIcon icon={'spinner'} spin />
        ) : (
          fetchTitleQuery?.data?.data || '-'
        );
      }
    }
  ];
  const apiCallInProcess =
    AccountDraftQuery.isLoading ||
    AccountDraftQuery.isFetching ||
    WebsiteLinksQuery.isLoading ||
    WebsiteLinksQuery.isFetching;

  const NextPrevButtons = ({ selectedFlatRows, selectedRowIds }) => {
    const isNextStepButtonDisabled = selectedFlatRows?.length < 2;
    return (
      <ButtonGroup className="mt-4">
        <Button
          variant="outline-primary"
          className="rounded-pill me-3"
          onClick={onPrevious}
        >
          <FontAwesomeIcon icon={'arrow-left'} /> Previous
        </Button>
        <OverlayTrigger
          placement={'top'}
          overlay={
            isNextStepButtonDisabled ? (
              <Tooltip>at least 2 urls must be selected</Tooltip>
            ) : (
              <></>
            )
          }
        >
          <span className="d-inline-block">
            <Button
              variant="outline-primary"
              className="rounded-pill"
              disabled={apiCallInProcess || isNextStepButtonDisabled}
              onClick={() => saveWebsite(selectedFlatRows)}
            >
              Next Step: Competitors <FontAwesomeIcon icon={'arrow-right'} />
            </Button>
          </span>
        </OverlayTrigger>
      </ButtonGroup>
    );
  };

  return (
    <Col>
      <LocalStoragePageInfo slug="account_form_your_website">
        <Row>
          <StepDetailsCard
            step="2"
            text="Help AIDA understand your website faster and thus deliver better adverstising right from the start by marking the pages that best describes your services/products."
            title="Your Website"
          />
        </Row>
      </LocalStoragePageInfo>
      <Card>
        <Card.Body className="m-3">
          <AdvanceTableWrapper
            columns={columns}
            data={websiteLinks || []}
            perPage={25}
            sortable
            initialSelectedRowIds={initiallySelectedRowIds}
            pagination
            selection
          >
            <Row className="align-items-center mb-3">
              <Col xs="auto">
                <AdvanceTableSearchBox table />
              </Col>
              <Col />
              <Col xs="auto">
                <ManualAddWebsiteLinkModal
                  disabled={apiCallInProcess}
                  setWebsiteLinks={setWebsiteLinks}
                  websiteDomain={domain}
                  setInitiallySelectedRowIds={setInitiallySelectedRowIds}
                  websiteLinks={websiteLinks}
                  table
                />
              </Col>
            </Row>
            <AdvanceTable
              table
              headerClassName="bg-aida-table-header text-900 text-nowrap align-middle"
              rowClassName="align-middle white-space-nowrap"
              tableProps={{
                className: 'fs--1 mb-0 overflow-hidden'
              }}
              rowProps={row => {
                return {
                  className: classNames({
                    'bg-soft-success': row.isSelected
                  })
                };
              }}
              loadingText="Please wait, we are fetching your website's page links..."
              apiCallInProcess={apiCallInProcess}
            />
            <AdvanceTableFooter
              table
              className="mt-3 mx-3"
              rowCount={websiteLinks.length}
              rowInfo
              navButtons
              rowsPerPageSelection
              rowsPerPageOptions={[25, 50, 75, 100]}
            />
            <div className="text-center">
              <NextPrevButtons table />
            </div>
          </AdvanceTableWrapper>
        </Card.Body>
      </Card>
    </Col>
  );
};

export default YourWebsiteForm;
