import React from 'react';
import IconButton from 'components/common/IconButton';
import { Modal } from 'react-bootstrap';
import { useState } from 'react';
import UpsertForm from './UpsertForm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const UpsertModal = ({
  iconOnly = false,
  userId = '',
  userFor = '',
  isEditing = false,
  refreshListing
}) => {
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
  };

  return (
    <>
      {iconOnly ? (
        <FontAwesomeIcon
          className="text-primary cursor-pointer"
          icon={isEditing ? 'edit' : 'plus'}
          onClick={() => setShow(true)}
        />
      ) : (
        <IconButton
          icon={isEditing ? 'edit' : 'plus'}
          className="rounded-pill"
          size="sm"
          variant="outline-primary"
          onClick={() => setShow(true)}
        >
          {!isEditing && 'Add User'}
        </IconButton>
      )}
      <Modal show={show} centered onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{isEditing ? 'Edit User' : 'Add User'}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-3">
          <UpsertForm
            userFor={userFor}
            closeModal={() => {
              refreshListing();
              handleClose();
            }}
            userId={userId}
          />
        </Modal.Body>
        <Modal.Footer>
          <IconButton
            icon="times-circle"
            size="sm"
            onClick={handleClose}
            variant="falcon-light"
          >
            Cancel
          </IconButton>
          <IconButton
            className="rounded-pill"
            variant="outline-primary"
            form="user-form"
            type="submit"
            icon="save"
            size="sm"
          >
            {isEditing ? 'Update' : 'Save'}
          </IconButton>
        </Modal.Footer>
      </Modal>
    </>
  );
};

UpsertModal.propTypes = {};

export default UpsertModal;
