import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Avatar from 'components/common/Avatar';
import CallApi from 'components/common/custom-components/CallApi';
import LocalStoragePageInfo from 'components/common/custom-components/LocalStoragePageInfo';
import LoginDetails from 'components/pages/user/settings/LoginDetails';
import ProfileSettings from 'components/pages/user/settings/ProfileSettings';
import { AidaAppContext } from 'context/AidaAppContext';
import { deleteItemFromStore } from 'helpers/utils';
import React, { useContext } from 'react';
import { Button, Card, Col, Row, Tab, Tabs } from 'react-bootstrap';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import AvatarPickerModal from './components/AvatarPickerModal';
import IconButton from 'components/common/IconButton';
import TwoFactorAuth from './components/TwoFactorAuth';

const UserProfileSettings = () => {
  const { data, refetch } = useQuery('me', () => CallApi.get('/me'));

  const history = useHistory();

  return (
    <>
      <LocalStoragePageInfo slug="profile_settings" />
      <Row className="g-3">
        <Col lg={9}>
          <Card>
            <Card.Body>
              <Tabs defaultActiveKey="profile" id="uncontrolled-tab-example">
                <Tab
                  eventKey="profile"
                  title="Profile"
                  className="border-bottom border-x p-3"
                >
                  <ProfileSettings userDetails={data?.data} />
                </Tab>
                <Tab
                  eventKey="login-details"
                  title="Login Details"
                  className="border-bottom border-x p-3"
                >
                  <LoginDetails userDetails={data?.data} />
                </Tab>
              </Tabs>
            </Card.Body>
          </Card>
        </Col>
        <Col lg={3}>
          <TwoFactorAuth user={data?.data} refetch={refetch} />
          <Card className="mt-3">
            <Card.Header className="text-center">
              <Avatar
                className="my-3"
                size="5xl"
                name={data?.data?.name}
                src={data?.data?.avatar_url}
              />
            </Card.Header>
            <Card.Body>
              <AvatarPickerModal
                userId={data?.data?.id}
                refetchCurrent={refetch}
                variant="outline-primary"
                className="w-100"
              >
                Update Photo
              </AvatarPickerModal>
            </Card.Body>
          </Card>
          <Card className="mt-3">
            <Card.Body>
              <h5 className="mb-1">
                <FontAwesomeIcon
                  icon={'exclamation-triangle'}
                  className="text-warning"
                />{' '}
                Deactivate account
              </h5>
              <p className="fs--1">
                Once you deactivate your account, you have to contact admin to
                re-enable it. Please be certain!
              </p>
              <Button
                onClick={() => {
                  if (
                    confirm('Do you really want to deactivate your account?')
                  ) {
                    CallApi.get('/me/toggle-status').then(response => {
                      if (response?.data?.status == 'In-Active') {
                        history.push('/app/logout');
                      }
                    });
                  }
                }}
                variant="danger"
                className="w-100"
              >
                Deactivate Account
              </Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default UserProfileSettings;
