import {
  faCloudDownloadAlt,
  faDownload
} from '@fortawesome/free-solid-svg-icons';
import IconButton from 'components/common/IconButton';
import CallApi from 'components/common/custom-components/CallApi';
import LocalStorageTooltip from 'components/common/custom-components/LocalStorageTooltip';
import React, { useContext, useState } from 'react';
import { Form, Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { AdsAccountContext } from '../AdsAccountContext';

const ImportAdgroupsModal = ({ refetch, ...rest }) => {
  const [show, setShow] = useState(false);
  const adsAccountContext = useContext(AdsAccountContext);
  const { currentAccount } = adsAccountContext;

  const ImportAsExcelMutation = useMutation(
    ['import-and-upsert-adgroups'],
    formData =>
      CallApi.post(
        `/account/${currentAccount?.id}/import-keyword-adgroups`,
        formData
      )
  );

  const handleClose = () => {
    setShow(false);
  };

  const [errors, setErrors] = useState({ file: '' });
  const { handleSubmit, register, control, reset } = useForm();

  const onSubmit = (data, form) => {
    ImportAsExcelMutation.mutate(new FormData(form.target), {
      onSuccess: json => {
        if (json.status == 200) {
          reset();
          refetch();
          setShow(false);
        } else if (json.status == 422) {
          setErrors(json.errors);
        }
      }
    });
  };

  return (
    <>
      <IconButton
        icon={faDownload}
        variant="outline-primary"
        size="sm"
        onClick={() => setShow(true)}
        {...rest}
      >
        Import
      </IconButton>
      <Modal show={show} centered onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Import Adgroups</Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-3">
          <Form onSubmit={handleSubmit(onSubmit)} id="import-adgroup-form">
            <Form.Group className="mb-3">
              <Form.Label>
                Excel File
                <LocalStorageTooltip
                  className="ms-1"
                  identifier={'form_import_adgroups_field_file'}
                />
              </Form.Label>
              <Form.Control
                type="file"
                {...register('file', { required: true })}
                isInvalid={errors?.file?.length > 0}
              />
              <Form.Control.Feedback type="invalid">
                {errors?.file && errors?.file?.map(error => error)}
              </Form.Control.Feedback>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <IconButton
            icon="times-circle"
            size="sm"
            onClick={handleClose}
            variant="falcon-light"
          >
            Cancel
          </IconButton>
          <IconButton
            form="import-adgroup-form"
            type="submit"
            icon={
              ImportAsExcelMutation.isLoading ? 'spinner' : faCloudDownloadAlt
            }
            spin={ImportAsExcelMutation.isLoading}
            size="sm"
            iconClassName="me-2"
          >
            {ImportAsExcelMutation.isLoading ? 'Importing...' : 'Import'}
          </IconButton>
        </Modal.Footer>
      </Modal>
    </>
  );
};

ImportAdgroupsModal.propTypes = {};

export default ImportAdgroupsModal;
