import React from 'react';

const VerticalNavbarLayout = ({ navbar, children }) => {
  return (
    <>
      {navbar}
      <div className="content overflow-hidden">{children}</div>
    </>
  );
};

export default VerticalNavbarLayout;
