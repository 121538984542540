import Avatar from 'components/common/Avatar';
import CallApi from 'components/common/custom-components/CallApi';
import { AidaAppContext } from 'context/AidaAppContext';
import React, { useContext } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import { profileDropdownRoutes } from 'routes/routes';

const ProfileDropdown = () => {
  const { data } = useQuery('me', () => CallApi.get('/me'), {
    placeholderData: { data: {} }
  });
  const aidaAppContext = useContext(AidaAppContext);
  return (
    <Dropdown navbar={true} as="li">
      <Dropdown.Toggle
        bsPrefix="toggle"
        as={'a'}
        className="pe-0 nav-link d-flex align-items-center cursor-pointer"
      >
        {/* <div>
          <Badge className="fs--1 me-2">Role: Manager</Badge>
        </div> */}
        <Avatar src={data?.data?.avatar_url} />
      </Dropdown.Toggle>

      <Dropdown.Menu className="dropdown-menu-card  dropdown-menu-end">
        <div className="bg-white rounded-2 py-2 dark__bg-1000">
          <Dropdown.Item
            as={Link}
            to="/profile/settings"
            className="fw-bold text-warning"
            // target="_blank"
            // href={
            //   aidaAppContext.user_permissions?.role == 'Admin'
            //     ? AppSettings.apiHost + '/profile'
            //     : null
            // }
          >
            <span>
              {data?.data?.name} ({aidaAppContext.user_role})
            </span>
          </Dropdown.Item>
          <Dropdown.Divider />
          {aidaAppContext.user_permissions?.access_profile_links && (
            <>
              {profileDropdownRoutes.map((route, index) => (
                <Dropdown.Item key={index} as={Link} to={route.to}>
                  {route.name}
                </Dropdown.Item>
              ))}
              {profileDropdownRoutes?.length > 0 ? <Dropdown.Divider /> : null}
            </>
          )}
          <Dropdown.Item as={Link} to="/app/logout">
            Logout
          </Dropdown.Item>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ProfileDropdown;
