import LogoutContent from 'components/authentication/LogoutContent';
import React from 'react';

const Logout = () => {
  return (
    <div className="text-center">
      <LogoutContent />
    </div>
  );
};

export default Logout;
