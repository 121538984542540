import IconButton from 'components/common/IconButton';
import React from 'react';
import generateExcel from 'zipcelx';

const ExportXLSX = ({
  page,
  sheetHeaderRow,
  filename,
  ignoreableColumnIndexes = [],
  className = 'ms-2',
  ...rest
}) => {
  const getExcel = () => {
    const config = {
      filename: filename,
      sheet: {
        data: []
      }
    };

    const dataSet = config.sheet.data;

    let sheetHeaders = [];

    sheetHeaderRow.map(columnName =>
      sheetHeaders.push({ value: columnName, type: 'string' })
    );

    dataSet.push(sheetHeaders);

    page.map(row => {
      const cells = row.cells;
      let payload = [];

      cells
        .filter((cell, index) => !ignoreableColumnIndexes.includes(index))
        .map(cell =>
          payload.push({
            value: cell.value,
            type: typeof cell.value == 'number' ? 'number' : 'string'
          })
        );

      dataSet.push(payload);
    });

    return generateExcel(config);
  };

  return (
    <IconButton
      className={`rounded-pill ${className}`}
      size="sm"
      variant="outline-primary"
      icon={['far', 'file-excel']}
      onClick={() => getExcel()}
      {...rest}
    >
      Export
    </IconButton>
  );
};

export default ExportXLSX;
