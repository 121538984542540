import Revenue from 'components/dashboards/crm/revenue/Revenue';
import ComingSoon from 'components/pages/ComingSoon';
import { AidaAppContext } from 'context/AidaAppContext';
import { inArray } from 'is_js';
import React, { useContext } from 'react';
import SeoProjects from '../seo-projects/SeoProjects';

const Dashboard = () => {
  const aidaAppContext = useContext(AidaAppContext);

  if (inArray('profit-margins', aidaAppContext.agency_modules)) {
    return (
      <div className="mt-n3">
        <Revenue />
      </div>
    );
  }

  if (inArray(aidaAppContext.user_role, ['Admin', 'Manager', 'Sales'])) {
    return <SeoProjects />;
  }

  return <ComingSoon />;
};

export default Dashboard;
