import { faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useClientSelectBox from 'components/aida/custom-form-hooks/useClientSelectBox';
import useRoleSelectBox from 'components/aida/custom-form-hooks/useRoleSelectBox';
import CallApi from 'components/common/custom-components/CallApi';
import LocalStorageTooltip from 'components/common/custom-components/LocalStorageTooltip';
import ReactSelectBoxImageLabel from 'components/common/custom-components/ReactSelectBoxImageLabel';
import Divider from 'components/common/Divider';
import Flex from 'components/common/Flex';
import { AidaAppContext } from 'context/AidaAppContext';
import { inArray } from 'is_js';
import React, { useContext, useEffect, useState } from 'react';
import { Button, Form, InputGroup } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import ReactSelect from 'react-select';

const UpsertForm = ({ userFor = '', closeModal, userId }) => {
  const aidaAppContext = useContext(AidaAppContext);
  const [errors, setErrors] = useState({
    email: null,
    force_to_change_password: null,
    password: null,
    password_confirmation: null
  });
  const [showPass, setShowPass] = useState(false);
  const [clients, setClients] = useState([]);
  const [client, setClient] = useState({ id: null });
  const [selectedRole, setSelectedRole] = useState(null);
  const [accounts, setAccounts] = useState([]);
  const { handleSubmit, setValue, register, control, reset, watch } = useForm();

  const onSubmit = (data, form) => {
    if (userId) {
      let formData = new FormData(form.target);
      // because password isn't require while updating details & empty is getting validated by laravel.
      for (let field of formData.keys()) {
        const value = formData.get(field);

        if ((field == 'password' && value === '') || value === null) {
          formData.delete(field);
        }
      }

      CallApi.put('/users/' + userId, formData, true, false).then(json => {
        if (json.status == 422) {
          setErrors(json.errors);
        } else {
          closeModal();
        }
      });
    } else {
      CallApi.post('/users', new FormData(form.target), true, false).then(
        json => {
          if (json.status == 422) {
            setErrors(json.errors);
          } else {
            closeModal();
          }
        }
      );
    }
  };

  const ClientsQuery = useQuery(
    'clients',
    () => CallApi.get('/clients?sort=name', false),
    {
      placeholderData: { data: [] },
      enabled: false
    }
  );

  const AccountsQuery = useQuery(
    ['client_accounts', client?.id || aidaAppContext.client?.id],
    () =>
      CallApi.get(
        `/clients/${client?.id || aidaAppContext.client?.id}/accounts`,
        false
      ),
    {
      onSuccess: response => {
        if (userId) {
          setAccounts(
            response?.data?.filter(account => {
              return ExistingUserQuery?.data?.data?.account_ids?.includes(
                account.id
              );
            })
          );
        }
      },
      placeholderData: { data: [] },
      enabled: !!(
        client?.id ||
        (aidaAppContext.client?.id && selectedRole == 'Client')
      )
    }
  );

  const ExistingUserQuery = useQuery(
    ['user', userId],
    () => CallApi.get('/users/' + userId),
    {
      onSuccess: response => {
        reset(response.data);
        setSelectedRole(response.data?.role?.name);
        // setClients(
        //   ClientsQuery.data?.data?.filter(client =>
        //     response.data.client_ids.find(
        //       existingClientId => client.id == existingClientId
        //     )
        //   )
        // );
        // setClient(
        //   ClientsQuery.data?.data?.find(
        //     client => response.data.client_id == client.id
        //   )
        // );
      },
      enabled: !!userId
    }
  );

  const RoleSelectBox = useRoleSelectBox();

  return (
    <>
      <Form id="user-form" onSubmit={handleSubmit(onSubmit)}>
        <h5>Personal Details</h5>
        <hr className="mt-2" />
        <Form.Group hidden={userFor == 'accounts'} className="mb-3">
          <Form.Label>
            Status
            <LocalStorageTooltip
              className="ms-1"
              identifier={'form_users_field_status'}
            />
          </Form.Label>
          <Form.Select
            defaultValue={userFor == 'accounts' ? 'Active' : ''}
            {...register('status', {
              required: true,
              disabled: ExistingUserQuery.isFetching
            })}
            isInvalid={errors.status}
          >
            <option value="">Please select status</option>
            <option value={'Active'}>Active</option>
            <option value={'Unconfirmed'}>Unconfirmed</option>
            <option value={'Banned'}>Banned</option>
          </Form.Select>
          <Form.Control.Feedback type="invalid">
            {errors.status && errors.status.map(error => error)}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>
            First Name
            <LocalStorageTooltip
              className="ms-1"
              identifier={'form_users_field_first_name'}
            />
          </Form.Label>
          <Form.Control
            {...register('first_name', {
              required: true,
              disabled: ExistingUserQuery.isFetching
            })}
            isInvalid={errors.first_name}
          />
          <Form.Control.Feedback type="invalid">
            {errors.first_name && errors.first_name.map(error => error)}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>
            Last Name
            <LocalStorageTooltip
              className="ms-1"
              identifier={'form_users_field_last_name'}
            />
          </Form.Label>
          <Form.Control
            {...register('last_name', {
              required: true,
              disabled: ExistingUserQuery.isFetching
            })}
            isInvalid={errors.last_name}
          />
          <Form.Control.Feedback type="invalid">
            {errors.last_name && errors.last_name.map(error => error)}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group
          hidden={userFor == 'accounts'}
          className="mb-3"
          controlId="favicon"
        >
          <Form.Label>Phone</Form.Label>
          <Form.Control
            type="string"
            {...register('phone', {
              disabled: ExistingUserQuery.isFetching
            })}
            placeholder="Enter phone number"
          />
          <Form.Control.Feedback type="invalid">
            Please enter phone number.
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>
            Role
            <LocalStorageTooltip
              className="ms-1"
              identifier={'form_users_field_role'}
            />
          </Form.Label>
          <Controller
            control={control}
            name="role_id"
            render={({ field }) => {
              return (
                <RoleSelectBox
                  isSearchable
                  required
                  isDisabled={
                    aidaAppContext?.current_user?.id == userId ||
                    ExistingUserQuery.isFetching
                  }
                  value={field.value}
                  className={errors.role_id && 'is-invalid'}
                  onChange={role => {
                    setSelectedRole(role.name);
                    field.onChange(role.id);
                  }}
                />
              );
            }}
          />
          <Form.Control.Feedback type="invalid">
            {errors.role_id && errors.role_id.map(error => error)}
          </Form.Control.Feedback>
        </Form.Group>

        {/* <>
          {inArray(selectedRole, ['Manager', 'Sales']) ? (
            <Form.Group className="mb-3">
              <Form.Label>
                Accessible Clients
                <LocalStorageTooltip
                  className="ms-1"
                  identifier={'form_users_field_clients'}
                />
              </Form.Label>
              <Controller
                control={control}
                name="client_ids[]"
                render={({ field }) => {
                  return (
                    <ReactSelect
                      placeholder={'Please select clients'}
                      options={ClientsQuery.data?.data || []}
                      name={'client_ids[]'}
                      formatOptionLabel={client => (
                        <ReactSelectBoxImageLabel
                          src={client?.favicon}
                          title={client?.name}
                        />
                      )}
                      onChange={client => setClients(client)}
                      value={clients}
                      getOptionValue={client => client.id}
                      getOptionLabel={client => client.name}
                      isDisabled={ExistingUserQuery.isLoading}
                      isMulti
                      isSearchable
                    />
                  );
                }}
              />
              <Form.Control.Feedback type="invalid">
                {errors.clients && errors.clients.map(error => error)}
              </Form.Control.Feedback>
            </Form.Group>
          ) : null}
        </> */}

        {/*<>
          {aidaAppContext.user_role == 'Admin' &&
          inArray(selectedRole, ['Client Admin', 'Client']) ? (
            <Form.Group className="mb-3">
              <Form.Label>
                Choose Client
                <LocalStorageTooltip
                  className="ms-1"
                  identifier={'form_users_field_client'}
                />
              </Form.Label>
              <Controller
                control={control}
                name="client_id"
                render={({ field }) => {
                  return (
                    <ReactSelect
                      placeholder={'Please select client'}
                      options={ClientsQuery.data?.data || []}
                      name={'client_id'}
                      formatOptionLabel={client => (
                        <ReactSelectBoxImageLabel
                          src={client?.favicon}
                          title={client?.name}
                        />
                      )}
                      onChange={client => {
                        setClient(client);
                        field.onChange(client.id);
                      }}
                      value={client}
                      getOptionValue={client => client.id}
                      getOptionLabel={client => client.name}
                      isDisabled={ExistingUserQuery.isLoading}
                      isSearchable
                    />
                  );
                }}
              />
              <Form.Control.Feedback type="invalid">
                {errors.clients && errors.clients.map(error => error)}
              </Form.Control.Feedback>
            </Form.Group>
          ) : null}
        </> */}

        {/*<>
          {inArray(selectedRole, ['Client']) ? (
            <Form.Group className="mb-3">
              <Form.Label>
                Accessible Accounts
                <LocalStorageTooltip
                  className="ms-1"
                  identifier={'form_users_field_accounts'}
                />
              </Form.Label>
              <Controller
                control={control}
                name="account_ids[]"
                render={({ field }) => {
                  return (
                    <ReactSelect
                      placeholder={'Please select accounts'}
                      options={AccountsQuery.data?.data || []}
                      name={'account_ids[]'}
                      onChange={account => setAccounts(account)}
                      value={accounts}
                      getOptionValue={account => account.id}
                      getOptionLabel={account =>
                        `${account.account_name} - ${account.account_id}`
                      }
                      isDisabled={ExistingUserQuery.isFetching}
                      isMulti
                      isSearchable
                    />
                  );
                }}
              />
              <Form.Control.Feedback type="invalid">
                {errors.clients && errors.clients.map(error => error)}
              </Form.Control.Feedback>
            </Form.Group>
          ) : null}
          <Form.Group hidden={userFor == 'accounts'} className="mb-3">
            <Form.Label>
              Address
              <LocalStorageTooltip
                className="ms-1"
                identifier={'form_users_field_address'}
              />
            </Form.Label>
            <Form.Control
              as="textarea"
              {...register('address', {
                disabled: ExistingUserQuery.isFetching
              })}
              isInvalid={errors.address}
            />
            <Form.Control.Feedback type="invalid">
              {errors.address && errors.address.map(error => error)}
            </Form.Control.Feedback>
          </Form.Group>
        </> */}

        <h5 className="mt-3 mb-0">Login Details</h5>
        <hr className="mt-2" />

        {/* <>
          <Form.Group hidden={userFor == 'accounts'} className="mb-3">
            <Form.Label>
              Username
              <LocalStorageTooltip
                className="ms-1"
                identifier={'form_users_field_username'}
              />
            </Form.Label>
            <Form.Control
              {...register('username', {
                disabled: ExistingUserQuery.isFetching
              })}
              isInvalid={errors.username}
            />
            <Form.Control.Feedback type="invalid">
              {errors.username && errors.username.map(error => error)}
            </Form.Control.Feedback>
          </Form.Group>
        </> */}

        <Form.Group className="mb-3">
          <Form.Label>
            E-mail
            <LocalStorageTooltip
              className="ms-1"
              identifier={'form_users_field_email'}
            />
          </Form.Label>
          <Form.Control
            type="email"
            {...register('email', {
              required: true,
              disabled: ExistingUserQuery.isFetching
            })}
            isInvalid={errors.email}
          />

          <Form.Control.Feedback type="invalid">
            {errors.email && errors.email.map(error => error)}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>
            {userId ? 'New' : null} Password
            <LocalStorageTooltip
              className="ms-1"
              identifier={'form_users_field_password'}
            />
          </Form.Label>
          <InputGroup>
            <Form.Control
              type={showPass ? 'text' : 'password'}
              {...register('password', {
                required: !userId,
                disabled: ExistingUserQuery.isFetching
              })}
              isInvalid={errors.password}
            />
            <InputGroup.Text type="button" as="button">
              <span onClick={() => setShowPass(!showPass)}>
                {showPass ? (
                  <FontAwesomeIcon icon={faEyeSlash} />
                ) : (
                  <FontAwesomeIcon icon={'eye'} />
                )}
              </span>
            </InputGroup.Text>
          </InputGroup>{' '}
          {/* <Form.Text>
            Leave blank if you don't want to change password.
          </Form.Text> */}
          {errors?.password ? (
            <Form.Text className="text-danger" as="div">
              {errors?.password?.join(',')}
            </Form.Text>
          ) : null}
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>
            Confirm {userId ? 'New' : null} Password
            <LocalStorageTooltip
              className="ms-1"
              identifier={'form_users_field_password_confirmation'}
            />
          </Form.Label>
          <InputGroup>
            <Form.Control
              type={showPass ? 'text' : 'password'}
              {...register('password_confirmation', {
                required: !userId,
                disabled: ExistingUserQuery.isFetching
              })}
              isInvalid={errors.password_confirmation}
            />
            <InputGroup.Text type="button" as="button">
              <span onClick={() => setShowPass(!showPass)}>
                {showPass ? (
                  <FontAwesomeIcon icon={faEyeSlash} />
                ) : (
                  <FontAwesomeIcon icon={'eye'} />
                )}
              </span>
            </InputGroup.Text>
          </InputGroup>
          {/* <Form.Text>
            Leave blank if you don't want to change password.
          </Form.Text> */}
          <Form.Control.Feedback type="invalid">
            {errors.password_confirmation &&
              errors.password_confirmation.map(error => error)}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group>
          <div className="form-check">
            <Form.Check
              id="force_to_change_password"
              defaultValue={1}
              {...register('force_to_change_password', {
                disabled: ExistingUserQuery.isFetching
              })}
              isInvalid={errors.force_to_change_password}
            />

            <Form.Label
              className="user-select-none"
              htmlFor="force_to_change_password"
            >
              Force user to change password
              <LocalStorageTooltip
                className="ms-1"
                identifier={'form_users_field_force_to_change_password'}
              />
            </Form.Label>
          </div>
          {errors?.force_to_change_password ? (
            <Form.Text className="text-danger mt-0" as="div">
              {errors?.force_to_change_password?.join(',')}
            </Form.Text>
          ) : null}
        </Form.Group>
      </Form>
    </>
  );
};

Form.propTypes = {};

export default UpsertForm;
