import { AidaAppContext } from 'context/AidaAppContext';
import React, { useContext } from 'react';
import { Badge } from 'react-bootstrap';
import FreshChat from 'react-freshchat';
import { AppSettings } from 'settings';

const FreshChatPlugin = () => {
  const aidaAppContext = useContext(AidaAppContext);
  const { current_user, user_role, agency_modules, agency } = aidaAppContext;

  return (
    <>
      {user_role != '' && agency_modules.includes('freshchat') && (
        <>
          {AppSettings.freshchat_token && AppSettings.freshchat_host ? (
            <FreshChat
              host={AppSettings.freshchat_host}
              token={AppSettings.freshchat_token}
              onInit={widget => {
                /* Use `widget` instead of `window.fcWidget`*/
                widget.user.setEmail(current_user?.email);
                widget.user.setFirstName(current_user?.first_name);
                widget.user.setLastName(current_user?.last_name);
                widget.user.setPhone(current_user?.phone);

                widget.user.setProperties({
                  username: current_user?.name,
                  name: current_user?.name,
                  email: current_user?.email,
                  first_name: current_user?.first_name,
                  last_name: current_user?.last_name,
                  phone: current_user?.phone,
                  agency: agency?.name
                });
              }}
            />
          ) : (
            <Badge
              bg="danger"
              pill
              className="m-3 px-2 py-2 sticky-bottom float-end"
              style={{ bottom: '35px' }}
            >
              Freshchat not configured
            </Badge>
          )}
        </>
      )}
    </>
  );
};

export default FreshChatPlugin;
