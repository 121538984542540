import useAccountCategorySelectBox from 'components/aida/custom-form-hooks/useAccountCategorySelectBox';
import useAccountTypeSelectBox from 'components/aida/custom-form-hooks/useAccountTypeSelectBox';
import useClientSelectBox from 'components/aida/custom-form-hooks/useClientSelectBox';
import useCountrySelectBox from 'components/aida/custom-form-hooks/useCountrySelectBox';
import useLanguageSelectBox from 'components/aida/custom-form-hooks/useLanguageSelectBox';
import Avatar from 'components/common/Avatar';
import LocalStorageTooltip from 'components/common/custom-components/LocalStorageTooltip';
import FalconCardHeader from 'components/common/FalconCardHeader';
import React, { useContext, useEffect } from 'react';
import { Row, Col, Card, Form, Button } from 'react-bootstrap';
import { AdsAccountContext } from '../AdsAccountContext';
import YourLogo from './components/YourLogo';
import useProfileForm from '../../form-hooks/useProfileForm';

const ProfileSettings = () => {
  const adsAccountContext = useContext(AdsAccountContext);
  const { currentAccount } = adsAccountContext;

  const { ProfileForm, reset } = useProfileForm(
    (data, form) => {},
    'account-profile-settings',
    true
  );

  useEffect(() => {
    reset(currentAccount);
    return () => {};
  }, [currentAccount]);

  return (
    <Row className="gap-3">
      <Col>
        <Card>
          <Card.Body className="m-3">
            <h5 className="border-bottom border-2 border-dark pb-3 mb-4">
              Profile Settings
            </h5>
            {ProfileForm}
          </Card.Body>
        </Card>
      </Col>
      <Col lg={4} xxl={3}>
        <YourLogo />
      </Col>
    </Row>
  );
};

export default ProfileSettings;
