import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import CallApi from 'components/common/custom-components/CallApi';
import React, { useContext, useEffect, useState } from 'react';
import { useMemo } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { searchQueryKeywordsContext } from '../SearchQueryKeywords';
import StatusWithName from './StatusWithName';
import useUrl from 'hooks/useUrl';
import { AdvanceTableFooter } from 'components/common/advance-table/AdvanceTableFooter';

const ChangeCampaignAdgroupTable = ({ setShow, selectedKeywordIds = [] }) => {
  const url = useUrl();
  const [data, setData] = useState([]);
  const [apiCallInProcess, setApiCallInProcess] = useState(false);
  const { searchQueryContext, setSearchQueryContext } = useContext(
    searchQueryKeywordsContext
  );

  const bulkUpdate = (newCampaignId, newAdgroupId) => {
    const formData = new FormData();
    selectedKeywordIds.forEach(id => formData.append('keyword_ids[]', id));
    formData.append('new_campaign_id', newCampaignId);
    formData.append('new_adgroup_id', newAdgroupId);
    formData.append('action', '1'); // Positive status for keyword

    CallApi.put(
      '/search-query-keywords/bulk-update?type=campaign-adgroups',
      formData
    ).then(json => {
      setShow(false);
    });
  };

  const columns = useMemo(
    () => [
      {
        accessor: 'id',
        Header: 'Select',
        headerProps: {
          className: 'text-center'
        },
        Cell: ({ row }) => (
          <Form.Check
            className="ms-2 cursor-pointer"
            type="radio"
            onChange={() =>
              searchQueryContext.isBulkSelectedAndUpdating &&
              searchQueryContext.bulkSelectAction ==
                'update-campaigns-adgroups-and-decide-as-positive'
                ? bulkUpdate(row.original.campaign_id, row.original.adgroup_id)
                : upsertNewCampaignAdgroup(
                    row.original.campaign_id,
                    row.original.adgroup_id
                  )
            }
            checked={
              searchQueryContext.adgroupChangeableKeyword?.new_adgroup_id ==
                row.original.adgroup_id &&
              searchQueryContext.adgroupChangeableKeyword?.new_campaign_id ==
                row.original.campaign_id
            }
          />
        ),
        cellProps: {
          className: 'text-center'
        }
      },
      {
        accessor: 'campaign_name',
        Header: 'Campaign Name',
        Cell: ({ cell }) => (
          <StatusWithName
            status={cell.row.original.campaign_status}
            name={cell.value}
          />
        )
      },
      {
        accessor: 'adgroup_name',
        Header: 'Adgroup Name',
        Cell: ({ cell }) => (
          <StatusWithName
            status={cell.row.original.adgroup_status}
            name={cell.value}
          />
        )
      }
    ],
    []
  );

  const upsertNewCampaignAdgroup = (new_campaign_id, new_adgroup_id) => {
    let formData = new FormData();
    formData.append(
      'keyword_id',
      searchQueryContext.adgroupChangeableKeyword.id
    );
    formData.append('new_campaign_id', new_campaign_id);
    formData.append('new_adgroup_id', new_adgroup_id);

    // update entry with new campaign and adgroup id
    CallApi.put('/search-query-keywords/campaigns-and-adgroups', formData).then(
      json => {
        // close modal
        if (setShow) setShow(false);
      }
    );
  };

  useEffect(() => {
    CallApi.get(
      `/search-query-keywords/campaign-and-adgroups?account_id=${url.get(
        'account_id'
      )}`,
      false
    ).then(json => (json.status == 200 ? setData(json.data) : ''));
  }, []);

  return (
    <AdvanceTableWrapper
      columns={columns}
      data={data}
      sortable
      pagination
      perPage={20}
    >
      <Row className="align-items-end g-2 row mb-3">
        <Col xs={12}>
          <AdvanceTableSearchBox table />
        </Col>
        <Col />
      </Row>
      <AdvanceTable
        table
        headerClassName="bg-aida-table-header text-900 text-nowrap align-middle"
        rowClassName="align-middle white-space-nowrap"
        tableProps={{
          striped: true,
          className: 'fs--1 mb-0 overflow-hidden'
        }}
        apiCallInProcess={apiCallInProcess}
        tableFooterClassName="table-secondary"
      />
      <AdvanceTableFooter
        // @ts-ignore
        table
        className="mt-3"
        rowCount={data.length}
        navButtons
        rowsPerPageSelection
        rowsPerPageOptions={[10, 20, 30, 40, 50]}
      />
    </AdvanceTableWrapper>
  );
};

export default ChangeCampaignAdgroupTable;
