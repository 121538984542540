import React, { useContext, useEffect, useState } from 'react';
import { Badge, Table, Toast, ToastContainer } from 'react-bootstrap';
import IconButton from 'components/common/IconButton';
import { Row, Col, Modal, Card } from 'react-bootstrap';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import Create from './Create';
import CallApi from 'components/common/custom-components/CallApi';
import LocalStoragePageInfo from 'components/common/custom-components/LocalStoragePageInfo';
import NameWithIcon from 'components/common/custom-components/NameWithIcon';
import ClientColorBadge from './ClientColorBadge';
import { Link } from 'react-router-dom';
import { AidaAppContext } from 'context/AidaAppContext';
import { useMutation, useQuery } from 'react-query';
import ClientUpsertModal from './ClientUpsertModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Clients = () => {
  const ClientsQuery = useQuery('clients', () => CallApi.get('/clients'));

  const columns = [
    {
      accessor: 'id',
      Header: 'Actions',
      headerProps: { style: { maxWidth: '20px' } },
      cellProps: { style: { maxWidth: '20px' } },
      Cell: ({ cell }) => {
        const DeleteClientMutation = useMutation(
          ['delete-client'],
          id => CallApi.delete(`/clients/${id}`),
          {
            onSuccess: json => {
              if (json?.status == 200) ClientsQuery?.refetch();
            }
          }
        );
        return (
          <>
            <ClientUpsertModal
              className="ms-2 text-primary cursor-pointer"
              iconOnly
              refreshListing={ClientsQuery.refetch}
              clientId={cell.value}
            />
            <FontAwesomeIcon
              onClick={() => {
                if (confirm("Attention! Are you sure you'd like to delete?")) {
                  DeleteClientMutation.mutate(cell.row.original.id);
                }
              }}
              className="ms-3 text-dark cursor-pointer"
              icon={DeleteClientMutation.isLoading ? 'spinner' : 'trash-alt'}
              spin={DeleteClientMutation.isLoading}
            />
          </>
        );
      }
    },
    {
      accessor: 'name',
      Header: 'Name',
      Cell: ({ cell }) => (
        <NameWithIcon name={cell.value} icon={cell.row.original?.favicon} />
      )
    },
    {
      accessor: 'info',
      Header: 'Info'
    }
  ];

  return (
    <>
      <LocalStoragePageInfo slug="clients" />
      <Card>
        <Card.Body className="m-3">
          <AdvanceTableWrapper
            columns={columns}
            data={ClientsQuery?.data?.data || []}
            sortable
            pagination
          >
            <Row className="align-items-end g-2 row mb-3">
              <Col xs="auto">
                <AdvanceTableSearchBox table />
              </Col>
              <Col></Col>
              <Col xs="auto">
                <ClientUpsertModal refreshListing={ClientsQuery?.refetch} />
              </Col>
            </Row>
            <AdvanceTable
              table
              headerClassName="bg-aida-table-header text-900 text-nowrap align-middle"
              rowClassName="align-middle white-space-nowrap"
              tableProps={{
                bordered: true,
                striped: true,
                className: 'fs--1 mb-0 overflow-hidden'
              }}
              apiCallInProcess={ClientsQuery?.isLoading}
            />
            <div className="mt-3">
              <AdvanceTableFooter
                rowCount={ClientsQuery?.data?.data?.length}
                table
                rowInfo
                navButtons
                rowsPerPageSelection
              />
            </div>
          </AdvanceTableWrapper>
        </Card.Body>
      </Card>
    </>
  );
};

export default Clients;
