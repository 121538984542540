import React from 'react';
import LogoutContent from 'components/authentication/LogoutContent';
import AuthAidaLayout from 'layouts/AuthAidaLayout';

const Logout = () => {
  return (
    <AuthAidaLayout>
      <div className="text-center">
        <LogoutContent layout="app" titleTag="h3" />
      </div>
    </AuthAidaLayout>
  );
};

export default Logout;
