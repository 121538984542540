import React, { useState } from 'react';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import CallApi from 'components/common/custom-components/CallApi';
import FalconCardHeader from 'components/common/FalconCardHeader';
import { Button, Card } from 'react-bootstrap';
import { useMutation } from 'react-query';

const TargetGroups = ({
  accountId = '',
  refetchAccount = () => {},
  targetGroup = '',
  ...rest
}) => {
  const levels = [
    { title: 'Priority', color: 'success' },
    { title: 'Neutral', color: 'secondary' },
    { title: 'Neglect', color: 'magenta' }
  ];

  const defaultData = [
    { age_group: '18-24', male: '', female: '' },
    { age_group: '25-34', male: '', female: '' },
    { age_group: '35-44', male: '', female: '' },
    { age_group: '45-54', male: '', female: '' },
    { age_group: '55-64', male: '', female: '' },
    { age_group: '65+', male: '', female: '' }
  ];

  const parsedJson = targetGroup ? JSON.parse(targetGroup) : null;

  const [data, setData] = useState(parsedJson || defaultData);

  const UpdateTargetGroupMutation = useMutation(
    'update-target-group',
    formData => CallApi.put(`/account/${accountId}/target-group`, formData)
  );

  const selectLevel = (tableRow, gender, level) => {
    const jsonData = data?.map(row =>
      row.age_group == tableRow.age_group ? { ...row, [gender]: level } : row
    );

    setData(jsonData);

    let formData = new FormData();
    formData.append('target_group', JSON.stringify(jsonData));

    UpdateTargetGroupMutation.mutate(formData, {
      onSuccess: json => {
        refetchAccount();
      }
    });
  };

  const columns = [
    {
      accessor: 'age_group',
      Header: 'Age',
      cellProps: { className: 'fw-medium' }
    },
    {
      accessor: 'male',
      Header: 'Male',
      cellProps: { className: 'border-end-2 border-start-2 border-dark' },
      Cell: ({ cell }) => {
        const male = cell.row.original.male;
        return (
          <>
            {levels.map(level => (
              <Button
                className="rounded-pill me-2"
                variant={
                  male == level.title ? level.color : `outline-${level.color}`
                }
                size="sm"
                onClick={() =>
                  selectLevel(cell.row.original, 'male', level.title)
                }
              >
                {level.title}
              </Button>
            ))}
          </>
        );
      }
    },
    {
      accessor: 'female',
      Header: 'Female',
      Cell: ({ cell }) => {
        const female = cell.row.original.female;
        return (
          <>
            {levels.map(level => (
              <Button
                className="rounded-pill me-2"
                variant={
                  female == level.title ? level.color : `outline-${level.color}`
                }
                size="sm"
                onClick={() =>
                  selectLevel(cell.row.original, 'female', level.title)
                }
              >
                {level.title}
              </Button>
            ))}
          </>
        );
      }
    }
  ];

  return (
    <Card {...rest}>
      <Card.Body className="m-3">
        <h5 className="pb-3">Target Groups</h5>
        <AdvanceTableWrapper columns={columns} data={data || []}>
          <AdvanceTable
            table
            headerClassName="bg-aida-table-header exclude-last-row text-900 text-nowrap align-middle"
            rowClassName="align-middle white-space-nowrap"
            tableProps={{
              bordered: true,
              className: 'fs--1 text-center mb-0 overflow-hidden'
            }}
          />
        </AdvanceTableWrapper>
      </Card.Body>
    </Card>
  );
};

export default TargetGroups;
