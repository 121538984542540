import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CallApi from 'components/common/custom-components/CallApi';
import ReactSelectBoxIconLabel from 'components/common/custom-components/ReactSelectBoxIconLabel';
import ReactSelectBoxImageLabel from 'components/common/custom-components/ReactSelectBoxImageLabel';
import Flex from 'components/common/Flex';
import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useQuery } from 'react-query';
import ReactSelect from 'react-select';
import defaultAccountLogo from '../../../assets/img/team/avatar.png';

const useAccountSelectBox = () => {
  const getAccounts = () => CallApi.get('/accounts?sort=name', false);
  const { data } = useQuery('accounts', getAccounts, {
    placeholderData: { data: [] }
  });

  const AccountSelectBox = ({
    name = 'account_id',
    className = '',
    isClearable = false,
    isDisabled = false,
    isLoading = false,
    isMulti = false,
    isSearchable = false,
    placeholder = 'Please select an account',
    onChange = account => {},
    value,
    size = 'md',
    canShowErrorFeedback = false
  }) => {
    const [account, setAccount] = useState(null);

    useEffect(() => {
      if (data?.data?.length > 0) {
        setAccount(data?.data.find(account => account.id == value));
      }
    }, [value]);

    const smSizeStyles = {
      control: provided => ({
        ...provided,
        minHeight: 10
      }),
      dropdownIndicator: provided => ({ ...provided, padding: 1 }),
      clearIndicator: provided => ({ ...provided, padding: 2 }),
      input: provided => ({ ...provided, padding: 0, margin: 0 })
    };

    const getStyles = () => {
      if (size == 'sm') return smSizeStyles;
    };

    return (
      <>
        <ReactSelect
          styles={getStyles()}
          placeholder={placeholder}
          options={data?.data || []}
          name={name}
          formatOptionLabel={account => (
            <Flex>
              <ReactSelectBoxImageLabel
                src={account.logo || defaultAccountLogo}
                title={account.account_name}
              />
            </Flex>
          )}
          onChange={account => onChange(account)}
          value={account}
          getOptionValue={account => account.id}
          getOptionLabel={account => account.account_name}
          className={`${className} ${data?.status == 403 && 'is-invalid'}`}
          isClearable={isClearable}
          isDisabled={isDisabled}
          isLoading={isLoading}
          isMulti={isMulti}
          classNamePrefix="react-select"
          isSearchable={isSearchable}
        />
        {canShowErrorFeedback && (
          <Form.Control.Feedback type="invalid">
            {data?.status == 403 && data?.message}
          </Form.Control.Feedback>
        )}
      </>
    );
  };

  return AccountSelectBox;
};

export default useAccountSelectBox;
