import { KeyBackground } from 'assets/img/aida-auth/images';
import ForgetPasswordForm from 'components/authentication/ForgetPasswordForm';
import AuthAidaLayout from 'layouts/AuthAidaLayout';
import React from 'react';

const ForgetPassword = () => {
  return (
    <AuthAidaLayout backgroundImage={KeyBackground}>
      <h4 className="mb-5 font-ibm-plex-sans"> Forgot your password?</h4>
      <p className="font-ibm-plex-sans">
        Enter your email & we'll send you a reset link
      </p>
      <ForgetPasswordForm layout="app" />
    </AuthAidaLayout>
  );
};

export default ForgetPassword;
