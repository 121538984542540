import React, { useContext, useEffect } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import NavbarTop from 'components/navbar/top/NavbarTop';
import AppContext from 'context/Context';
import MainRoutes from './MainRoutes';
import Footer from 'components/footer/Footer';
import ProductProvider from 'components/app/e-commerce/ProductProvider';
import classNames from 'classnames';
import Dashboard from 'components/aida/home/Dashboard';

const MainLayout = () => {
  const { hash, pathname } = useLocation();
  const isKanban = pathname.includes('kanban');

  const {
    config: { isFluid, navbarPosition }
  } = useContext(AppContext);

  useEffect(() => {
    setTimeout(() => {
      if (hash) {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ block: 'start', behavior: 'smooth' });
        }
      }
    }, 0);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <NavbarTop />
      <div className={isFluid ? 'mt-3 container-fluid' : 'container'}>
        <ProductProvider>
          <div
            className={classNames('content overflow-hidden', {
              'pb-0': isKanban
            })}
          >
            <div className="mt-5 mt-md-0">
              <Switch>
                <Route path="/" exact component={Dashboard} />
                <MainRoutes />
              </Switch>
              {!isKanban && <Footer />}
            </div>
          </div>
        </ProductProvider>
      </div>
    </>
  );
};

export default MainLayout;
