import React, { useContext, useEffect } from 'react';
import { SeoWizardContext } from './Context';
import LocalStoragePageInfo from 'components/common/custom-components/LocalStoragePageInfo';

const ProgressAndPageTitle = () => {
  const seoWizardContext = useContext(SeoWizardContext);

  return (
    <LocalStoragePageInfo
      slug={`form_seo_project_wizard_step_${seoWizardContext?.currentStep?.step}`}
    />
  );
};

export default ProgressAndPageTitle;
