import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import logo from 'assets/img/trakk_logo_w300px_ny_03.png';
import { getItemFromStore } from 'helpers/utils';
import { AidaAppContext } from 'context/AidaAppContext';

const CustomLogoColor = {
  color: 'black',
  fontSize: '28px'
};

const Logo = ({ at, width, className, textClass, ...rest }) => {
  // const aidaAppContext = useContext(AidaAppContext);

  return (
    <Link
      to={'/'}
      className={classNames(
        'text-decoration-none',
        { 'navbar-brand text-left': at === 'navbar-vertical' },
        { 'navbar-brand text-left': at === 'navbar-top' }
      )}
      {...rest}
    >
      <div
        className={classNames(
          'd-flex',
          {
            'align-items-center py-3': at === 'navbar-vertical',
            'align-items-center': at === 'navbar-top',
            'flex-center fw-bolder fs-5 mb-4': at === 'auth'
          },
          className
        )}
      >
        {/* {(
          aidaAppContext?.user_role == 'Client'
            ? aidaAppContext?.client?.favicon
            : aidaAppContext.agency?.logo
        ) ? (
          <img
            className="me-2"
            src={
              aidaAppContext?.user_role == 'Client'
                ? aidaAppContext?.client?.favicon
                : aidaAppContext.agency?.logo
            }
            alt="Logo"
            width={width}
          />
        ) : null} */}
        <img className="me-2" src={logo} alt="Logo" width={'100px'} />
        {/* <span
          className={classNames('font-ibm-plex-sans', textClass)}
          style={CustomLogoColor}
        >
        {aidaAppContext?.user_role == 'Client'
            ? aidaAppContext?.client?.name
            : aidaAppContext.agency?.name}
        AIDA
        </span>
           */}
      </div>
    </Link>
  );
};

Logo.propTypes = {
  at: PropTypes.oneOf(['navbar-vertical', 'navbar-top', 'auth']),
  width: PropTypes.number,
  className: PropTypes.string,
  textClass: PropTypes.string
};

Logo.defaultProps = { at: 'auth', width: 58 };

export default Logo;
