import { getItemFromStore, deleteItemFromStore } from 'helpers/utils';
import { useHistory } from 'react-router-dom';

const useAuthActions = () => {
  const history = useHistory();

  return {
    runPostLoginActions: () => {
      const redirectionUrl = getItemFromStore('redirectionUrl', false);
      deleteItemFromStore('redirectionUrl');

      if (redirectionUrl) {
        history.push(redirectionUrl);
      } else {
        history.push('/');
      }
    }
  };
};

export default useAuthActions;
