import LocalStorageTooltip from 'components/common/custom-components/LocalStorageTooltip';
import IconButton from 'components/common/IconButton';
import React, { useContext, useState } from 'react';
import { Form, Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { SeoWizardContext } from './Context';
import CallApi from 'components/common/custom-components/CallApi';

const ChangeNumberOfKeywordsModal = () => {
  const [showModal, setShowModal] = useState(false);

  const seoWizardContext = useContext(SeoWizardContext);
  const { currentSeoProject, refetchSeoProject } = seoWizardContext;

  const toggleModal = () => setShowModal(!showModal);
  const { register, handleSubmit } = useForm({
    defaultValues: {
      number_of_keywords:
        currentSeoProject?.main_information?.number_of_keywords
    }
  });

  const UpdateNumberOfKeywordsMutation = useMutation(
    'update-seo-project-draft',
    formData =>
      CallApi.put(`/seo-project-drafts/${currentSeoProject?.id}`, formData),
    {
      onSuccess: json => {
        refetchSeoProject();
        toggleModal();
      }
    }
  );

  const onSubmit = (data, form) => {
    let mainInformation = { ...currentSeoProject?.main_information };
    mainInformation.number_of_keywords = data?.number_of_keywords;

    let formData = new FormData();

    formData.append('main_information', JSON.stringify(mainInformation));

    UpdateNumberOfKeywordsMutation.mutate(formData);
  };

  return (
    <>
      <IconButton
        icon={'pencil-alt'}
        className="rounded-pill"
        variant="outline-primary"
        size="sm"
        onClick={() => {
          setShowModal(true);
        }}
      >
        Change number of keywords
      </IconButton>

      <Modal show={showModal} centered onHide={toggleModal}>
        <Modal.Header closeButton>
          <Modal.Title>Change number of keywords</Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-3">
          <Form
            id="change-number-of-keywords-form"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Form.Group className="mb-3">
              <Form.Label>
                Number of keywords
                <LocalStorageTooltip
                  className="ms-1"
                  identifier={'form_seo_project_field_number_of_keywords'}
                />
              </Form.Label>
              <Form.Select
                {...register('number_of_keywords', { required: true })}
              >
                <option value={5}>5</option>
                <option value={10}>10</option>
                <option value={20}>20</option>
              </Form.Select>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <IconButton
            icon="times-circle"
            size="sm"
            onClick={toggleModal}
            variant="falcon-light"
          >
            Close
          </IconButton>
          <IconButton
            variant="outline-primary"
            className="rounded-pill"
            form="change-number-of-keywords-form"
            type="submit"
            spin={UpdateNumberOfKeywordsMutation.isLoading}
            icon={UpdateNumberOfKeywordsMutation.isLoading ? 'spinner' : 'save'}
            size="sm"
          >
            Update
          </IconButton>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ChangeNumberOfKeywordsModal;
