import CallApi from 'components/common/custom-components/CallApi';
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import ReactSelect from 'react-select';

const useRegionSelectBox = () => {
  const getRegions = () => CallApi.get('/regions?sort=name', false);
  const { data } = useQuery('regions', getRegions, {
    placeholderData: { data: [] }
  });

  const RegionSelectBox = ({
    name = 'region_id',
    className = '',
    isClearable = false,
    isDisabled = false,
    isLoading = false,
    isMulti = false,
    isSearchable = false,
    placeholder = 'Please select a region',
    onChange = (/** @type {any} */ region) => {},
    value
  }) => {
    const [regionSelectBoxValue, setRegionSelectBoxValue] = useState(null);

    useEffect(() => {
      if (data?.data?.length > 0) {
        setRegionSelectBoxValue(data?.data?.find(region => region.id == value));
      }
    }, [value]);

    return (
      <ReactSelect
        placeholder={placeholder}
        options={data?.data || []}
        name={name}
        onChange={region => onChange(region)}
        value={regionSelectBoxValue}
        getOptionValue={region => region.id}
        getOptionLabel={region => region.name}
        className={className}
        isClearable={isClearable}
        isDisabled={isDisabled}
        isLoading={isLoading}
        isMulti={isMulti}
        isSearchable={isSearchable}
      />
    );
  };

  return RegionSelectBox;
};

export default useRegionSelectBox;
