import LocalStoragePageInfo from 'components/common/custom-components/LocalStoragePageInfo';
import React from 'react';
import Listing from './components/Listing';

const Users = () => {
  return (
    <>
      <LocalStoragePageInfo slug="agency_users" />
      <Listing />
    </>
  );
};

export default Users;
