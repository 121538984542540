import { faFileAlt } from '@fortawesome/free-regular-svg-icons';
import {
  faChartBar,
  faChartLine,
  faKeyboard,
  faObjectGroup,
  faPencilAlt
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext } from 'react';
import { Button, Col, Nav, Row } from 'react-bootstrap';
import AccountSettings from './AccountSettings';
import Adgroups from './Adgroups/Adgroups';
import { AdsAccountContext } from './AdsAccountContext';
import EditTexts from './EditTexts';
import OverviewPage from './OverviewPage';
import ResearchCompetitor from './ResearchCompetitor';
import WebMonitor from './WebMonitor';
import { AidaAppContext } from 'context/AidaAppContext';

export const EditAccountFormSteps = [
  {
    step: 0,
    name: 'Overview',
    icon: 'home',
    component: <OverviewPage />
  },
  {
    step: 1,
    name: 'Account Settings',
    icon: 'cog',
    component: <AccountSettings />
  },
  {
    step: 2,
    name: 'Research Competitor',
    icon: faChartBar,
    component: <ResearchCompetitor />
  },
  {
    step: 3,
    name: 'Reports',
    icon: faFileAlt,
    component: 'Reports is scheduled in upcoming tasks'
  },
  { step: 4, name: 'Edit Texts', icon: faPencilAlt, component: <EditTexts /> },
  {
    step: 5,
    name: 'Keyword List',
    icon: faKeyboard,
    component: 'Keyword list is scheduled in upcoming tasks'
  },
  {
    step: 6,
    name: 'Web Monitor',
    icon: faChartLine,
    component: <WebMonitor />
  },
  {
    step: 7,
    name: 'Adgroups',
    onlyAccessibleForModule: 'Approver',
    icon: faObjectGroup,
    component: <Adgroups />
  }
];

const AdsAccountSidebar = () => {
  const adsAccountContext = useContext(AdsAccountContext);
  const aidaAppContext = useContext(AidaAppContext);
  const { current_user } = aidaAppContext;

  const { currentStep, setAdsAccountContext } = adsAccountContext;

  const handleStepChange = step => {
    setAdsAccountContext({ ...adsAccountContext, currentStep: step });
  };

  const EditAccountFormStep = step => (
    <React.Fragment key={step?.step}>
      <div
        className={`ps-5 ${
          currentStep?.step === step?.step
            ? 'bg-soft-active-background text-dark border-0 rounded-0'
            : ''
        }`}
      >
        <Button
          className={` w-100 text-start py-2 ps-0 ${
            currentStep?.step === step?.step
              ? 'bg-transparent text-dark border-0'
              : 'btn-outline-light bg-transparent text-dark shadow-none border-0'
          }`}
          onClick={() => handleStepChange(step)}
        >
          <Row>
            <Col xs={1}>
              <FontAwesomeIcon icon={step.icon} />
            </Col>
            <Col>{step.name}</Col>
          </Row>
        </Button>
      </div>
      {step?.step == 0 ? <hr className="text-dark ms-5 my-2" /> : null}
    </React.Fragment>
  );

  return (
    <Nav className="flex-column d-grid gap-2 ">
      {EditAccountFormSteps.filter(step =>
        step?.onlyAccessibleForModule
          ? current_user?.modules
              ?.map(module => module.name)
              ?.includes(step.onlyAccessibleForModule)
          : true
      ).map(EditAccountFormStep)}
    </Nav>
  );
};

export default AdsAccountSidebar;
