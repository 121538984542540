import CallApi from 'components/common/custom-components/CallApi';
import React, { createContext, useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { steps } from './Sidebar';

export const defaultSeoWizardContext = {
  currentStep: steps[0],
  currentSeoProject: {},
  refetchSeoProject: () => {},
  setSeoWizardContext: newContext => {},
  nextStep: additionalContextData => {},
  prevStep: additionalContextData => {},
  lastStepAsDrafted: additionalContextData => {},
  isRefetchingContext: false,
  isDraft: true,
  isRefetchingSeoProjectDraft: false,
  isLoadingSeoProjectDraft: false,
  isFetchingSeoProjectDraft: false,
  matchKeywordUrlScreen: {
    top10urls: {}
  },
  resetContext: () => {}
};

export const SeoWizardContext = createContext(defaultSeoWizardContext);

const SeoWizardProvider = ({ children }) => {
  const routeParams = useParams();
  const [seoWizardContext, setSeoWizardContext] = useState({
    ...defaultSeoWizardContext
  });
  const draftId = seoWizardContext.currentSeoProject?.id || routeParams.id;

  const { refetch, isFetching, isLoading, isRefetching } = useQuery(
    ['seo-project-drafts', draftId],
    () => CallApi.get(`/seo-project-drafts/${draftId}`, false),
    {
      onSuccess: json =>
        setSeoWizardContext({
          ...seoWizardContext,
          currentSeoProject: json?.data
        }),
      enabled: !!draftId
    }
  );

  const changeStep = (depth, additionalData = {}) => {
    const currentStepIndex = steps.findIndex(
      step => step.step == seoWizardContext.currentStep?.step
    );

    const newIndex = depth < 0 ? currentStepIndex - 1 : currentStepIndex + 1;

    setSeoWizardContext({
      ...seoWizardContext,
      ...additionalData,
      currentStep: steps[newIndex]
    });
  };

  const lastStepAsDrafted = additionalData =>
    setSeoWizardContext({
      ...seoWizardContext,
      ...additionalData,
      currentStep: steps[steps.length - 1]
    });

  return (
    <SeoWizardContext.Provider
      value={{
        ...seoWizardContext,
        refetchSeoProject: refetch,
        setSeoWizardContext: value => setSeoWizardContext(value),
        nextStep: additionalData => changeStep(1, additionalData),
        prevStep: additionalData => changeStep(-1, additionalData),
        lastStepAsDrafted: lastStepAsDrafted,
        resetContext: () => setSeoWizardContext(defaultSeoWizardContext),
        isRefetchingContext: isFetching || isRefetching,
        isRefetchingSeoProjectDraft: isRefetching,
        isLoadingSeoProjectDraft: isLoading,
        isFetchingSeoProjectDraft: isFetching
      }}
    >
      {children}
    </SeoWizardContext.Provider>
  );
};

export default SeoWizardProvider;
