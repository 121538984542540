import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from 'components/common/Flex';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import CallApi from 'components/common/custom-components/CallApi';
import React, { useContext, useState, useRef } from 'react';
import {
  Button,
  ButtonGroup,
  Form,
  Modal,
  OverlayTrigger,
  Tooltip
} from 'react-bootstrap';
import { useMutation, useQuery } from 'react-query';
import { SeoWizardContext } from './Context';
import MatchKeywordsToUrlModal from './MatchKeywordsToUrlModal';
import { useForm } from 'react-hook-form';
import LocalStorageTooltip from 'components/common/custom-components/LocalStorageTooltip';
import useKeywordPurposesSelectBox from 'components/aida/custom-form-hooks/useKeywordPurposesSelectBox';
import SaveAsDraftWithNoteModal from './SaveAsDraftWithNoteModal';

const MatchKeywordsToUrl = () => {
  const [keywords, setKeywords] = useState([]);
  const seoProjectContext = useContext(SeoWizardContext);
  const { currentSeoProject, nextStep, prevStep } = seoProjectContext;

  const [visibleMatchModalIndex, setVisibleMatchModalIndex] = useState(-1);
  const [animateMatchModalIndex, setAnimateMatchModalIndex] = useState(-1);

  const { KeywordPurposeSelectBox } = useKeywordPurposesSelectBox();

  const WebsiteLinksQuery = useQuery(
    ['website-links', currentSeoProject?.id],
    () =>
      CallApi.get(
        `/scrape-links?domain=` + currentSeoProject?.main_information?.domain,
        false
      ),
    {
      enabled: !!currentSeoProject?.main_information?.domain
    }
  );

  const { handleSubmit } = useForm();
  const columns = [
    {
      accessor: 'completed',
      Header: (
        <>
          Completed
          <LocalStorageTooltip
            identifier={'table-match-keywords-to-url-column-completed'}
          />
        </>
      ),
      headerProps: { className: 'text-center' },
      cellProps: { className: 'text-center' },
      Cell: ({ cell }) => (
        <FontAwesomeIcon
          icon={
            cell.row.original?.url ||
            cell.row.original?.add_later ||
            cell.row.original?.create_page
              ? 'check'
              : 'times'
          }
          className={
            cell.row.original?.url ||
            cell.row.original?.add_later ||
            cell.row.original?.create_page
              ? 'text-success fs-1'
              : 'text-danger fs-1'
          }
        />
      )
    },
    {
      accessor: 'keyword',
      Header: (
        <>
          Keyword
          <LocalStorageTooltip
            identifier={'table-match-keywords-to-url-column-keyword'}
          />
        </>
      ),
      Cell: ({ cell }) => {
        return (
          <>
            <input
              type="hidden"
              name={`link_urls_to_keywords[[${cell.row.id}][keyword]]`}
              value={cell.value}
            />
            {cell.value}
          </>
        );
      }
    },

    {
      accessor: 'match',
      Header: (
        <>
          Match
          <LocalStorageTooltip
            identifier={'table-match-keywords-to-url-column-match'}
          />
        </>
      ),
      headerProps: { className: 'text-center' },
      cellProps: { className: 'text-center' },
      Cell: ({ cell }) => (
        <MatchKeywordsToUrlModal
          key={cell.row.index}
          keyword={cell.row.original?.keyword}
          setKeywords={setKeywords}
          keywords={keywords}
          competitorDomains={WebsiteLinksQuery?.data?.data || []}
          addLater={cell.row.original?.add_later}
          animate={cell.row.index == animateMatchModalIndex}
          show={cell.row.index == visibleMatchModalIndex}
          setShow={show => {
            if (show) {
              // Ugly fix to prevent remounting from triggering animation
              setAnimateMatchModalIndex(cell.row.index);
              setTimeout(() => {
                setAnimateMatchModalIndex(-1);
              }, 300);
            }
            setVisibleMatchModalIndex(show ? cell.row.index : -1);
          }}
        />
      )
    },
    {
      accessor: 'add_later',
      Header: (
        <>
          Add Later
          <LocalStorageTooltip
            identifier={'table-match-keywords-to-url-column-add-later'}
          />
        </>
      ),
      headerProps: { className: 'text-center' },
      cellProps: { className: 'text-center' },
      Cell: ({ cell }) => {
        return (
          <Form.Check
            name={`link_urls_to_keywords[[${cell.row.id}][add_later]]`}
            className="fs-0 cursor-pointer"
            onChange={e => {
              const newKeywords = [...keywords].map(kwrd =>
                kwrd.keyword == cell.row.original?.keyword
                  ? {
                      ...kwrd,
                      add_later: !cell.row.original?.add_later,
                      url: null
                    }
                  : kwrd
              );
              setKeywords(newKeywords);
            }}
            checked={cell.value}
            value={`${cell.value}`}
          />
        );
      }
    },
    {
      accessor: 'create_page',
      Header: (
        <>
          Create Page
          <LocalStorageTooltip
            identifier={'table-match-keywords-to-url-column-create-page'}
          />
        </>
      ),
      headerProps: { className: 'text-center' },
      cellProps: { className: 'text-center' },
      Cell: ({ cell }) => {
        return (
          <Form.Check
            name={`link_urls_to_keywords[[${cell.row.id}][create_page]]`}
            className="fs-0 cursor-pointer"
            onChange={e => {
              const newKeywords = [...keywords].map(kwrd =>
                kwrd.keyword == cell.row.original?.keyword
                  ? {
                      ...kwrd,
                      create_page: !cell.row.original?.create_page
                    }
                  : kwrd
              );
              setKeywords(newKeywords);
            }}
            checked={cell.value}
            value={`${cell.value}`}
          />
        );
      }
    },
    {
      accessor: 'keyword_purpose_id',
      Header: (
        <>
          Purpose
          <LocalStorageTooltip
            identifier={'table-match-keywords-to-url-column-purpose'}
          />
        </>
      ),
      Cell: ({ cell }) => (
        <KeywordPurposeSelectBox
          value={cell.value}
          isSearchable
          onChange={keywordPurpose => {
            const newKeywords = [...keywords].map(kwrd =>
              kwrd.keyword == cell.row.original?.keyword
                ? {
                    ...kwrd,
                    keyword_purpose_id: keywordPurpose?.id
                  }
                : kwrd
            );
            setKeywords(newKeywords);
          }}
        />
      )
    },
    {
      accessor: 'url',
      Header: (
        <>
          URL
          <LocalStorageTooltip
            identifier={'table-match-keywords-to-url-column-url'}
          />
        </>
      ),
      Cell: ({ cell }) => {
        return (
          <>
            <input
              type="hidden"
              name={`link_keywords_to_url[[${cell.row.id}][url]]`}
              readOnly
              value={cell.value}
            />
            {cell.value}
          </>
        );
      }
    }
  ];

  const UpdateSeoProjectDraftMutation = useMutation(
    'update-seo-project-draft',
    formData =>
      CallApi.put(`/seo-project-drafts/${currentSeoProject?.id}`, formData)
  );

  const KeywordsToMatchQuery = useQuery(
    ['seo-keywords-to-match'],
    () =>
      CallApi.get(
        `/seo-project-drafts/${currentSeoProject?.id}/keywords-to-match-urls`
      ),
    {
      onSuccess: json => {
        if (json?.status == 200) {
          setKeywords(json?.data);
        }
      }
    }
  );

  const urls = new Set();

  const hasDuplicateUrl = keywords.some(keyword => {
    if (keyword.url && urls.has(keyword.url)) {
      return true; // Found a duplicate URL
    } else {
      urls.add(keyword.url);
      return false; // Unique URL
    }
  });

  const isNotCompleted = keywords.some(
    keyword => !(keyword.url || keyword.add_later || keyword.create_page)
  );

  const onMatchKeywordsFormSubmit = (data, form) => {
    let formData = new FormData();

    if (isNotCompleted || hasDuplicateUrl) {
      alert('Please complete all the keywords');
    } else {
      formData.append('link_keywords_to_url', JSON.stringify(keywords));

      UpdateSeoProjectDraftMutation.mutate(formData, {
        onSuccess: json => {
          if (json?.status == 200) {
            nextStep({
              currentSeoProject: json?.data,
              isDraft: false
            });
          }
        }
      });
    }
  };

  const NextPrevButtons = () => {
    return (
      <ButtonGroup className="mt-4">
        <Button
          variant="outline-primary"
          className="rounded-pill"
          onClick={prevStep}
        >
          <FontAwesomeIcon icon={'arrow-left'} /> Previous
        </Button>
        <SaveAsDraftWithNoteModal dataToBeDrafted={keywords} />
        <OverlayTrigger
          placement={'top'}
          overlay={
            isNotCompleted || hasDuplicateUrl ? (
              <Tooltip>
                {isNotCompleted
                  ? 'Please complete all the keywords'
                  : 'More than one keyword cannot have the same URL'}
              </Tooltip>
            ) : (
              <></>
            )
          }
        >
          <span className="d-inline-block">
            <Button
              variant="outline-primary"
              type="submit"
              form="match-keywords-form"
              className="rounded-pill ms-2"
              disabled={isNotCompleted || hasDuplicateUrl}
            >
              {UpdateSeoProjectDraftMutation.isLoading ? (
                <>
                  Publishing... <FontAwesomeIcon icon={'spinner'} spin />
                </>
              ) : (
                <>
                  Finish <FontAwesomeIcon icon={'arrow-right'} />
                </>
              )}
            </Button>
          </span>
        </OverlayTrigger>
      </ButtonGroup>
    );
  };

  return (
    <AdvanceTableWrapper
      columns={columns}
      data={keywords}
      sortable
      pagination
      perPage={25}
    >
      <Form
        noValidate
        id="match-keywords-form"
        onSubmit={handleSubmit(onMatchKeywordsFormSubmit)}
      >
        <AdvanceTable
          table
          headerClassName="bg-aida-table-header text-900 text-nowrap align-middle"
          rowClassName="align-middle white-space-nowrap"
          tableProps={{
            className: 'fs--1 mb-0 overflow-hidden'
          }}
          loadingText={'Fetching selected keywords...'}
          apiCallInProcess={KeywordsToMatchQuery.isFetching}
        />
      </Form>
      <AdvanceTableFooter
        rowsPerPageOptions={[25, 50, 75, 100]}
        table
        className="mt-3 mx-3"
        rowCount={keywords?.length}
        rowInfo
        navButtons
        rowsPerPageSelection
      />
      <Flex justifyContent="center">
        <NextPrevButtons table />
      </Flex>
    </AdvanceTableWrapper>
  );
};

export default MatchKeywordsToUrl;
