import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useRegionSelectBox from 'components/aida/custom-form-hooks/useRegionSelectBox';
import CallApi from 'components/common/custom-components/CallApi';
import FalconCardHeader from 'components/common/FalconCardHeader';
import React, { useContext, useState } from 'react';
import { Row, Col, Card, Form, Table, Button } from 'react-bootstrap';
import { useMutation, useQuery } from 'react-query';
import { AdsAccountContext } from '../AdsAccountContext';
import TargetGroups from './components/TargetGroups';
import YourRegions from './components/YourRegions';
import MonthlyBudget from './components/MonthlyBudget';

const AudienceSettings = () => {
  const adsAccountContext = useContext(AdsAccountContext);
  const { currentAccount, refetchCurrentAccount } = adsAccountContext;
  return (
    <Row className="gap-3">
      <Col lg={5}>
        <YourRegions />
      </Col>
      <Col>
        <TargetGroups
          className="mb-5"
          targetGroup={currentAccount?.target_group}
          accountId={currentAccount?.id}
          refetchAccount={refetchCurrentAccount}
        />
        <MonthlyBudget />
      </Col>
    </Row>
  );
};

export default AudienceSettings;
