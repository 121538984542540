import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ManualAddCompetitorModal from 'components/aida/accounts/wizard-sub-forms/ManualAddCompetitorModal';
import Flex from 'components/common/Flex';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import CallApi from 'components/common/custom-components/CallApi';
import React, { useContext, useState } from 'react';
import {
  Button,
  ButtonGroup,
  Col,
  OverlayTrigger,
  Row,
  Tooltip
} from 'react-bootstrap';
import { useMutation, useQuery } from 'react-query';
import { SeoWizardContext } from './Context';
import LocalStorageTooltip from 'components/common/custom-components/LocalStorageTooltip';
import ReRunCompetitorsModal from './ReRunCompetitorsModal';
import SaveAsDraftWithNoteModal from './SaveAsDraftWithNoteModal';

const SelectCompetitorsForm = () => {
  const columns = [
    {
      accessor: 'domain',
      Header: (
        <>
          Domain{' '}
          <LocalStorageTooltip
            identifier={'table-select-competitors-column-domain'}
          />
        </>
      ),
      Cell: ({ cell }) => (
        <a
          href={`https://${cell.value}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon icon={'external-link-alt'} /> {cell.value}
        </a>
      )
    },
    {
      accessor: 'common_keywords',
      Header: (
        <>
          Common Keyword
          <LocalStorageTooltip
            identifier={'table-select-competitors-column-common-keyword'}
          />
        </>
      ),
      headerProps: { className: 'text-end' },
      cellProps: { className: 'text-end' }
    },
    {
      accessor: 'total_keywords',
      Header: (
        <>
          Total Keywords
          <LocalStorageTooltip
            identifier={'table-select-competitors-column-total-keywords'}
          />
        </>
      ),
      headerProps: { className: 'text-end' },
      cellProps: { className: 'text-end' }
    },
    {
      accessor: 'relevance',
      Header: (
        <>
          Relevance
          <LocalStorageTooltip
            identifier={'table-select-competitors-column-relevance'}
          />
        </>
      ),
      headerProps: { className: 'text-end' },
      cellProps: { className: 'text-end' }
    },
    {
      accessor: 'points',
      Header: (
        <>
          Points
          <LocalStorageTooltip
            identifier={'table-select-competitors-column-points'}
          />
        </>
      ),
      headerProps: { className: 'text-end' },
      cellProps: { className: 'text-end' }
    }
  ];
  const [initiallySelectedRowIds, setInitiallySelectedRowIds] = useState({});
  const [competitors, setCompetitors] = useState([]);
  const seoProjectContext = useContext(SeoWizardContext);
  const {
    currentSeoProject,
    currentStep,
    nextStep,
    prevStep,
    refetchSeoProject,
    setSeoWizardContext
  } = seoProjectContext;

  const CompetitorsQuery = useQuery(
    [
      'competitors',
      currentSeoProject?.main_information?.country_id,
      currentSeoProject?.main_information?.domain
    ],
    () =>
      CallApi.get(
        `/competitors/se-ranking-reports/competitors?country_id=${currentSeoProject?.main_information?.country_id}&domain=${currentSeoProject?.main_information?.domain}`,
        true
      ),
    {
      onSuccess: json => {
        if (json?.status == 200) {
          const savedCompetitors = currentSeoProject?.competitors || [];

          const savedJsonCompetitors =
            savedCompetitors.map(
              competitorDomain =>
                json.data.find(
                  competitor => competitor.domain == competitorDomain
                ) || {
                  domain: competitorDomain,
                  common_keywords: '-',
                  total_keywords: '-',
                  relevance: '-',
                  points: '-'
                }
            ) || [];

          const allCompetitors = [
            ...savedJsonCompetitors,
            ...json.data
              .filter(competitor => competitor?.total_keywords > 30)
              .filter(item => !savedCompetitors.includes(item?.domain))
          ];

          let initialSelectedRowIndexes = {};

          const indexesOfPreviouslySelectedRows = allCompetitors
            ?.map((competitor, i) =>
              savedCompetitors.includes(competitor?.domain) ? i : null
            )
            ?.filter(index => index != null);

          indexesOfPreviouslySelectedRows.forEach(index => {
            initialSelectedRowIndexes[index] = true;
          });

          setInitiallySelectedRowIds(initialSelectedRowIndexes);

          setCompetitors(allCompetitors);
        }
      },
      enabled: !!(
        currentSeoProject?.main_information?.country_id &&
        currentSeoProject?.main_information?.domain
      )
    }
  );

  const UpdateSeoProjectDraftMutation = useMutation(
    'create-seo-project-draft',
    formData =>
      CallApi.put(`/seo-project-drafts/${currentSeoProject?.id}`, formData)
  );

  const CompetitorsMutation = useMutation(
    'compeittors-mutation',
    customDomain =>
      CallApi.get(
        `/competitors/se-ranking-reports/competitors?country_id=${currentSeoProject?.main_information?.country_id}&domain=${customDomain}`,
        true
      ),
    {
      onSuccess: (json, customDomain) => {
        const allCompetitors = [
          ...[
            {
              domain: customDomain,
              common_keywords: '-',
              total_keywords: '-',
              relevance: '-',
              points: '-'
            }
          ],
          ...json.data.filter(competitor => competitor?.total_keywords > 30)
        ];

        let initialSelectedRowIndexes = { 0: true };

        setInitiallySelectedRowIds(initialSelectedRowIndexes);

        setCompetitors(allCompetitors);
      }
    }
  );

  const saveCompetitors = selectedRows => {
    const formData = new FormData();

    formData.append(
      'competitors',
      JSON.stringify(selectedRows.map(row => row.original.domain))
    );

    UpdateSeoProjectDraftMutation.mutate(formData, {
      onSuccess: json => {
        if (json?.status == 200) {
          nextStep({
            currentSeoProject: json?.data
          });
        }
      }
    });
  };

  const NextPrevButtons = ({ selectedRowIds, data }) => {
    // Fix for issue with V7 of TanStack Table, see https://github.com/TanStack/table/issues/2210
    let selectedFlatRows = Object.keys(selectedRowIds).map(rowId => ({
      original: data[rowId]
    }));
    const isNextStepButtonDisabled = Object.keys(selectedFlatRows).length < 4;
    return (
      <ButtonGroup className="mt-4">
        <Button
          variant="outline-primary"
          className="rounded-pill"
          onClick={prevStep}
        >
          <FontAwesomeIcon icon={'arrow-left'} /> Previous
        </Button>
        <SaveAsDraftWithNoteModal
          dataToBeDrafted={selectedFlatRows.map(row => row.original.domain)}
        />
        <OverlayTrigger
          placement={'top'}
          overlay={
            isNextStepButtonDisabled ? (
              <Tooltip>at least 4 competitors must be selected</Tooltip>
            ) : (
              <></>
            )
          }
        >
          <span className="d-inline-block">
            <Button
              variant="outline-primary"
              className="rounded-pill ms-2"
              disabled={isNextStepButtonDisabled}
              onClick={() => saveCompetitors(selectedFlatRows)}
            >
              {UpdateSeoProjectDraftMutation.isLoading ? (
                <>
                  Updating draft... <FontAwesomeIcon icon={'spinner'} spin />
                </>
              ) : (
                <>
                  Next Step: Keywords <FontAwesomeIcon icon={'arrow-right'} />
                </>
              )}
            </Button>
          </span>
        </OverlayTrigger>
      </ButtonGroup>
    );
  };

  const apiCallInProcess =
    CompetitorsQuery.isFetching ||
    CompetitorsQuery.isRefetching ||
    CompetitorsMutation.isLoading;

  return (
    <AdvanceTableWrapper
      columns={columns}
      data={competitors || []}
      sortable
      pagination
      perPage={25}
      selection
      uncheckAllButton
      showBulkSelectRowCheckbox={false}
      maxSelection={4}
      initialSelectedRowIds={initiallySelectedRowIds}
    >
      <Row className="align-items-space-between mb-3">
        <Col xs="auto">
          <AdvanceTableSearchBox table />
        </Col>
        <Col>
          <TopSelectedRowsCounter table />
        </Col>
        <Col xs="auto" className="pe-0">
          <ReRunCompetitorsModal
            rerunCompetitors={customDomain => {
              CompetitorsMutation.mutate(customDomain);
            }}
          />
        </Col>

        <Col xs="auto">
          <ManualAddCompetitorModal
            disabled={apiCallInProcess}
            setCompetitors={setCompetitors}
            competitors={competitors}
            setInitiallySelectedRowIds={setInitiallySelectedRowIds}
            table
            variant="outline-primary"
            className="rounded-pill"
          />
        </Col>
      </Row>
      <AdvanceTable
        table
        headerClassName="bg-aida-table-header text-900 text-nowrap align-middle"
        rowClassName="align-middle white-space-nowrap"
        tableProps={{
          className: 'fs--1 mb-0 overflow-hidden'
        }}
        loadingText={'Please wait, we are fetching your competitors...'}
        apiCallInProcess={apiCallInProcess}
      />
      <AdvanceTableFooter
        showSelectedRowCount
        rowsPerPageOptions={[25, 50, 75, 100]}
        table
        className="mt-3 mx-3"
        rowCount={competitors?.length}
        rowInfo
        navButtons
        rowsPerPageSelection
      />
      <Flex justifyContent="center">
        <NextPrevButtons table data={competitors} />
      </Flex>
    </AdvanceTableWrapper>
  );
};

const TopSelectedRowsCounter = ({ selectedRowIds }) => {
  return (
    <h6 className="mt-1 mb-0 text-center">
      {Object.keys(selectedRowIds).length > 0
        ? 'You have selected ' + Object.keys(selectedRowIds).length + ' rows'
        : ''}
    </h6>
  );
};

export default SelectCompetitorsForm;
